<ng-template #priceCell>
  <div class="fill-content">
    <div style="justify-content: space-between" class="content-wrap p-10">
      <a class="button" *ngIf="editable && editFunction" (click)="editFunction(editFunctionParams)">
        <i class="fa fa-pencil"></i>
      </a>
      <div class="number">
        {{ price | number | price : false }}
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="isCaseChanged()"
     [tooltip]="casePrice.priceChanged ? 'Cena upravena, viz historie případu.' : 'ZP změněn, viz historie případu'"
     class="tooltip-hover-area flexbox-container flex-centered fill-content"
>
  <ng-container *ngTemplateOutlet="priceCell"></ng-container>
</div>

<ng-container *ngIf="!isCaseChanged() && (!tooltipComponent || !tooltipData)">
  <ng-container *ngTemplateOutlet="priceCell"></ng-container>
</ng-container>

<div *ngIf="!isCaseChanged() && tooltipComponent && tooltipData"
     [tooltip]="tooltipComponent"
     [data]="tooltipData"
     [activeContent]="true"
     class="tooltip-hover-area flexbox-container flex-centered fill-content"
>
  <ng-container *ngTemplateOutlet="priceCell"></ng-container>
</div>
