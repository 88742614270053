<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l mb-10">
        <a class="button" uiSref="symap.project.titles"><i class="fa fa-chevron-left mr-5"></i>seznam LV</a>
      </div>
      <div class="actions" *ngIf="!data.endDate">
        <a uiSref="symap.project.map" [uiParams]="{ title: titleId }" class="button"><i class="fa fa-map-marker"></i>Zobrazit v mapovém okně</a>

        <a class="button" gmtAuth="assignable" *ngIf="(!!dataOccupations?.length || !!dataEasements?.length || buildingsWithDifferentTitleExists)" (click)="addNote()"><i class="fa fa-plus-square"></i>
          <span *ngIf="data.blockedByUser">Odblokovat LV</span>
          <span *ngIf="!data.blockedByUser">Zablokovat LV</span>
        </a>
        <a uiSref="symap.project.titles.detail.pricing.overview" [uiParams]="{ titleId: this.titleId }" *ngIf="!!dataOccupations?.length || buildingsWithDifferentTitleExists" class="button">
          <i class="fa fa-plus-square"></i>Znalecké posudky
        </a>
        <a uiSref="symap.project.titles.detail.createCase2" gmtAuth="assignable" *ngIf="!data.blockedByUser && (!!dataOccupations?.length || !!dataEasements?.length || buildingsWithDifferentTitleExists)" class="button">
          <i class="fa fa-plus-square"></i>Založit případ
        </a>
      </div>
    </div>

    <h1>LV{{ data.lv }}
      <span>{{ data.area.name }}</span>
      <span *ngIf="data.endDate" class="data-ended"> / neaktualizováno od {{ data.endDate | date }}</span>
      <span *ngIf="data.blockedByUser" class="title-blocked"> / LV Blokováno</span>
      <span *ngIf="cadastreDataDate"> / Data KN aktuální k {{ cadastreDataDate | date}} </span>
    </h1>
    <span class="tag tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="data.ssEU">Sankční seznam EU</span>
    <span class="tag tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="data.ssNonEU">Sankční seznam mimo EU</span>
    <span class="tag tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="data.ssRuskyVliv">Ruský vliv</span>

    <div *ngIf="data.duplicityOwnership === true">
      <span title="Duplicitní zápis vlastnictví" class="tag tag--danger mr-10 tag--small is-uppercase">Duplicitní zápis vlastnictví</span>
    </div>

    <div class="clearfix mt-30" *ngIf="!hideColumn('user', 'titles')">
      <div class="column-100">
        <label>Přidělení LV</label>
        <div class="info" gmtAuthNot="assignment_edit">
          {{ data.user | username }}
        </div>
        <div class="input" gmtAuth="assignment_edit">
          <gmt-select required="true" (changed)="updateUser()" [data]="data" field="user" [optionItems]="users" [itemPipe]="userNamePipe"></gmt-select>
          <div *ngIf="userSending" class="ib sending"><span class="fa fa-circle-o-notch fa-spin"></span> Načítání...</div>
        </div>
      </div>
    </div>

    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
        <div *ngIf="tab === 'owners' && !data.endDate">
          <div class="pt-20">
            <gmt-labeled-block label="Osoby">
              <div class="bordered" [loading]="ownershipsLoading" *ngIf="ownershipsLoading"></div>
              <div class="bordered" *ngFor="let itemGroup of dataOwnershipGroups; let index = index" [ngClass]="{ 'mt-20': index > 0 }">
                <table>
                  <thead>
                  <tr>
                    <th>{{ itemGroup.groupName }}</th>
                    <th>Podíl</th>
                    <th>Adresa</th>
                    <th>Rodné číslo / IČ</th>
                  </tr>
                  </thead>
                  <tbody *ngFor="let itemOwnership of itemGroup.items" class="hover">
                  <tr *ngFor="let itemSubject of itemOwnership.subjects; let index = index">
                    <td class="no-padding">
                      <div class="cell--flex__container">
                        <div class="padding-10 cell--flex__container__main"><a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: itemSubject.id, tab: 'about' }">{{itemSubject | subjectName}}</a>
                          {{ itemOwnership.isBsm ? ' (osoba SJM)' : '' }}</div>
                        <div class="cell--flex__container_side">
                          <span title="Problematický vlastník" class="tag tag--danger mr-10 tag--small is-uppercase" *ngIf="itemSubject.subjectDetail.problematic">PV</span>
                          <span title="Sankce" class="tag tag--danger mr-10 tag--small is-uppercase" *ngIf="itemSubject.subjectDetail?.ssEU || itemSubject.subjectDetail?.ssNonEU || itemSubject.subjectDetail?.ssRuskyVliv">Sankce</span>
                        </div>
                      </div>
                    </td>
                    <td *ngIf="index === 0" [attr.rowspan]="itemOwnership.subjects.length" class="number">{{ itemOwnership.podilCitatel }}/{{ itemOwnership.podilJmenovatel }}</td>
                    <td class="cell cell--flex" *ngIf="itemSubject.subjectDetail && itemSubject.subjectDetail.customAddressUse">
                      <div class="cell--flex__container">
                        <div class="cell--flex__container__main">
                          {{ itemSubject.subjectDetail | address }}
                        </div>
                        <div class="cell--flex__container_side">
                              <span class="tag tag--inactive tag--small is-uppercase tag--not-clickable">
                                Adresa nepochází z KN
                              </span>
                        </div>
                      </div>
                    </td>

                    <td *ngIf="!itemSubject.subjectDetail || !itemSubject.subjectDetail.customAddressUse">
                      {{ itemSubject | address }}
                    </td>

                    <td class="number">
                      {{ itemSubject.rodneCislo | personalNumber }}{{ itemSubject.rodneCislo && itemSubject.ico !== 1 && itemSubject.ico ? ' / ' : '' }}{{ itemSubject.ico !== 1 ? itemSubject.ico : '' }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </gmt-labeled-block>
          </div>
        </div>

        <div *ngIf="tab === 'otherEntries'">
          <gmt-labeled-block  label="Jiné zápisy">
            <div class="bordered" [loading]="otherEntriesLoading">
              <div *ngIf="!otherEntriesLoading">
                <div *ngIf="!otherEntriesLoading && dataOtherEntries.length === 0" class="empty-info">Žádné zápisy</div>
                <table *ngIf="!otherEntriesLoading && dataOtherEntries.length > 0">
                  <tbody>
                  <tr *ngFor="let itemOtherEntry of dataOtherEntries">
                    <td>
                      <b>{{ itemOtherEntry.nazev }}</b>{{itemOtherEntry.popisPravnihoVztahu ? ' (' + itemOtherEntry.popisPravnihoVztahu + ')' : ''}}
                      <div *ngIf="itemOtherEntry.subjectsTo.length || itemOtherEntry.parcelTo || itemOtherEntry.buildingTo">
                        K: <span class="comma-list"><span *ngFor="let itemSubject of itemOtherEntry.subjectsTo"><a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: itemSubject.id, tab: 'about' }">{{itemSubject | subjectName}}</a></span>
                        <span *ngIf="itemOtherEntry.parcelTo">p.č. {{ itemOtherEntry.parcelTo | parcelnumber }}</span></span>
                        <span *ngIf="itemOtherEntry.buildingTo">stavba {{ itemOtherEntry.buildingTo | buildingNumber }} na parcele <span class="comma-list"><span *ngFor="let itemParcel of itemOtherEntry.buildingTo.parcels">{{ itemParcel | parcelnumber }}{{ itemParcel.soucasti ? ' (součástí)' : '' }}</span></span></span>
                      </div>
                      <div *ngIf="itemOtherEntry.subjectsFor.length || itemOtherEntry.parcelFor">
                        Pro: <span class="comma-list"><span *ngFor="let itemSubject of itemOtherEntry.subjectsFor">
                        <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: itemSubject.id, tab: 'about' }">{{ itemSubject | subjectName }}</a></span>
                        <span *ngIf="itemOtherEntry.parcelFor">p.č. {{ itemOtherEntry.parcelFor | parcelnumber }}</span></span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </gmt-labeled-block>
        </div>

        <div *ngIf="tab === 'otherLegalRelations'">
          <gmt-labeled-block label="Omezení vlastnického práva">
            <div class="bordered" [loading]="restrictionsLoading">
              <div *ngIf="!restrictionsLoading">
                <div *ngIf="!restrictionsLoading && dataRestrictions.length === 0" class="empty-info">Žádná omezení</div>
                <table *ngIf="!restrictionsLoading && dataRestrictions.length > 0">
                  <tbody>
                  <tr *ngFor="let itemRestriction of dataRestrictions">
                    <td><b>{{ itemRestriction.nazev }}</b>{{ itemRestriction.popisPravnihoVztahu ? ' (' + itemRestriction.popisPravnihoVztahu + ')' : '' }}
                      <div *ngIf="itemRestriction.subjectsTo.length || itemRestriction.parcelTo || itemRestriction.buildingTo">
                        K: <span class="comma-list"><span *ngFor="let itemSubject of itemRestriction.subjectsTo">
                        <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: itemSubject.id, tab: 'about' }">{{ itemSubject | subjectName }}</a></span>
                        <span *ngIf="itemRestriction.parcelTo">p.č. {{ itemRestriction.parcelTo | parcelnumber }}</span>
                        <span *ngIf="itemRestriction.buildingTo">stavba {{ itemRestriction.buildingTo | buildingNumber }}
                          na parcele <span class="comma-list"><span *ngFor="let itemParcel of itemRestriction.buildingTo.parcels">{{ itemParcel | parcelnumber }}{{ itemParcel.soucasti ? ' (součástí)' : '' }}</span></span>
                        </span></span>
                      </div>
                      <div *ngIf="itemRestriction.subjectsFor.length || itemRestriction.parcelFor">
                        Pro: <span class="comma-list"><span *ngFor="let itemSubject of itemRestriction.subjectsFor">
                        <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: itemSubject.id, tab: 'about' }">{{ itemSubject | subjectName }}</a></span>
                        <span *ngIf="itemRestriction.parcelFor">p.č. {{ itemRestriction.parcelFor | parcelnumber }}</span></span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </gmt-labeled-block>
        </div>

        <div *ngIf="tab === 'parcels'">
          <div class="bordered mb-20" [loading]="parcelsLoading || easementsLoading" *ngIf="parcelsLoading || easementsLoading"></div>

          <div *ngIf="dataOccupationGroups?.permanent && !parcelsLoading">
            <gmt-labeled-block label="{{ 'PERMANENT_OCCUPATION' | wordtranslate }}">
              <table-occupations-permanent [data]="dataOccupationGroups.permanent" [columns]="occupationPermanentColumns"></table-occupations-permanent>
            </gmt-labeled-block>
          </div>

          <div *ngIf="dataOccupationGroups?.permanentWithSolutionType && !parcelsLoading">
            <gmt-labeled-block label="Trvalý zábor bez výkupu (nájem)">
              <table-occupations-temporary [data]="dataOccupationGroups.permanentWithSolutionType" [columns]="permanentWithSolutionTypeColumns"></table-occupations-temporary>
            </gmt-labeled-block>
          </div>

          <div *ngIf="dataOccupationGroups?.underOneYear && !parcelsLoading">
            <gmt-labeled-block [label]="occupationUnderYearTableName">
              <table-occupations-temporary [data]="dataOccupationGroups.underOneYear" [columns]="occupationUnderOneYearColumns"></table-occupations-temporary>
            </gmt-labeled-block>
          </div>

          <div *ngIf="dataOccupationGroups?.overOneYear && !parcelsLoading">
            <gmt-labeled-block [label]="occupationOverYearTableName">
              <table-occupations-temporary [data]="dataOccupationGroups.overOneYear" [columns]="occupationOverOneYearColumns"></table-occupations-temporary>
            </gmt-labeled-block>
          </div>

          <div *ngIf="dataEasements && !easementsLoading && dataEasements.length > 0">
            <gmt-labeled-block [collapsed]="false" label="{{ 'EASEMENT' | wordtranslate }}">
              <table-easements [data]="dataEasements" [columns]="easementColumns"></table-easements>
            </gmt-labeled-block>
          </div>
        </div>

        <div *ngIf="tab === 'otherParcels'">
          <gmt-labeled-block label="Ostatní parcely v zájmovém území">
            <div class="bordered" [loading]="parcelsOtherLoading">
              <div *ngIf="!parcelsOtherLoading">
                <div *ngIf="!dataParcelsOther.length && !parcelsOtherLoading" class="empty-info">Žádné parcely</div>
                <table *ngIf="dataParcelsOther.length">
                  <thead>
                  <tr>
                    <th>Parcela</th>
                    <th>Případ</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let parcel of dataParcelsOther">
                    <td class="no-padding cell-max-height">
                      <cell-parcel
                        [parcel]="parcel"
                        [tooltipType]="'parcel'"
                        [tooltipData]="{ parcel: parcel, withoutTitle: true }"
                        [showProtection]="true"
                      ></cell-parcel>
                    </td>
                    <td class="no-padding cell-max-height">
                      <cell-cases
                        [casesCount]="parcel.countCases"
                        [tooltipType]="'cases'"
                        [tooltipData]="{ filter: { filters: { parcelId: parcel.id, cancelStatus: 'notCancelled' }}}"
                      ></cell-cases>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </gmt-labeled-block>
        </div>

        <div *ngIf="tab === 'buildings'">
          <gmt-labeled-block label="Budovy">
            <div class="bordered" [loading]="buildingsLoading || parcelsLoading || easementsLoading">
              <div *ngIf="!buildingsLoading && dataBuildings.length === 0" class="empty-info">Žádné budovy</div>
              <table *ngIf="!buildingsLoading && dataBuildings.length !== 0">
                <thead>
                <tr>
                  <th>Parcela</th>
                  <th>LV parcely</th>
                  <th>LV budovy</th>
                  <th>Část obce</th>
                  <th>Číslo budovy</th>
                  <th>Typ a Využití</th>
                  <th>Ochrana</th>
                  <!-- radit? -->
                  <th>Případy</th>
                  <th>Cena [<span class="unit">Kč</span>]</th>
                </tr>
                </thead>
                <tbody *ngFor="let itemBuilding of dataBuildings" class="hover">
                <tr *ngFor="let itemParcel of itemBuilding.parcels; let index = index">
                  <td class="no-padding">
                    <div class="fill-content">
                      <div class="pt-10 pl-10" *ngIf="itemParcel.soucasti">
                        součástí
                      </div>
                      <cell-parcel
                        [parcel]="itemParcel"
                        [tooltipType]="'parcel'"
                        [tooltipData]="{ parcel: itemParcel }"
                      ></cell-parcel>
                    </div>
                  </td>
                  <td class="number">
                    <span *ngIf="!itemParcel.title">{{ itemParcel.lv }}</span>
                    <a *ngIf="itemParcel.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: itemParcel.title.id, tab: null }">{{ itemParcel.title.lv }}</a>
                  </td>
                  <td *ngIf="index === 0" [attr.rowspan]="itemBuilding.parcels.length" class="number">
                    <span *ngIf="!itemBuilding.title">{{ itemBuilding.cisloTel }}</span>
                    <a *ngIf="itemBuilding.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: itemBuilding.title.id, tab: null }">{{ itemBuilding.title.lv }}</a>
                  </td>
                  <td *ngIf="index === 0" [attr.rowspan]="itemBuilding.parcels.length">{{ itemBuilding.castobce }}</td>
                  <td *ngIf="index === 0" [attr.rowspan]="itemBuilding.parcels.length">{{ itemBuilding | buildingNumber:true }}</td>
                  <td *ngIf="index === 0" [attr.rowspan]="itemBuilding.parcels.length">{{ itemBuilding.typ }}<br>{{ itemBuilding.vyuziti }}</td>
                  <td *ngIf="index === 0" [attr.rowspan]="itemBuilding.parcels.length">
                    <span class="comma-list">
                      <span *ngFor="let itemProtection of itemBuilding.buildingProtections">{{itemProtection.nazevOchrany}}</span>
                    </span>
                  </td>
                  <td *ngIf="index === 0" [attr.rowspan]="itemBuilding.parcels.length" class="no-padding cell-max-height">
                    <cell-cases
                      [casesCount]="itemBuilding.countCases"
                      [tooltipType]="'cases'"
                      [tooltipData]="{ filter: { filters: { buildingId: itemBuilding.id, cancelStatus: 'notCancelled' }}}"
                    ></cell-cases>
                  </td>
                  <td *ngIf="index === 0" [attr.rowspan]="itemBuilding.parcels.length">{{ itemBuilding.buildingsPrice | number | price : false }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </gmt-labeled-block>
        </div>

        <div *ngIf="tab === 'cases'">
          <div [loading]="caseList.loading">
            <table-cases
              [columnNames]="{lv: 'LV případu'}"
              [list]="caseList"
              (sort)="onCaseTableSort($event)"
            ></table-cases>
          </div>
        </div>

        <div *ngIf="tab === 'notifications'">
          <!-- User can edit notifications only on his notifications -->
          <gmt-notifications
            *ngIf="!loading"
            [notificationList]="notificationList"
            (updated)="onUpdateNotificationList()"
          ></gmt-notifications>
        </div>

        <div *ngIf="tab === 'documents'">
          <div class="attachments-flex">
            <gmt-documents
              [documentList]="documentList"
              [defaultData]="{ titleId: data.id }"
              (updated)="onUpdateDocumentList();loadHistory();"
              [authorizedUser]="canEdit()"
              [allowType]="false"
            ></gmt-documents>
          </div>
        </div>

        <div *ngIf="tab === 'notes'">
          <gmt-notes
            [noteList]="noteList"
            [editable]="true"
            [allowType]="true"
            [defaultData]="{ title: { id: data.id }}"
            (updated)="onUpdateNoteList()"
          ></gmt-notes>
        </div>

        <div *ngIf="tab === 'history'">
          <gmt-notes
            [noteList]="historyList"
            [editable]="false"
            type="history"
          ></gmt-notes>
        </div>
      </gmt-navbar>
    </div>
  </div>

  <help [helpId]="helpIds.TITLE_DETAIL" class="cm-help-page"></help>
</div>
