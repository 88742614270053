import { CellOpinions } from './components/cell-opinions/cell-opinions.component';
import { CellGeometricPlans } from './components/cell-geometric-plans/cell-geometric-plans.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '../common/common.module';

import { NotificationsComponent } from './components/notifications/notifications.component';
import { TitlesShortListComponent } from './components/titles-short-list/titles-short-list.component';
import { TableCasesComponent } from './components/table-cases/table-cases.component';
import { TableEasementsComponent } from './components/table-easements/table-easements.component';
import { TableOccupationsTemporaryComponent } from './components/table-occupations-temporary/table-occupations-temporary.component';
import { TableOccupationsPermanentComponent } from './components/table-occupations-permanent/table-occupations-permanent.component';
import { SettlementProgressChartComponent } from './components/settlement-progress-chart/settlement-progress-chart.component';
import { StatisticsEntityFilterComponent } from './components/statistics-entity-filter/statistics-entity-filter.component';
import { ParcelCostsFormComponent } from '@app/ps/components/parcel-costs-form/parcel-costs-form.component';
import { TableGeometricPlansComponent } from '@app/ps/components/table-geometric-plans/table-geometric-plans.component';
import { AresFormComponent } from '@app/ps/components/ares-form/ares-form.component';
import { ExternalParcelFormComponent } from '@app/ps/components/external-parcel-form/external-parcel-form.component';
import { ExpertEditFormComponent } from '@app/ps/components/expert-edit-form/expert-edit-form.component';
import { ExpertSelectFormComponent } from '@app/ps/components/expert-select-form/expert-select-form.component';
import { OpinionBasicFormComponent } from '@app/ps/components/opinion-basic-form/opinion-basic-form.component';
import { PricingExpertOpinionRowComponent } from '@app/ps/components/pricing-expert-opinion-row/pricing-expert-opinion-row.component';
import { PricingOtherSourceRowComponent } from '@app/ps/components/pricing-other-source-row/pricing-other-source-row.component';
import { ParcelEditFormComponent } from '@app/ps/components/parcel-edit-form/parcel-edit-form.component';
import { TableParcelsSmallComponent } from '@app/ps/components/table-parcels-small/table-parcels-small.component';
import { CadastreDataDateComponent } from '@app/ps/components/cadastre-data-date/cadastre-data-date.component';
import { TableTitlesComponent } from './components/table-titles/table-titles.component';
import { SeriesComponent } from '@app/ps/components/serie/series.component';
import { AdministratorEditComponent } from '@app/ps/components/administrator-edit/administrator-edit.component';
import { ProjectSubjectComponent } from '@app/ps/components/project-subject/project-subject.component';
import { ProjectSubjectAgentComponent } from '@app/ps/components/project-subject-agent/project-subject-agent.component';
import { UpdateEasementPriceComponent } from '@app/ps/components/update-easement-price/update-easement-price.component';
import { TableBuildingsComponent } from '@app/ps/components/table-buildings/table-buildings.component';
import { StatisticsComponent } from '@app/ps/components/statistics/statistics.component';
import { LineChartComponent } from '@app/ps/components/line-chart/line-chart.component';
import { StatisticsCustomFormComponent } from '@app/ps/components/statistics-custom-form/statistics-custom-form.component';
import { SeriesListComponent } from '@app/ps/components/series-list/series-list.component';
import { CellCaseSubjectsComponent } from '@app/ps/components/cell-case-subjects/cell-case-subjects.component';
import { TableSubjectsComponent } from '@app/ps/components/table-subjects/table-subjects.component';
import { TableParcelsComponent } from '@app/ps/components/table-parcels/table-parcels.component';
import { UpdateOccupationComponent } from '@app/ps/components/update-occupation/update-occupation.component';

import { OccupationNamePipe } from './pipes/occupation-name.pipe';
import { SettlementCategoryPipe } from './pipes/settlement-category.pipe';
import { ActingPersonNamePipe } from './pipes/acting-person-name.pipe';
import { ProjectSubjectNamePipe } from './pipes/project-subject-name.pipe';
import { KnGpNamePipe } from './pipes/kn-gp-name.pipe';
import { ProjectSubjectTypeNamePipe } from '@app/ps/pipes/project-subject-type-name.pipe';
import { ParentCaseOwnershipNamePipe } from '@app/ps/pipes/parent-case-ownership-name.pipe';
import { ProjectStatePipe } from '@app/ps/pipes/project-state.pipe';
import { TitleCreateSubjectComponent } from '@app/ps/components/title-create-subject/title-create-subject.component';
import { ReadinessPipe } from '@app/ps/pipes/readiness.pipe';
import { CaseNamePipe } from '@app/ps/pipes/case-name.pipe';
import { EasementOccupationPipe } from '@app/ps/pipes/easement-occupation.pipe';
import { IsprofondPipe } from '@app/ps/pipes/isprofond.pipe';
import { PriceRoundingPrecisionTypeNamePipe } from '@app/ps/pipes/price-rounding-precision-type-name.pipe';
import { UIRouterModule } from '@uirouter/angular';


@NgModule({
  imports: [
    UIRouterModule,
    CommonModule,
  ],
  providers: [],
  declarations: [
    NotificationsComponent,
    TitlesShortListComponent,
    TableCasesComponent,
    TableEasementsComponent,
    TableOccupationsTemporaryComponent,
    TableOccupationsPermanentComponent,
    SettlementProgressChartComponent,
    StatisticsEntityFilterComponent,
    ParcelCostsFormComponent,
    TableGeometricPlansComponent,
    AresFormComponent,
    ExternalParcelFormComponent,
    ExpertEditFormComponent,
    ExpertSelectFormComponent,
    OpinionBasicFormComponent,
    PricingExpertOpinionRowComponent,
    PricingOtherSourceRowComponent,
    ParcelEditFormComponent,
    TableParcelsSmallComponent,
    CadastreDataDateComponent,
    TableTitlesComponent,
    CellGeometricPlans,
    CellOpinions,
    SeriesComponent,
    AdministratorEditComponent,
    ProjectSubjectComponent,
    ProjectSubjectAgentComponent,
    UpdateEasementPriceComponent,
    TableBuildingsComponent,
    StatisticsComponent,
    LineChartComponent,
    StatisticsCustomFormComponent,
    SeriesListComponent,
    CellCaseSubjectsComponent,
    TableSubjectsComponent,
    TableParcelsComponent,
    TitleCreateSubjectComponent,
    UpdateOccupationComponent,

    OccupationNamePipe,
    SettlementCategoryPipe,
    ActingPersonNamePipe,
    ProjectSubjectNamePipe,
    KnGpNamePipe,
    ProjectSubjectTypeNamePipe,
    ParentCaseOwnershipNamePipe,
    ProjectStatePipe,
    ReadinessPipe,
    CaseNamePipe,
    EasementOccupationPipe,
    IsprofondPipe,
    PriceRoundingPrecisionTypeNamePipe,

  ],
  entryComponents: [
    NotificationsComponent,
    SettlementProgressChartComponent,
    TableCasesComponent,
    ExternalParcelFormComponent,
    ExpertEditFormComponent,
    TableTitlesComponent,
    AdministratorEditComponent,
    UpdateEasementPriceComponent,
    ProjectSubjectAgentComponent,
    StatisticsCustomFormComponent,
    TableSubjectsComponent,
    TableParcelsComponent,
    TitleCreateSubjectComponent,

    // For downgrade
    ParcelCostsFormComponent,
    TableEasementsComponent,
    TableOccupationsPermanentComponent,
    TableOccupationsTemporaryComponent,
    TableGeometricPlansComponent,
    AresFormComponent,
    TableParcelsSmallComponent,
    CellGeometricPlans,
    CellOpinions,
  ],
  exports: [
    NotificationsComponent,
    TitlesShortListComponent,
    TableCasesComponent,
    TableEasementsComponent,
    TableOccupationsTemporaryComponent,
    TableOccupationsPermanentComponent,
    SettlementProgressChartComponent,
    StatisticsEntityFilterComponent,
    ParcelCostsFormComponent,
    TableGeometricPlansComponent,
    AresFormComponent,
    ExternalParcelFormComponent,
    ExpertEditFormComponent,
    ExpertSelectFormComponent,
    OpinionBasicFormComponent,
    PricingExpertOpinionRowComponent,
    PricingOtherSourceRowComponent,
    ParcelEditFormComponent,
    TableParcelsSmallComponent,
    CadastreDataDateComponent,
    TableTitlesComponent,
    CellGeometricPlans,
    CellOpinions,
    SeriesComponent,
    AdministratorEditComponent,
    ProjectSubjectComponent,
    ProjectSubjectAgentComponent,
    UpdateEasementPriceComponent,
    TableBuildingsComponent,
    StatisticsComponent,
    LineChartComponent,
    StatisticsCustomFormComponent,
    SeriesListComponent,
    CellCaseSubjectsComponent,
    TableSubjectsComponent,
    TableParcelsComponent,
    TitleCreateSubjectComponent,
    UpdateOccupationComponent,

    OccupationNamePipe,
    SettlementCategoryPipe,
    ActingPersonNamePipe,
    ProjectSubjectNamePipe,
    KnGpNamePipe,
    ProjectSubjectTypeNamePipe,
    ParentCaseOwnershipNamePipe,
    ProjectStatePipe,
    ReadinessPipe,
    CaseNamePipe,
    EasementOccupationPipe,
    IsprofondPipe,
    PriceRoundingPrecisionTypeNamePipe,
  ],
})
export class PsCommonModule {}
