import { Component, Input, OnInit } from '@angular/core';
import { GmtHttpError } from '@app/common/services/http.service';
import { CreateCaseStepEnum } from '@app/ps/enums/create-case-step.enum';

@Component({
  selector: 'create-case-status',
  templateUrl: './create-case-status.component.html',
})
export class CreateCaseStatusComponent implements OnInit {
  @Input()
  isLoading = true;

  @Input()
  processSteps = [];

  @Input()
  errors: GmtHttpError[] = [];

  stepEnum = CreateCaseStepEnum;

  ngOnInit(): void {

  }
}
