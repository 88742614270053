import {
  Component,
  ComponentRef,
  Type,
  OnDestroy,
  AfterViewInit,
  ComponentFactoryResolver,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Subject } from 'rxjs';

import { InsertionDirective } from '../../directives/insertion.directive';
import { DialogRef } from '../../services/dialog-ref';
import { DialogConfig } from '../../models/dialog-config';
import {ClassName} from "@app/common/enums/class-name.enum";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;
  childComponentType: Type<any>;
  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  public className: string;
  private updateScrollbar: Function;

  @ViewChild(InsertionDirective)
  insertionPoint: InsertionDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
    private dialogRef: DialogRef,
    private elementRef: ElementRef,
    private dialogConfig: DialogConfig,
  ) {
    this.onScrollbarRegister = this.onScrollbarRegister.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.className = this.dialogConfig.className;
    if (typeof this.dialogConfig.data === 'object') {
      for (let [key, value] of Object.entries(this.dialogConfig.data)) {
        this.componentRef.instance[key] = value;
      }
    }
    this.changeDetector.detectChanges();
    this.onWindowResize();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onOverlayClicked(evt: MouseEvent) {
    if (this.elementRef.nativeElement.children[0] === evt.target && this.className !== ClassName.UNCLOSABLE_DIALOG) {
      this.close(false);
    }
  }

  onEscape(evt: KeyboardEvent) {
    const ESC_KEY = 27;
    if (evt.keyCode === ESC_KEY && this.className !== ClassName.UNCLOSABLE_DIALOG) {
      this.close(false);
    }
  }

  onClosed(evt: MouseEvent) {
    if (this.className !== ClassName.UNCLOSABLE_DIALOG) {
      this.close(false);
    }
  }

  private close(val: boolean) {
    this.dialogRef.close(val);
  }

  loadChildComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  @HostListener('window:resize')
  onWindowResize() {
    const scroll = $(this.elementRef.nativeElement.querySelector('.modal-content > * > .scrollable'));
    scroll.css('max-height', ($(window).height() - 80) + 'px');
    this.updateScrollbar();
  }

  onScrollbarRegister(updateScrollbar) {
    this.updateScrollbar = updateScrollbar;
  }
}
