import { Component, Inject, Input, OnInit } from '@angular/core';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { TitleModel } from '@app/common/models/title.model';
import { AuthService } from '@app/common/services/auth.service';
import { OpinionService } from '@app/ps/services/opinion.service';
import { StateService, TransitionService } from '@uirouter/angular';
import { ParcelPriceModel } from '@app/ps/models/parcel-price.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { OwnershipNamePipe } from '@app/common/pipes/ownership-name.pipe';
import { ParcelModel } from '@app/common/models/parcel.model';
import { NumericPipe } from '@app/common/pipes/numeric.pipe';
import * as _ from 'lodash';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';
import {ParcelPriceService} from "@app/ps/services/parcel-price.service";
import { RoundingService } from '@app/ps/services/rounding.service';
import { DecimalPipe } from '@app/common/pipes/decimal.pipe';

@Component({
  selector: 'pricing-other-source',
  templateUrl: './pricing-other-source.component.html',
})
export class PricingOtherSourceComponent implements OnInit {

  @Input() opinionId: number;
  @Input() titleId: number;
  @Input() occupationType: 'P' | 'T';

  loading = true;
  title: TitleModel;
  opinion: OpinionModel;
  readonly = !this.authService.hasPermission('assignable');
  canDelete: boolean;
  parcelPrices: ParcelPriceModel[] = [];
  checklistParcelPrices: any;
  checkedAll = false;
  selectOwnerships: any;
  totalPrice = 0;
  type: 'O' | 'T';

  switchTypes: SwitchOption[] = [
    {
      value: 'Pro celé LV',
      id: 'T',
    },
    {
      value: 'Pro vlastnický podíl',
      id: 'O',
    },
  ];
  helpIds = HelpService.HELP_IDS;

  private dataOwnerships: any[] = [];
  private dataParcelPrices: ParcelPriceModel[] = [];
  private deregisterLeaveListener: Function;

  constructor(
    private restangular: Restangular,
    public ownershipNamePipe: OwnershipNamePipe,
    private authService: AuthService,
    private opinionService: OpinionService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private numericPipe: NumericPipe,
    private dialogService: DialogService,
    private parcelPriceService: ParcelPriceService,
    private decimalPipe: DecimalPipe,
  ) {
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  async ngOnInit() {
    await this.loadTitle();

    if (this.opinionId) {
      await this.loadOpinion();
      await this.loadOpinionPrices();
    } else {
      this.opinion = this.occupationType === 'P'
        ? OpinionModel.createDefaultOtherSourcePermanent()
        : OpinionModel.createDefaultOtherSourceTemporary();
      this.opinion.title = <TitleModel>{ id: this.titleId };
      this.opinion.parcelPrices = [];
      await this.loadParcelPrices();
    }

    this.canDelete = !!(this.authService.hasPermission('assignable') && this.opinionId);
    this.type = this.opinion.otherOwnership ? 'O' : 'T';
    this.checklistParcelPrices = new ChecklistModel(this.opinion.parcelPrices);
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
    this.loading = false;
  }

  onDelete(): Promise<any> {
    return this.dialogService.confirmDialogPromise('Všechna zadaná data budou ztracena.')
      .then(data => {
        if (data === true) {
          this.deregisterLeaveListener();
          return this.opinionService.deleteOne(this.opinion.id).then(() => true);
        } else {
          return false;
        }
      }).then(deleted => {
        if (deleted) {
          this.onClose();
        }
      });
  }

  onClose() {
    this.stateService.go('symap.project.titles.detail.pricing.overview', { titleId: this.titleId });
  }

  onSave() {
    this.deregisterLeaveListener();

    if (this.opinion.id) {
      return this.opinionService
        .updateOne(this.opinion.id, this.opinion)
        .then(() => this.onClose());
    }

    return this.opinionService
      .createOne(this.opinion)
      .then(() => this.onClose());
  }

  isFormValid(): boolean {
    const parcelsSelected = this.opinion.parcelPrices.length;
    const priceTypeValid = (this.opinion.otherOwnership && this.type === 'O') || this.type === 'T';
    const parcelsValid = this.opinion.parcelPrices.every(this.isParcelPriceValid, this);
    return !!(parcelsSelected && priceTypeValid && parcelsValid && this.opinion.otherSource);
  }

  computeTotalPrice() {
    this.totalPrice = this.opinion.parcelPrices.reduce((acc, currVal) => this.computeParcelPrice(currVal) + acc, 0);
  }

  computeParcelPrice(parcelPrice: ParcelPriceModel): number {
    const landPrice = parcelPrice.otherLandPrice && this.occupationType === 'P' ? this.numericPipe.transform(parcelPrice.otherLandPrice) : 0;
    const vegetationPrice = parcelPrice.otherVegetationPrice ? this.numericPipe.transform(parcelPrice.otherVegetationPrice) : 0;
    const compensationPrice = parcelPrice.otherCompensationPrice ? this.numericPipe.transform(parcelPrice.otherCompensationPrice) : 0;
    const buildingPrice = parcelPrice.otherBuildingsPrice && this.occupationType === 'P' ? this.numericPipe.transform(parcelPrice.otherBuildingsPrice) : 0;
    return landPrice + vegetationPrice + compensationPrice + buildingPrice;
  }

  isDisabled(parcelPrice: ParcelPriceModel): boolean {
    let isDisabled;
    const parcelPriceWithoutPermanentOpinion = item => item.opinion.priceType === 'O' && item.parcel && parcelPrice.parcel.id === item.parcel.id;
    const ownershipMatches = item => item.opinion.otherOwnership && item.opinion.otherOwnership.id === this.opinion.otherOwnership.id;
    const soloOwner = this.opinion.otherOwnership && this.opinion.otherOwnership.podilCitatel === this.opinion.otherOwnership.podilJmenovatel;

    if (this.opinion.otherOwnership) {
      isDisabled = this.dataParcelPrices.some(item => parcelPriceWithoutPermanentOpinion(item) && (ownershipMatches(item) || soloOwner));
    } else {
      isDisabled = this.dataParcelPrices.some(item => parcelPriceWithoutPermanentOpinion(item) && !item.opinion.otherOwnership);
    }

    return isDisabled;
  }

  checkAll() {
    this.checkedAll = !this.checkedAll;
    this.opinion.parcelPrices.splice(0);

    if (this.checkedAll) {
      this.parcelPrices
        .filter(item => !this.isDisabled(item), this)
        .forEach(item => this.opinion.parcelPrices.push(item), this);
    }

    this.updateOwnerships();
  }

  toggleSelection(parcelPrice: ParcelPriceModel) {
    if (!this.isDisabled(parcelPrice)) {
      this.checklistParcelPrices.toggleSelection(parcelPrice);
    }
    this.updateOwnerships();
    this.computeTotalPrice();
  }

  togglePriceType(type: string) {
    if (type === 'T') {
      this.opinion.otherOwnership = null;
      this.opinion.parcelPrices
        .filter(this.isDisabled, this)
        .forEach(this.checklistParcelPrices.toggleSelection);
    }
  }

  getCurrentPriceType(): string | number {
    return this.switchTypes.filter(obj => obj.id === this.type)[0].value;
  }

  private loadTitle(): Promise<any> {
    return this.restangular
      .one('titles', this.titleId)
      .get({ loadCollections: [ 'ownerships', 'ownerships.subjects' ]})
      .toPromise()
      .then(res => {
        this.title = res.plain();
        this.dataOwnerships = this.title.ownerships;
      });
  }

  private loadOpinion(): Promise<any> {
    return this.opinionService
      .getOne(this.opinionId)
      .then(res => this.opinion = res);
  }

  private loadOpinionPrices(): Promise<any> {
    return Promise.all(this.prepareApiCalls()).then((res) => {
      // loadOpinionParcelPrices
      res[0].forEach((parcelPrice) => {
        this.opinion.parcelPrices.push(parcelPrice);
        this.parcelPrices.push(parcelPrice);

        this.readonly = this.readonly || parcelPrice.caseOwnershipExists;
        this.canDelete = this.canDelete && !parcelPrice.caseOwnershipExistsAndNotCancelled;
      });

      // loadTitleParcelPrices
      this.dataParcelPrices = [];
      res[1].filter(parcelPrice => parcelPrice.opinion.id !== this.opinionId)
        .forEach((parcelPrice) => {
          this.dataParcelPrices.push(parcelPrice);
        });

      // loadFilteredParcels
      if (!this.readonly) {
        res[2].filter(item => this.parcelPrices
          .map(pp => pp.idpar)
          .indexOf(item.idpar) === -1)
          .forEach(parcel => this.parcelPrices.push(<ParcelPriceModel>{
            parcel: parcel,
            otherLandPrice: '0',
            otherVegetationPrice: '0',
            otherBuildingsPrice: '0',
          }));

        res[3].filter(item => this.parcelPrices
                        .filter(pp => pp.building)
                        .map(pp => pp.building.budId)
                        .indexOf(item.budId) === -1)
          .forEach((building) => {
          const doesBuildingMatch = this.parcelPrices.find(parcelPrice => this.parcelPriceService.buildingsMatch(building, parcelPrice));
          const item = {
            building: building,
            otherLandPrice: '0',
            otherVegetationPrice: '0',
            otherBuildingsPrice: '0',
            otherCompensationPrice: '0',
          } as ParcelPriceModel;

          if (!doesBuildingMatch && this.occupationType === 'P') {
            this.parcelPrices.push(item);
          }
        });
      }

      this.updateOwnerships();
      this.computeTotalPrice();
    });
  }

  private loadParcelPrices(): Promise<any> {
    return Promise.all(this.prepareApiCalls()).then((res) => {
      this.dataParcelPrices = res[0];
      res[1].forEach((parcel: ParcelModel) => {
        this.parcelPrices.push(<ParcelPriceModel>{
          parcel: parcel,
          otherLandPrice: '0',
          otherVegetationPrice: '0',
          otherBuildingsPrice: '0',
          otherCompensationPrice: '0',
        });
      });
      res[2].forEach((building) => {
        const doesBuildingMatch = this.parcelPrices.find(parcelPrice => this.parcelPriceService.buildingsMatch(building, parcelPrice));
        const item = {
          building: building,
          otherLandPrice: '0',
          otherVegetationPrice: '0',
          otherBuildingsPrice: '0',
          otherCompensationPrice: '0',
        } as ParcelPriceModel;

        if (!doesBuildingMatch && this.occupationType === 'P') {
          this.parcelPrices.push(item);
        }
      });
      this.checkAll();
    });
  }

  private prepareApiCalls() {
    const parcelsFilter = {
      filters: {
        titleId: [ this.titleId ],
        // validBuyoutOpinion: [ false ],
        validity: [ 'valid' ],
      },
    };
    parcelsFilter.filters[ (this.opinion.occupationType === 'P' ? 'permanent' : 'temporary') + 'OccupationExists' ] = [ true ];

    const buildingsFilter = {
      filters: {
        occupationOrEasementExists: [ true ],
        titleId: [ this.titleId ],
        // validBuyoutOpinion: [ false ],
        validity: [ 'valid' ],
        differentTitle: [ true ],
        loadCollections: [
          'parcelsWithEasementOrOccupation',
          'buildingProtections',
          'parcels.parcelProtections',
        ],
      },
    };

    const calls = [];
    if (this.opinionId) {
      calls.push(this.opinionService.getParcelPrices(this.opinionId));
    }
    calls.push(this.opinionService.getTitleParcelPrices(this.titleId));
    calls.push(this.opinionService.getParcels(parcelsFilter));
    calls.push(this.opinionService.getBuildings(buildingsFilter));
    return calls;
  }

  private isParcelPriceValid(parcelPrice: ParcelPriceModel): boolean {
    const isValidLandPrice = this.decimalPipe.transform(parcelPrice.otherLandPrice) && <any>parcelPrice.otherLandPrice !== '' && this.occupationType === 'P';
    const isValidVegetationPrice = this.decimalPipe.transform(parcelPrice.otherVegetationPrice) && <any>parcelPrice.otherVegetationPrice !== '' && this.occupationType === 'P';
    const isValidCompensationPrice = this.decimalPipe.transform(parcelPrice.otherCompensationPrice) && <any>parcelPrice.otherCompensationPrice !== '' && this.occupationType === 'T';
    const isValidBuildingPrice = this.decimalPipe.transform(parcelPrice.otherBuildingsPrice) && <any>parcelPrice.otherBuildingsPrice !== '' && this.occupationType === 'P';
    return (isValidLandPrice && isValidVegetationPrice && isValidBuildingPrice) || isValidCompensationPrice;
  }

  private updateOwnerships() {
    this.selectOwnerships = this.dataOwnerships.filter(ownership => {
      let result = true;
      _.forEach(this.opinion.parcelPrices, (newParcelPrice) => {
        _.forEach(this.dataParcelPrices, (existingParcelPrice) => {
          if (existingParcelPrice.parcel && newParcelPrice.parcel.id === existingParcelPrice.parcel.id && existingParcelPrice.opinion.priceType === 'O' &&
            ((existingParcelPrice.opinion.otherOwnership && ownership.id === existingParcelPrice.opinion.otherOwnership.id) || ownership.podilCitatel === ownership.podilJmenovatel)) {
            result = false;
            return false;
          }
        });
        return result;
      });
      return result;
    });
  }

  private beforeExit(): Promise<any> {
    if (this.readonly) {
      this.deregisterLeaveListener();
      return Promise.resolve(true);
    }

    return this.dialogService.confirmDialogPromise('Všechny úpravy budou ztraceny.')
      .then(data => {
        if (data === true) {
          this.deregisterLeaveListener();
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      });
  }
}
