import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface SwitchOption {
  id: string | number;
  value: string | number;
}

@Component({
  selector: 'gmt-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent implements OnInit {
  @Input() options: SwitchOption[];
  @Input() selected: SwitchOption;
  @Output() changedAfter: EventEmitter<any> = new EventEmitter<any>();
  @Output() changed: EventEmitter<any> = new EventEmitter<any>(true);
  @Output() selectedChange: EventEmitter<SwitchOption> = new EventEmitter<SwitchOption>();
  @Output() select: EventEmitter<SwitchOption> = new EventEmitter<SwitchOption>();
  @Input() selectedId: string | number;
  @Output() selectedIdChange: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() onSwitchMouseEnter = new EventEmitter<string | number>();
  @Output() onSwitchMouseLeave = new EventEmitter<string | number>();

  ngOnInit() {
    this.changed.subscribe((a) => {
      if (a.promise) {
        a.promise.then(result => {
          if (result !== false) {
            this.changeSelection(a.newValue);
            this.changedAfter.emit(a);
          }
        });
      } else {
        this.changeSelection(a.newValue);
        this.changedAfter.emit(a);
      }
    });
  }

  private changeSelection(option: SwitchOption) {
    this.selected = option;
    this.selectedChange.emit(this.selected);
    this.select.emit(this.selected);
    this.selectedId = this.selected.id;
    this.selectedIdChange.emit(this.selectedId);
  }

  onSelect(option: SwitchOption) {
    this.changed.emit({
      newValue: option,
      oldValue: this.selected,
      promise: undefined,
    });
  }
}
