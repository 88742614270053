<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <div *ngIf="step === 'accepted'">
      <fieldset>
        <div class="column-100">
          <label class="required">Datum příklepu</label>
          <div class="input"><input type="text" pick-a-date [(ngModel)]="statusData.auctionWinDate" [maxToday]="true"/></div>

          <label class="required">Datum nabytí právní moci rozhodnutí</label>
          <div class="input"><input type="text" pick-a-date [(ngModel)]="statusData.auctionWinLegalEffectsDate"/></div>

          <label class="required">Celková cena podání</label>
          <div class="input"><input type="text" [(ngModel)]="statusData.auctionWinAmount" gmtInputNumber/></div>
        </div>
      </fieldset>

      <fieldset visibleByBrand="mpv">
        <legend>Finanční rekapitulace, zveřejnění v registru smluv</legend>

        <div *ngIf="data.fulfillmentPrice" class="bordered-bottom mb-20 pb-10">
          <div class="column-100">
            <div class="label">Finanční plnění</div>
            <div class="info">
              {{ data.fulfillmentPrice | number | price }}
              <span *ngIf="!isFulfillmentPriceValid()" class="error-message"> Zadané ceny u podílů neodpovídají celkové ceně</span>
            </div>
          </div>
          <div class="cb"></div>
        </div>

        <div *ngFor="let caseOwnership of subjectsData.dataCaseOwnerships">
          <sign-owner-validation
            [caseOwnership]="caseOwnership"
            [isSubjectSigned]="isSubjectSigned"
            [regularPriceObligation]="false"
            [fulfillmentPrice]="data.fulfillmentPrice"
          ></sign-owner-validation>
        </div>

        <div class="column-100">
          <gmt-input label="Zveřejnění v registru smluv" [required]="true">
            <gmt-switch [options]="contractRegisterPublicationOptions" [(selectedId)]="contractRegisterPublication"></gmt-switch>
          </gmt-input>
        </div>
      </fieldset>
    </div>

    <div class="mt-30">
      <gmt-button class="red" [clickAction]="onDecline" *ngIf="step === 1">Nevydraženo</gmt-button>
      <gmt-button (click)="setStep('accepted')" *ngIf="step === 1">{{ name }}</gmt-button>
      <gmt-button class="black" (click)="setStep(1)" *ngIf="step === 'accepted'">Zpět</gmt-button>
      <gmt-button [clickAction]="onConfirm" [gmtDisabled]="!isStatusValid() || !isSubjectsValid()" *ngIf="step === 'accepted'">Uložit</gmt-button>
    </div>
  </div>
</div>
