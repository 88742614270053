<div class="list">
  <h1>Zábory</h1>
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
    <gmt-select
      *ngIf="!hideColumn('zubkutx')"
      [multiselect]="true"
      [data]="list.filter.filters.areaId"
      field="values"
      [filter]="{ sortOrder: { sortBy: 'name' }}"
      enableSelectAll="true"
      [(selectAll)]="list.filter.filters.areaId.negation"
      resource="areas"
      selectTitle="Katastrální území"
      logGa
    ></gmt-select>

    <gmt-select
      *ngIf="!hideColumn('lv')"
      [multiselect]="true"
      [data]="list.filter.filters.titleId"
      field="values"
      resource="titles"
      selectTitle="LV"
      [itemPipe]="titleNamePipe"
      logGa
    ></gmt-select>

    <gmt-select
      *ngIf="!hideColumn('parcel')"
      [multiselect]="true"
      [data]="list.filter.filters.parcelId"
      field="values" resource="parcels"
      [filter]="{ filters: {validity: 'valid' }}"
      selectTitle="Parcela"
      [itemPipe]="parcelNumberPipe"
      logGa
    ></gmt-select>

    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.occupationTypeId"
      [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'name' }}"
      field="values"
      resource="occupation-types"
      selectTitle="Druh záboru"
      logGa
    ></gmt-select>

    <gmt-select
      *ngIf="!hideColumn('zabst')"
      [multiselect]="true"
      [data]="list.filter.filters.constructionObjectStageId"
      [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'name' }}"
      field="values"
      resource="construction-object-stages"
      selectTitle="{{ 'ETAPA_USEK' | wordtranslate }}"
      logGa
    ></gmt-select>

    <gmt-select
      *ngIf="!hideColumn('constructionObjects')"
      [multiselect]="true"
      [data]="list.filter.filters.constructionObjectId"
      [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'socis' }}"
      field="values"
      enableSelectAll="true"
      [(selectAll)]="list.filter.filters.constructionObjectId.negation"
      resource="construction-objects"
      selectTitle="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}"
      [itemPipe]="constructionObjectNamePipe"
      logGa
    ></gmt-select>

    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.validityParcelCase"
      field="values"
      [optionItems]="inputValidities"
      selectTitle="Platnost záboru"
      logGa
    ></gmt-select>

    <!-- Fulltext -->
    <div class="column-100">
      <input
        class="fulltext"
        type="text"
        autocomplete="off"
        placeholder="Fultextové hledání..."
        [(ngModel)]="list.filter.filters.searchText.values[0].id"
      />
    </div>
  </filter>

  <div [loading]="list.loading || !settingsLoaded">
    <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
      <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
      <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount && settingsLoaded">
      <table>
        <thead class="sticky">
        <tr>
          <th *ngIf="!hideColumn('lv')" [attr.rowspan]="rowspan" class="nowrap">
            <sort-by
              [selected]="'parcel.title.lv' === list.filter.sortOrder.sortBy"
              (sort)="pageableList.onSort('parcel.title.lv', $event)"
              [sortDir]="list.filter.sortOrder.direction"
            >LV</sort-by>
          </th>
          <th *ngIf="!hideColumn('zabkutx')" [attr.rowspan]="rowspan" class="nowrap">
            <sort-by
              [selected]="'zabkutx' === list.filter.sortOrder.sortBy"
              (sort)="pageableList.onSort('zabkutx', $event)"
              [sortDir]="list.filter.sortOrder.direction"
            >K.Ú.</sort-by>
          </th>
          <th *ngIf="!hideColumn('ownerships')" [attr.rowspan]="rowspan">Vlastník</th>
          <th *ngIf="!hideColumn('zabcis')" [attr.rowspan]="rowspan">
            <sort-by
              [selected]="'zabcis' === list.filter.sortOrder.sortBy"
              (sort)="pageableList.onSort('zabcis', $event)"
              [sortDir]="list.filter.sortOrder.direction"
            >Číslo</sort-by>
          </th>
          <th *ngIf="!hideColumn('zabst')" [attr.rowspan]="rowspan">
            <sort-by
              [selected]="'zabst' === list.filter.sortOrder.sortBy"
              (sort)="pageableList.onSort('zabst', $event)"
              [sortDir]="list.filter.sortOrder.direction"
            >{{ 'ETAPA_USEK' | wordtranslate }}</sort-by>
          </th>
          <th *ngIf="!hideColumn('nabyvatel')" [attr.rowspan]="rowspan">
            <sort-by
              [selected]="'nabyvatel' === list.filter.sortOrder.sortBy"
              (sort)="pageableList.onSort('nabyvatel', $event)"
              [sortDir]="list.filter.sortOrder.direction"
            >Nabyvatel</sort-by>
          </th>
          <th *ngIf="!hideColumn('investor')" [attr.rowspan]="rowspan">
            <sort-by
              [selected]="'investor' === list.filter.sortOrder.sortBy"
              (sort)="pageableList.onSort('investor', $event)"
              [sortDir]="list.filter.sortOrder.direction"
            >Investor</sort-by>
          </th>
          <th *ngIf="!hideColumn('parcel')" [attr.rowspan]="rowspan">
            <sort-by
              [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[0].sortBy === 'parcel.parcisKmen'"
              (sort)="pageableList.onSort(['parcel.parcisKmen','parcel.parcisPod'], $event)"
              [sortDir]="list.filter.sortOrder[0]?.direction"
            >Parcela</sort-by>
          </th>
          <th *ngIf="!hideColumn('cases')" [attr.rowspan]="rowspan">
            <sort-by
              [selected]="'countCases' === list.filter.sortOrder.sortBy"
              (sort)="pageableList.onSort('countCases', $event)"
              [sortDir]="list.filter.sortOrder.direction"
            >Případy</sort-by>
          </th>
          <th *ngIf="!hideColumn('constructionObjects')" [attr.rowspan]="rowspan">{{ 'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true }}</th>
          <th
            *ngIf="occupationTypesPermanent.length > 0"
            [ngClass]="{ 'bordered-bottom': shouldDrawSecondRowOf(occupationTypesPermanent) }"
            [attr.colspan]="occupationTypesPermanent.length"
            [attr.rowspan]="permanentOccupationRowspan"
          >{{ 'PERMANENT_OCCUPATION' | wordtranslate }} [<span class="unit">m<sup>2</sup></span>]</th>
          <th
            *ngIf="occupationTypesTemporary.length > 0"
            [ngClass]="{ 'bordered-bottom': shouldDrawSecondRowOf(occupationTypesTemporary)} "
            [attr.colspan]="occupationTypesTemporary.length"
            [attr.rowspan]="temporaryOccupationRowspan"
          >{{ 'TEMPORARY_OCCUPATION' | wordtranslate }} [<span class="unit">m<sup>2</sup></span>]</th>
        </tr>
        <tr *ngIf="shouldHaveSecondRow()">
          <ng-container *ngIf="shouldDrawSecondRowOf(occupationTypesPermanent)">
            <th class="column-8" *ngFor="let occupationType of occupationTypesPermanent">{{ occupationType.name }}</th>
          </ng-container>
          <ng-container *ngIf="shouldDrawSecondRowOf(occupationTypesTemporary)">
            <th class="column-8" *ngFor="let occupationType of occupationTypesTemporary">{{ occupationType.name }}</th>
          </ng-container>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let occupation of list.list" class="noclickable" style="height: 100%;">
          <td class="number" *ngIf="!hideColumn('lv')">
            <span *ngIf="occupation.parcel.title?.id">
              <a uiSref="symap.project.titles.detail" [uiParams]="{ id: occupation.parcel.title.id, page: null }">
                {{ occupation.parcel.title.lv }}
              </a>
            </span>
          </td>
          <td *ngIf="!hideColumn('zabkutx')">{{ occupation.zabkutx }}</td>
          <td *ngIf="!hideColumn('ownerships')" class="no-padding cell-max-height">
            <ng-container *ngIf="occupation.parcel.title">
              <cell-ownership
                [count]="occupation.parcel.title.countOwnerships"
                [ownerships]="occupation.parcel.title.ownerships"
                [tooltipData]="{ filter: {sortOrder: {sortBy: 'subjectName'}, filters: { titleId: occupation.parcel.title.id, validity: 'valid' }}, attributes: {subject: {load: false}}}"
                [tooltipType]="'ownership-list'"
              ></cell-ownership>
            </ng-container>
          </td>
          <td *ngIf="!hideColumn('zabcis')" class="number" [ngClass]="{ cancelled: !!occupation.endDate }">{{ occupation.zabcis }}</td>
          <td *ngIf="!hideColumn('zabst')"class="number" [ngClass]="{ cancelled: !!occupation.endDate }">{{ occupation.zabst }}</td>
          <td *ngIf="!hideColumn('nabyvatel')">{{ occupation.nabyvatel }}</td>
          <td *ngIf="!hideColumn('investor')">{{ occupation.investor }}</td>
          <td *ngIf="!hideColumn('parcel')" class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="occupation.parcel"
              [occupation]="occupation"
              updateOccupationParcelPath=".updateOccupationParcel"
              [tooltipData]="{ parcel: occupation.parcel.endDate ? null : occupation.parcel, withoutTitle: true }"
              [tooltipType]="'parcel'"
            ></cell-parcel>
          </td>
          <td *ngIf="!hideColumn('cases')" class="no-padding cell-max-height">
            <cell-cases
              [casesCount]="occupation.countCases"
              [tooltipData]="{ filter: { filters: {occupationId: occupation.id, cancelStatus: 'notCancelled' }}}"
              [tooltipType]="'cases'"
            ></cell-cases>
          </td>
          <td *ngIf="!hideColumn('constructionObjects')" class="no-padding">
            <cell-construction-objects
              [constructionObjects]="occupation.constructionObjects"
              [tooltipType]="'construction-objects'"
              [tooltipData]="{ constructionObjects: occupation.constructionObjects }"
            ></cell-construction-objects>
          </td>
          <td *ngFor="let occupationType of occupationTypesPermanent" class="number">
            <span *ngIf="occupation.zabtyp === occupationType.number">{{ occupation.zabvym }}</span>
          </td>
          <td *ngFor="let occupationType of occupationTypesTemporary" class="number">
            <span *ngIf="occupationType.category === 'rental_length'">{{ occupation.rentalLength }}</span>
            <span *ngIf="occupationType.category !== 'rental_length' && occupation.zabtyp === occupationType.number">{{ occupation.zabvym }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
      Nenalezena žádná data
    </div>
    <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
    <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
  </div>
  <help [helpId]="helpIds.PANEL_OCCUPATIONS" class="cm-help-page"></help>
</div>
