import { Injectable } from '@angular/core';

import { SimpleCaseStatusConfigModel } from '@app/ps/models/simple-case-status-config.model';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { FormCaseSendAdministratorComponent } from '@app/ps/cases/components/form-case-send-administrator/form-case-send-administrator.component';
import { FormCaseSignAdministratorComponent } from '@app/ps/cases/components/form-case-sign-administrator/form-case-sign-administrator.component';
import { FormCaseSendInvestorComponent } from '@app/ps/cases/components/form-case-send-investor/form-case-send-investor.component';
import { FormCaseSignInvestorComponent } from '@app/ps/cases/components/form-case-sign-investor/form-case-sign-investor.component';
import { FormCaseSendOwnerComponent } from '@app/ps/cases/components/form-case-send-owner/form-case-send-owner.component';
import { FormCaseUndeliveredOwnerComponent } from '@app/ps/cases/components/form-case-undelivered-owner/form-case-undelivered-owner.component';
import { FormCaseReceiveOwnerComponent } from '@app/ps/cases/components/form-case-receive-owner/form-case-receive-owner.component';
import { FormCaseDeclineOwnerComponent } from '@app/ps/cases/components/form-case-decline-owner/form-case-decline-owner.component';
import { FormCaseSignOwnerComponent } from '@app/ps/cases/components/form-case-sign-owner/form-case-sign-owner.component';
import { FormCaseEntryProposalComponent } from '@app/ps/cases/components/form-case-entry-proposal/form-case-entry-proposal.component';
import { FormCaseEntryDeclinedComponent } from '@app/ps/cases/components/form-case-entry-declined/form-case-entry-declined.component';
import { FormCaseEntryStoppedComponent } from '@app/ps/cases/components/form-case-entry-stopped/form-case-entry-stopped.component';
import { FormCaseHandOverInvestorComponent } from '@app/ps/cases/components/form-case-hand-over-investor/form-case-hand-over-investor.component';
import { FormCasePaymentInstructionComponent } from '@app/ps/cases/components/form-case-payment-instruction/form-case-payment-instruction.component';
import { FormCasePaymentOrderComponent } from '@app/ps/cases/components/form-case-payment-order/form-case-payment-order.component';
import { FormCaseSignedSendOwnersComponent } from '@app/ps/cases/components/form-case-signed-send-owners/form-case-signed-send-owners.component';
import { AuthService } from '@app/common/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CaseService {

  private simpleCaseStatusConfig: SimpleCaseStatusConfigModel[];

  constructor(
    datePipe: DatePipe,
    private subjectNamePipe: SubjectNamePipe,
    private authService: AuthService,
  ) {
    this.getRecipients = this.getRecipients.bind(this);
    this.hasEqualAddress = this.hasEqualAddress.bind(this);
    this.isFieldEqual = this.isFieldEqual.bind(this);
    this.easementPriceByShare = this.easementPriceByShare.bind(this);
    this.showEasementDistributionSystemParts = this.showEasementDistributionSystemParts.bind(this);

    this.simpleCaseStatusConfig = [
      {
        id: 'expropriationEnforceable',
        name: 'ExpropriationEnforceable',
        saveEndpoint: 'expropriation-enforceable',
        formFields: [
          {
            field: 'expropriationEnforceableDate',
            name: 'Datum nabytí právní moci',
            type: 'date',
            required: true,
          }
        ]
      }, {
        id: 'expropriationAppeal',
        name: 'ExpropriationAppeal',
        saveEndpoint: 'expropriation-appeal',
        formFields: [
          {
            field: 'expropriationAppealDate',
            name: 'Datum odvolání',
            type: 'date',
            required: true,
          }, {
            field: 'expropriationAppealReason',
            name: 'Důvod odvolání',
            type: 'string',
            required: true,
          }, {
            field: 'expropriationAppealSide',
            name: 'Strana podávající odvolání',
            type: 'switch',
            options: [{ id: 'I', value: 'Investor' }, { id: 'O', value: 'Vlastník' }],
            required: true,
          }
        ]
      }, {
        id: 'expropriationHearingRepetition',
        name: 'ExpropriationHearingRepetition',
        saveEndpoint: 'expropriation-hearing-repetition',
        formFields: [
          {
            field: 'expropriationHearingRepetitionCalledDate',
            name: 'Datum doručení rozhodnutí o svolání opakovaného ústního jednání',
            type: 'date',
            required: true,
            default: 'currentDate',
          }, {
            field: 'expropriationHearingRepetitionReason',
            name: 'Důvod opakování',
            type: 'string',
            required: true,
          }, {
            field: 'expropriationHearingRepetitionDate',
            name: 'Datum konání opakovaného ústního jednání',
            type: 'date',
            required: true,
            allowFutureDate: true
          }
        ]
      }, {
        id: 'expropriationProceedingInteruptionExtended',
        name: 'ExpropriationProceedingInteruptionExtended',
        saveEndpoint: 'expropriation-proceeding-interuption-extended',
        formFields: [
          {
            field: 'expropriationProceedingInteruptionExtendedDate',
            name: 'Datum prodloužení',
            type: 'date',
            required: true,
            default: 'currentDate',
          }, {
            field: 'expropriationProceedingInteruptionExtendedReason',
            name: 'Důvod',
            type: 'string',
            required: true,
          }, {
            field: 'expropriationProceedingInteruptionExtendedDeadlineDate',
            name: 'Lhůta',
            type: 'date',
            required: true,
            allowFutureDate: true
          }
        ]
      }, {
        id: 'expropriationProceedingInterupted',
        name: 'ExpropriationProceedingInterupted',
        saveEndpoint: 'expropriation-proceeding-interupted',
        formFields: [
          {
            field: 'expropriationProceedingInteruptionDate',
            name: 'Datum přerušení',
            type: 'date',
            required: true,
            default: 'currentDate',
          }, {
            field: 'expropriationProceedingInteruptionReason',
            name: 'Důvod',
            type: 'string',
            required: true,
          }, {
            field: 'expropriationProceedingInteruptionDeadlineDate',
            name: 'Lhůta',
            type: 'date',
            required: true,
            allowFutureDate: true
          }
        ]
      }, {
        id: 'expropriationAssessmentInterupted',
          name: 'ExpropriationAssessmentInterupted',
          saveEndpoint: 'expropriation-assessment-interupted',
          formFields: [
          {
            field: 'expropriationAssessmentInteruptionDate',
            name: 'Datum přerušení',
            type: 'date',
            required: true,
            default: 'currentDate',
          }, {
            field: 'expropriationAssessmentInteruptionReason',
            name: 'Důvod',
            type: 'string',
            required: true,
          }, {
            field: 'expropriationAssessmentInteruptionDeadlineDate',
            name: 'Lhůta',
            type: 'date',
            required: true,
            allowFutureDate: true
          }
        ]
      }, {
        id: 'expropriationStop',
        name: 'ExpropriationStopped',
        saveEndpoint: 'expropriation-stopped',
        formFields: [
          {
            field: 'expropriationStoppedDate',
            name: 'Datum zastavení řízení',
            type: 'date',
            required: true,
            default: 'currentDate',
          }, {
            field: 'expropriationStoppedBySide',
            name: 'Kdo řízení zastavil',
            type: 'string',
            required: true,
          }, {
            field: 'expropriationStoppedReason',
            name: 'Důvod zastavení řízení',
            type: 'string',
            required: true,
          }
        ]
      }, {
        id: 'expropriationCalledHearing',
        name: 'ExpropriationCalledHearing',
        saveEndpoint: 'expropriation-called-hearing',
        formFields: [
          {
            field: 'expropriationCalledHearingCalledDate',
            name: 'Datum doručení rozhodnutí o svolání ústního jednání',
            type: 'date',
            required: true,
          }, {
            field: 'expropriationCalledHearingDate',
            name: 'Datum konání ústního jednání',
            type: 'date',
            required: true,
            allowFutureDate: true
          }
        ]
      }, {
        id: 'expropriationProceedingStarted',
        name: 'ExpropriationProceedingStarted',
        saveEndpoint: 'expropriation-proceeding-started',
        formFields: [
          {
            field: 'expropriationProceedingStartedDate',
            name: 'Datum zahájení řízení',
            type: 'date',
            required: true,
          }
        ]
      }, {
        id: 'handOverInvestor',
        name: 'HandedOverInvestor',
        saveEndpoint: 'hand-over-investor',
        formFields: [
          {
            field: 'handedOverInvestorDate',
            name: 'Datum předání',
            type: 'date',
            default: 'currentDate',
            required: true,
          }
        ]
      }, {
        id: 'paymentInstruction',
        name: 'PaymentInstruction',
        saveEndpoint: 'payment-instruction',
        formFields: [
          {
            field: 'paymentInstructionDate',
            name: 'Datum',
            type: 'date',
            default: 'currentDate',
            required: true,
          }
        ]
      }, {
        id: 'paymentOrder',
        name: 'PaymentOrder',
        saveEndpoint: 'payment-order',
        formFields: [
          {
            field: 'paymentOrderDate',
            name: 'Datum',
            type: 'date',
            required: true,
          }
        ]
      }, {
        id: 'sendAdministrator',
        name: 'SentAdministrator',
        saveEndpoint: 'send-administrator',
        formFields: [
          {
            field: 'sentAdministratorDate',
            name: 'Datum odeslání',
            type: 'date',
            required: true,
          }
        ]
      }, {
        id: 'sendInvestor',
        name: 'SentInvestor',
        saveEndpoint: 'send-investor',
        formFields: [
          {
            field: 'sentInvestorDate',
            name: 'Datum odeslání',
            type: 'date',
            default: 'currentDate',
            required: true,
          }
        ]
      }, {
        id: 'sentAllOwners',
        name: 'SentAllOwners',
        saveEndpoint: 'sent-all-owners',
        formFields: [
          {
            field: 'sentAllOwnersDate',
            name: 'Datum odeslání',
            type: 'date',
            default: 'currentDate',
            required: true,
          }
        ]
      }, {
        id: 'expropriationProposal',
        name: 'ExpropriationProposal',
        saveEndpoint: 'expropriation-proposal',
        formFields: [
          {
            field: 'expropriationProposalDate',
            name: 'Datum žádosti',
            type: 'date',
            default: 'currentDate',
            required: true,
          }
        ]
      }, {
        id: 'signAdministrator',
        name: 'SignedAdministrator',
        saveEndpoint: 'sign-administrator',
        formFields: [
          {
            field: 'signedAdministratorDate',
            name: 'Datum podpisu',
            type: 'date',
            required: true,
          }
        ]
      }, {
        id: 'signedSentOwners',
        name: 'SignedSentOwners',
        saveEndpoint: 'signed-sent-owners',
        formFields: [
          {
            field: 'signedSentOwnersDate',
            name: 'Datum',
            type: 'date',
            required: true,
          }
        ]
      }, {
        id: 'callBeforeExpropriation',
        name: 'CallBeforeExpropriation',
        saveEndpoint: 'call-before-expropriation',
        recipient: true,
        statusDateFieldName: 'callBeforeExpropriationDate',
        formFields: [
          {
            field: 'callBeforeExpropriationDate',
            name: 'Datum odeslání výzvy před vyvlastněním',
            type: 'date',
            required: true,
          }
        ]
      }, {
        id: 'callBeforeExpropriationReceived',
        name: 'CallBeforeExpropriationReceived',
        saveEndpoint: 'call-before-expropriation-received',
        recipient: true,
        statusDateFieldName: 'callBeforeExpropriationReceivedDate',
        formFields: [
          {
            field: 'callBeforeExpropriationReceivedDate',
            name: 'Datum doručení výzvy před vyvlastněním',
            type: 'date',
            required: true,
            onChange: (data) => {
              const date = new Date(data.callBeforeExpropriationReceivedDate);
              const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + data.obligation.callBeforeExpropriationReceivedPeriod);
              data.callBeforeExpropriationReceivedDeadlineDate = datePipe.transform(newDate, 'yyyy-MM-dd');
            },
          },
          {
           field: 'callBeforeExpropriationReceivedDeadlineDate',
           name: 'Datum lhůty výzvy k vyvlastnění',
           type: 'date',
           required: false,
           allowFutureDate: true,
          }
        ]
      }, {
        id: 'expropriationCaseInProgress',
        name: 'ExpropriationCaseInProgress',
        saveEndpoint: 'expropriation-case-in-progress',
        recipient: true,
        statusDateFieldName: 'expropriationCaseInProgressDate',
        formFields: [
          {
            field: 'expropriationCaseInProgressDate',
            name: 'Datum zahájení vyvlastňovacího řízení',
            type: 'date',
            required: true,
          }
        ]
      }, {
        id: 'dunningLetter',
        name: 'DunningLetter',
        saveEndpoint: 'dunning-letter',
        recipient: true,
        statusDateFieldName: 'dunningLetterDate',
        formFields: [
          {
            field: 'dunningLetterDate',
            name: 'Datum odeslání urgence',
            type: 'date',
            required: true,
            onChange: (data) => {
              const date = new Date(data.dunningLetterDate);
              const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + data.obligation.dunningLetterPeriod);
              data.dunningLetterDeadlineDate = datePipe.transform(newDate, 'yyyy-MM-dd');
            },
          },
          {
            field: 'dunningLetterDeadlineDate',
            name: 'Datum lhůty urgence',
            type: 'date',
            required: false,
            allowFutureDate: true,
          }
        ]
      }, {
        id: 'buildingAuthorityApprovalRequest',
        name: 'BuildingAuthorityApprovalRequest',
        saveEndpoint: 'building-authority-approval-request',
        statusDateFieldName: 'buildingAuthorityApprovalRequestDate',
        formFields: [
          {
            field: 'buildingAuthorityApprovalRequestDate',
            name: 'Datum odeslání žádosti na stavební úřad',
            type: 'date',
            required: true,
            default: 'currentDate',
          },
        ]
      },{
        id: 'buildingAuthorityApproved',
        name: 'BuildingAuthorityApproved',
        saveEndpoint: 'building-authority-approved',
        statusDateFieldName: 'buildingAuthorityApprovedDate',
        formFields: [
          {
            field: 'buildingAuthorityApprovedDate',
            name: 'Datum souhlasu stavebního úřadu',
            type: 'date',
            required: true,
          },
        ]
      }, {
        id: 'buildingAuthorityRejected',
        name: 'BuildingAuthorityRejected',
        saveEndpoint: 'building-authority-rejected',
        statusDateFieldName: 'buildingAuthorityRejectedDate',
        hasNote: true,
        formFields: [
          {
            field: 'buildingAuthorityRejectedDate',
            name: 'Datum nesouhlasu stavebního úřadu',
            type: 'date',
            required: true,
          },
        ]
      }, {
        id: 'approvedOwner',
        name: 'ApprovedOwner',
        recipient: true,
        saveEndpoint: 'approved-owner',
        statusDateFieldName: 'approvedOwnerDate',
        formFields: [
          {
            field: 'approvedOwnerDate',
            name: 'Datum souhlasu vlastníka',
            type: 'date',
            required: true,
          },
        ]
      }, {
        id: 'disapprovedOwner',
        name: 'DisapprovedOwner',
        recipient: true,
        saveEndpoint: 'disapproved-owner',
        statusDateFieldName: 'disapprovedOwnerDate',
        hasNote: true,
        formFields: [
          {
            field: 'disapprovedOwnerDate',
            name: 'Datum nesouhlasu vlastníka',
            type: 'date',
            required: true,
          },
        ]
      }, {
        id: 'exchangeRequest',
        name: 'ExchangeRequest',
        recipient: true,
        saveEndpoint: 'exchange-request',
        statusDateFieldName: 'exchangeRequestDate',
        hasNote: true,
        formFields: [
          {
            field: 'exchangeRequestDate',
            name: 'Datum požadavku na směnu vlastníka',
            type: 'date',
            required: true,
          },
        ]
      }, {
        id: 'otherSolutionRequest',
        name: 'OtherSolutionRequest',
        recipient: true,
        saveEndpoint: 'other-solution-request',
        statusDateFieldName: 'otherSolutionRequestDate',
        hasNote: true,
        formFields: [
          {
            field: 'otherSolutionRequestDate',
            name: 'Datum požadavku vlastníka na jiné řešení',
            type: 'date',
            required: true,
          },
        ]
      }, {
        id: 'withoutResolution',
        name: 'WithoutResolution',
        recipient: true,
        saveEndpoint: 'without-resolution',
        statusDateFieldName: 'withoutResolutionDate',
        hasNote: true,
        formFields: [
          {
            field: 'withoutResolutionDate',
            name: 'Datum označení případu bez výsledku',
            type: 'date',
            required: true,
          },
        ]
      }, {
        id: 'meetingRequest',
        name: 'MeetingRequest',
        recipient: true,
        recipientFilter: (value) => {
          return !value.signedDate && !value.acceptedGeometricalPlanPossibleDate && (value.sentDate || value.personalVisitDate || value.receivedDate);
        },
        saveEndpoint: 'meeting-request',
        statusDateFieldName: 'meetingRequestDate',
        formFields: [
          {
            field: 'meetingRequestDate',
            name: 'Datum požadavku jednání',
            type: 'date',
            required: true,
          },
        ]
      }, {
        id: 'undeliveredOwner',
        name: 'UndeliveredOwner',
        hasNoteType: true,
        noteTypeFilter: { filters: { keys: ['ReasonNotSigned-noResponse', 'ReasonNotSigned-notDelivered', 'ReasonNotSigned-died', 'ReasonNotSigned-probateProceedings'], dialogTypes:['BusinessCase'] }},
        hasNote: true,
        recipient: true,
        recipientFilter: (value) => {
          return !value.receivedDate && (value.sentDate || value.personalVisitDate);
        },
        saveEndpoint: 'undelivered-owner',
        statusDateFieldName: 'undeliveredDate',
        formFields: [
          {
            field: 'undeliveredOwnerDate',
            name: 'Datum vrácení',
            type: 'date',
            required: true,
          },
        ]
      }
    ];
  }

  getRecipients(caseOwnerships, allowDeliveryAddress?) {
    allowDeliveryAddress = allowDeliveryAddress && true;
    const recipients = [];
    caseOwnerships.forEach((item) => {
      // remove parents and avoid duplicates
      if (!item.isParent && !_.some(recipients, (recipient) => _.some(item.caseSubjects, (caseSubject) => caseSubject.subject.opsubId === recipient.id || (recipient.opsubIds && recipient.opsubIds.includes(caseSubject.subject.opsubId))))) {
        // fill recipients array, exactly the same algorithm as in backend in covering letter generating
        if (item.ownership.isBsm && item.caseSubjects && item.caseSubjects.length === 2) {
          if (this.hasEqualAddress(item.caseSubjects[0], item.caseSubjects[1], false) &&
            (!allowDeliveryAddress || this.hasEqualAddress(item.caseSubjects[0], item.caseSubjects[1], allowDeliveryAddress)) &&
            item.caseSubjects[0].personalVisitDate === item.caseSubjects[1].personalVisitDate &&
            item.caseSubjects[0].sentDate === item.caseSubjects[1].sentDate &&
            item.caseSubjects[0].undeliveredDate === item.caseSubjects[1].undeliveredDate &&
            item.caseSubjects[0].receivedDate === item.caseSubjects[1].receivedDate &&
            item.caseSubjects[0].signedDate === item.caseSubjects[1].signedDate &&
            item.caseSubjects[0].responseReceivedDate === item.caseSubjects[1].responseReceivedDate &&
            item.caseSubjects[0].meetingRequestDate === item.caseSubjects[1].meetingRequestDate &&
            item.caseSubjects[0].acceptedGeometricalPlanPossibleDate === item.caseSubjects[1].acceptedGeometricalPlanPossibleDate &&
            item.caseSubjects[0].dunningLetterDate === item.caseSubjects[1].dunningLetterDate &&
            item.caseSubjects[0].callBeforeExpropriationDate === item.caseSubjects[1].callBeforeExpropriationDate &&
            item.caseSubjects[0].callBeforeExpropriationReceivedDate === item.caseSubjects[1].callBeforeExpropriationReceivedDate &&
            item.caseSubjects[0].expropriationCaseInProgressDate === item.caseSubjects[1].expropriationCaseInProgressDate &&
            item.caseSubjects[0].expropriationCaseStoppedDate === item.caseSubjects[1].expropriationCaseStoppedDate &&
            item.caseSubjects[0].expropriationCaseFinishedDate === item.caseSubjects[1].expropriationCaseFinishedDate &&
            item.caseSubjects[0].disapprovedOwnerDate === item.caseSubjects[1].disapprovedOwnerDate &&
            item.caseSubjects[0].approvedOwnerDate === item.caseSubjects[1].approvedOwnerDate &&
            item.caseSubjects[0].exchangeRequestDate === item.caseSubjects[1].exchangeRequestDate &&
            item.caseSubjects[0].otherSolutionRequestDate === item.caseSubjects[1].otherSolutionRequestDate &&
            item.caseSubjects[0].withoutResolutionDate === item.caseSubjects[1].withoutResolutionDate) {
            recipients.push({
              name: this.subjectNamePipe.transform(item.caseSubjects[0].subject) + ', ' + this.subjectNamePipe.transform(item.caseSubjects[1].subject),
              id: item.ownership.opsubId,
              opsubIds: [item.caseSubjects[0].subject.opsubId, item.caseSubjects[1].subject.opsubId],
              personalVisitDate: item.caseSubjects[0].personalVisitDate,
              sentDate: item.caseSubjects[0].sentDate,
              undeliveredDate: item.caseSubjects[0].undeliveredDate,
              receivedDate: item.caseSubjects[0].receivedDate,
              declinedDate: item.caseSubjects[0].declinedDate,
              signedDate: item.caseSubjects[0].signedDate,
              responseReceivedDate: item.caseSubjects[0].responseReceivedDate,
              meetingRequestDate: item.caseSubjects[0].meetingRequestDate,
              acceptedGeometricalPlanPossibleDate: item.caseSubjects[0].acceptedGeometricalPlanPossibleDate,
              dunningLetterDate: item.caseSubjects[0].dunningLetterDate,
              callBeforeExpropriationDate: item.caseSubjects[0].callBeforeExpropriationDate,
              callBeforeExpropriationReceivedDate: item.caseSubjects[0].callBeforeExpropriationReceivedDate,
              expropriationCaseInProgressDate: item.caseSubjects[0].expropriationCaseInProgressDate,
              expropriationCaseStoppedDate: item.caseSubjects[0].expropriationCaseStoppedDate,
              expropriationCaseFinishedDate: item.caseSubjects[0].expropriationCaseFinishedDate,
              disapprovedOwnerDate: item.caseSubjects[0].disapprovedOwnerDate,
              approvedOwnerDate: item.caseSubjects[0].approvedOwnerDate,
              exchangeRequestDate: item.caseSubjects[0].exchangeRequestDate,
              otherSolutionRequestDate: item.caseSubjects[0].otherSolutionRequestDate,
              withoutResolutionDate: item.caseSubjects[0].withoutResolutionDate,
            });
          } else {
            item.caseSubjects.forEach((caseSubject) => {
              recipients.push({
                name: this.subjectNamePipe.transform(caseSubject.subject),
                id: caseSubject.subject.opsubId,
                personalVisitDate: caseSubject.personalVisitDate,
                sentDate: caseSubject.sentDate,
                undeliveredDate: caseSubject.undeliveredDate,
                receivedDate: caseSubject.receivedDate,
                declinedDate: caseSubject.declinedDate,
                signedDate: caseSubject.signedDate,
                responseReceivedDate: caseSubject.responseReceivedDate,
                meetingRequestDate: caseSubject.meetingRequestDate,
                acceptedGeometricalPlanPossibleDate: caseSubject.acceptedGeometricalPlanPossibleDate,
                dunningLetterDate: caseSubject.dunningLetterDate,
                callBeforeExpropriationDate: caseSubject.callBeforeExpropriationDate,
                callBeforeExpropriationReceivedDate: caseSubject.callBeforeExpropriationReceivedDate,
                expropriationCaseInProgressDate: caseSubject.expropriationCaseInProgressDate,
                expropriationCaseStoppedDate: caseSubject.expropriationCaseStoppedDate,
                expropriationCaseFinishedDate: caseSubject.expropriationCaseFinishedDate,
                disapprovedOwnerDate: caseSubject.disapprovedOwnerDate,
                approvedOwnerDate: caseSubject.approvedOwnerDate,
                exchangeRequestDate: caseSubject.exchangeRequestDate,
                otherSolutionRequestDate: caseSubject.otherSolutionRequestDate,
                withoutResolutionDate: caseSubject.withoutResolutionDate,
              });
            });
          }
        } else if (item.caseSubjects && item.caseSubjects.length === 1) {
          if (item.caseSubjects[0].subject.opsubType !== 'OFO' ||
            !_.some(caseOwnerships,
              (caseOwnership) => caseOwnership.ownership.isBsm &&
                _.some(caseOwnership.caseSubjects, (caseSubject) => caseSubject.subject.opsubId === item.caseSubjects[0].subject.opsubId)
            )
          ) {
            recipients.push({
              name: this.subjectNamePipe.transform(item.caseSubjects[0].subject),
              id: item.caseSubjects[0].subject.opsubId,
              personalVisitDate: item.caseSubjects[0].personalVisitDate,
              sentDate: item.caseSubjects[0].sentDate,
              undeliveredDate: item.caseSubjects[0].undeliveredDate,
              receivedDate: item.caseSubjects[0].receivedDate,
              declinedDate: item.caseSubjects[0].declinedDate,
              signedDate: item.caseSubjects[0].signedDate,
              responseReceivedDate: item.caseSubjects[0].responseReceivedDate,
              meetingRequestDate: item.caseSubjects[0].meetingRequestDate,
              acceptedGeometricalPlanPossibleDate: item.caseSubjects[0].acceptedGeometricalPlanPossibleDate,
              dunningLetterDate: item.caseSubjects[0].dunningLetterDate,
              callBeforeExpropriationDate: item.caseSubjects[0].callBeforeExpropriationDate,
              callBeforeExpropriationReceivedDate: item.caseSubjects[0].callBeforeExpropriationReceivedDate,
              expropriationCaseInProgressDate: item.caseSubjects[0].expropriationCaseInProgressDate,
              expropriationCaseStoppedDate: item.caseSubjects[0].expropriationCaseStoppedDate,
              expropriationCaseFinishedDate: item.caseSubjects[0].expropriationCaseFinishedDate,
              disapprovedOwnerDate: item.caseSubjects[0].disapprovedOwnerDate,
              approvedOwnerDate: item.caseSubjects[0].approvedOwnerDate,
              exchangeRequestDate: item.caseSubjects[0].exchangeRequestDate,
              otherSolutionRequestDate: item.caseSubjects[0].otherSolutionRequestDate,
              withoutResolutionDate: item.caseSubjects[0].withoutResolutionDate,
            });
          }
        }
      }
    });
    return recipients;
  }

  hasEqualAddress(caseSubject1, caseSubject2, allowDeliveryAddress) {
    return this.isFieldEqual(caseSubject1, caseSubject2, 'obec', 'dorucovaciObec', allowDeliveryAddress) &&
      this.isFieldEqual(caseSubject1, caseSubject2, 'cisloDomovni', 'dorucovaciCisloDomovni', allowDeliveryAddress) &&
      this.isFieldEqual(caseSubject1, caseSubject2, 'cisloOrientacni', 'dorucovaciCisloOrientacni', allowDeliveryAddress) &&
      this.isFieldEqual(caseSubject1, caseSubject2, 'psc', 'dorucovaciPsc', allowDeliveryAddress) &&
      this.isFieldEqual(caseSubject1, caseSubject2, 'ulice', 'dorucovaciUlice', allowDeliveryAddress);
  }

  getField(caseSubject, fieldName, deliveryFieldName, allowDeliveryAddress) {
    return caseSubject.dorucovaciUse && allowDeliveryAddress ? caseSubject[deliveryFieldName] : caseSubject[fieldName];
  }

  isFieldEqual(caseSubject1, caseSubject2, fieldName, deliveryFieldName, allowDeliveryAddress, mapper = (value) => value) {
    return mapper(this.getField(caseSubject1, fieldName, deliveryFieldName, allowDeliveryAddress)) === mapper(this.getField(caseSubject2, fieldName, deliveryFieldName, allowDeliveryAddress));
  }

  getDataCaseOwnerships(caseOwnerships, caseSubjects) {
    const dataCaseOwnerships = [..._.filter(caseOwnerships, (item) => {
      return !item.isParent;
    })];
    dataCaseOwnerships.forEach((item) => {
      item.podil = item.ownership.podilCitatel + '/' + item.ownership.podilJmenovatel;
      if (item.ownership.isBsm) {
        const bsmSubject = caseSubjects.find((value) => value.subject.opsubId === item.ownership.opsubId);
        if (bsmSubject) {
          item.caseSubjects = [];
          item.caseSubjects.push(caseSubjects.find((value) => value.subject.opsubId === bsmSubject.subject.partner1OpsubId));
          item.caseSubjects.push(caseSubjects.find((value) => value.subject.opsubId === bsmSubject.subject.partner2OpsubId));
        }
      } else {
        item.caseSubjects = caseSubjects.filter((value) => value.subject.opsubId === item.ownership.opsubId);
      }
    });
    return dataCaseOwnerships;
  }

  getDataCaseOwnershipsUnique(caseOwnerships, caseSubjects) {
    const dataCaseOwnerships = [..._.filter(caseOwnerships, (item) => {
      return !item.isParent;
    })];

    const uniqueCaseOwnerships = {};

    dataCaseOwnerships.forEach((item) => {
      item.podil = item.ownership.podilCitatel + '/' + item.ownership.podilJmenovatel;
      if (item.ownership.isBsm) {
        const bsmSubject = caseSubjects.find((value) => value.subject.opsubId === item.ownership.opsubId);
        if (bsmSubject) {
          item.caseSubjects = [];
          item.caseSubjects.push(caseSubjects.find((value) => value.subject.opsubId === bsmSubject.subject.partner1OpsubId));
          item.caseSubjects.push(caseSubjects.find((value) => value.subject.opsubId === bsmSubject.subject.partner2OpsubId));

        }
      } else {
        item.caseSubjects = caseSubjects.filter((value) => value.subject.opsubId === item.ownership.opsubId);
      }
      const identifier = (item.ownership.isBsm ? 'sjm' : '') + '_' + item.caseSubjects.map(cs => cs.subject.opsubId).join('_');
      const sumPrice = (item.price || 0) + (item.easementPrice || 0);

      if (uniqueCaseOwnerships[identifier]) {
        uniqueCaseOwnerships[identifier].sumPrice += sumPrice;
        uniqueCaseOwnerships[identifier].ownershipIds.push(item.id);
      } else {
        uniqueCaseOwnerships[identifier] = item;
        uniqueCaseOwnerships[identifier].sumPrice = sumPrice;
        uniqueCaseOwnerships[identifier].ownershipIds = [];
      }
    });
    return Object.values(uniqueCaseOwnerships);
  }

  fillCaseOwnershipsByMain(mainCaseOwnerships, caseOwnerships) {
    for (const mainCaseOwnership of mainCaseOwnerships) {
      caseOwnerships.filter(co => mainCaseOwnership.ownershipIds.includes(co.id)).forEach(co => {
        co.singlePayment = mainCaseOwnership.singlePayment;
        co.paymentType = mainCaseOwnership.paymentType;
        co.accountNumber = mainCaseOwnership.accountNumber;
        co.bankCode = mainCaseOwnership.bankCode;
      });
    }
  }

  checkParentCaseOwnershipCycle(newValue, ownershipsWithParent?) {
    let cycleValue = newValue;
    do {
      if (ownershipsWithParent) {
        const ownershipWithParent = _.find(ownershipsWithParent, {caseOwnership: {ownership: {id: cycleValue.ownership.id}}});
        if (ownershipWithParent) {
          cycleValue = ownershipWithParent.caseOwnership.parentCaseOwnership;
        } else {
          cycleValue = null;
        }
      } else {
        cycleValue = cycleValue.parentCaseOwnership;
      }
      if (cycleValue && cycleValue.ownership.id === newValue.ownership.id) {
        return true;
      }
    } while (cycleValue);

    return false;
  }

  isOwnerPresent(caseOwnership) {
    let cycleValue = caseOwnership;
    while (cycleValue.parentCaseOwnership) {
      cycleValue = cycleValue.parentCaseOwnership;
      if (cycleValue === caseOwnership) {
        return true;
      }
    }

    /*
     * "30";"Vlastnické právo"
     */
    return cycleValue.ownership.ownerType;
  }

  easementPriceByShare(obligation) {
    return this.authService.getActualProject().easementPriceByShare &&
      obligation.easementPriceByShare;
  }

  getActionButtons() {
    return [
      {key: 'sendAdministrator', caseStatus: 'SentAdministrator'},
      {key: 'signAdministrator', caseStatus: 'SignedAdministrator'},

      {key: 'sendInvestor', caseStatus: 'SentInvestor'},
      {key: 'confirm', caseStatus: 'ApprovedInvestor'},
      {key: 'signInvestor', caseStatus: 'SignedInvestor'},

      {key: 'auctionResult', caseStatus: 'AuctionWin'},
      {key: 'sendOwner', caseStatus: 'SentOwner'},
      {key: 'undeliveredOwner', caseStatus: 'UndeliveredOwner'},
      {key: 'receiveOwner', caseStatus: 'ReceivedOwner'},
      {key: 'declinedOwner', caseStatus: 'DeclinedOwner'},
      {caseStatus: 'Created', name: 'Obnovit případ do stavu Založeno', click: 'renew'},
      {key: 'signOwner', caseStatus: 'SignedOwner'},
      {key: 'personalVisit', caseStatus: 'PersonalVisit'},
      {key: 'meetingRequest', caseStatus: 'MeetingRequest'},
      {key: 'acceptedGeometricalPlanPossible', caseStatus: 'AcceptedGeometricalPlanPossible'},

      {key: 'expropriationProposal', caseStatus: 'ExpropriationProposal'},
      {key: 'expropriationAssessmentInterupted', caseStatus: 'ExpropriationAssessmentInterupted'},
      {key: 'expropriationProceedingStarted', caseStatus: 'ExpropriationProceedingStarted'},
      {key: 'expropriationProceedingInterupted', caseStatus: 'ExpropriationProceedingInterupted'},
      {key: 'expropriationProceedingInteruptionExtended', caseStatus: 'ExpropriationProceedingInteruptionExtended'},
      {key: 'expropriationStop', caseStatus: 'ExpropriationStopped'},
      {key: 'expropriationCalledHearing', caseStatus: 'ExpropriationCalledHearing'},
      {key: 'expropriationHearingRepetition', caseStatus: 'ExpropriationHearingRepetition'},
      {key: 'expropriationResult', caseStatus: 'ExpropriationAccepted', name: 'Rozhodnutí vyvlastňovacího úřadu'},
      {key: 'expropriationAppeal', caseStatus: 'ExpropriationAppeal'},
      {key: 'expropriationAppealResult', caseStatus: 'ExpropriationAppealAccepted', name: 'Rozhodnutí o odvolání'},
      {key: 'expropriationEnforceable', caseStatus: 'ExpropriationEnforceable'},

      {key: 'expropriationInterimDecisionRequest', caseStatus: 'ExpropriationInterimDecisionRequest'},
      {key: 'expropriationInterimDecision', caseStatus: 'ExpropriationInterimDecision'},
      {key: 'expropriationAdministrativeAppeal', caseStatus: 'ExpropriationAdministrativeAppeal'},
      {key: 'expropriationAdministrativeAppealRejected', caseStatus: 'ExpropriationAdministrativeAppealRejected'},
      {key: 'expropriationAdministrativeAppealAccepted', caseStatus: 'ExpropriationAdministrativeAppealAccepted'},
      {key: 'expropriationCompensationPayment', caseStatus: 'ExpropriationCompensationPayment'},

      {key: 'entryProposal', caseStatus: 'EntryProposal'},
      {key: 'cadastreEntry', caseStatus: 'CadastreEntry'},

      {key: 'handOverInvestor', caseStatus: 'HandedOverInvestor'},

      {key: 'paymentInstruction', caseStatus: 'PaymentInstruction'},
      {key: 'paymentOrder', caseStatus: 'PaymentOrder'},
      {key: 'signedSentOwners', caseStatus: 'SignedSentOwners'},
      {key: 'contractsRegister', caseStatus: 'ContractRegisterPublication'},
      {key: 'callBeforeExpropriation', caseStatus: 'CallBeforeExpropriation'},
      {key: 'callBeforeExpropriationReceived', caseStatus: 'CallBeforeExpropriationReceived'},
      {key: 'expropriationCaseInProgress', caseStatus: 'ExpropriationCaseInProgress'},
      {key: 'dunningLetter', caseStatus: 'DunningLetter'},
      {key: 'buildingAuthorityApprovalRequest', caseStatus: 'BuildingAuthorityApprovalRequest'},
      {key: 'buildingAuthorityApproved', caseStatus: 'BuildingAuthorityApproved'},
      {key: 'buildingAuthorityRejected', caseStatus: 'BuildingAuthorityRejected'},

      {key: 'sentAllOwners', caseStatus: 'SentAllOwners'},
      {key: 'approvedOwner', caseStatus: 'ApprovedOwner'},
      {key: 'disapprovedOwner', caseStatus: 'DisapprovedOwner'},
      {key: 'otherSolutionRequest', caseStatus: 'OtherSolutionRequest'},
      {key: 'withoutResolution', caseStatus: 'WithoutResolution'},
      {key: 'exchangeRequest', caseStatus: 'ExchangeRequest'},

      {key: 'cancel', caseStatus: 'Cancelled', click: 'cancel', icon: 'fa-remove'}
    ];
  }

  getGenerateButtons(obligationType) {
    return [
      {key: 'generate', name: 'Generovat dokumenty', templates: [obligationType + 'Contract', obligationType + 'CoveringLetter', 'OtherDocument', 'InformationLetter']},
      {key: 'generateFinancialControl', name: 'Generovat dokument finanční kontroly', templates: ['FinancialControl']},
      {key: 'paymentCheckPreliminary', name: 'Generovat proformu žádosti o úhradu', templates: [obligationType + 'PaymentInstructionPreliminary']},
      {key: 'paymentCheck', name: 'Generovat žádost o úhradu', templates: [obligationType + 'PaymentInstruction']}
    ];
  }

  showEasementDistributionSystemParts(obligation) {
    return this.authService.getActualProject().easementDistributionSystemParts && obligation.easementDistributionSystemParts;
  }

  getSimpleCaseStatusConfig(id: string) {
    return this.simpleCaseStatusConfig.find(c => c.id === id);
  }
}
