import { Component, OnInit, Inject, Input, ComponentFactoryResolver, ViewChild, ChangeDetectorRef } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { BulkOperationStepHostDirective } from '../../directives/bulk-operation-step-host/bulk-operation-step-host.directive';
import { BulkOperationProcessDefinitionModel } from "../../models/bulk-operation-process-definition.model";
import { CallbackModel } from '@app/common/models/callback.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './bulk-operation.component.html',
  styleUrls: ['./bulk-operation.component.scss']
})
export class BulkOperationComponent implements OnInit {
  @Input()
  processDefinition: BulkOperationProcessDefinitionModel;

  @Input()
  callbacks: CallbackModel;

  @ViewChild(BulkOperationStepHostDirective) private stepHostDirective;

  steps: any[];
  activeStep: any;
  isValid: Function = () => false;
  finalStep: boolean;
  sent = false;
  data: any = {};
  componentRef: any;
  done = false;

  constructor(
    private stateService: StateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private restangular: Restangular,
    private cdr: ChangeDetectorRef,
  ) {
    this.nextStatus = this.nextStatus.bind(this);
    this.proceed = this.proceed.bind(this);
    this.goToList = this.goToList.bind(this);
    this.newOperation = this.newOperation.bind(this);
  }

  ngOnInit() {
    this.callbacks.add(Callbacks.DataValidityChanged, (event) => {
      this.isValid = event.isValid;
      this.data[this.activeStep.id] = event.data;
      this.cdr.detectChanges();
    });
    this.steps = this.processDefinition.getSteps();
    this.selectStep(this.steps[0]);
  }

  isNextEnabled(): boolean {
    return this.isValid() || this.finalStep;
  }

  async nextStatus() {
    let useNext = false;

    for (const step of this.steps) {
      if (useNext) {
        if (this.finalStep) {
          await this.proceed();
        }
        this.selectStep(step);
        this.isValid = () => false;
        useNext = false;
        break;
      }
      if (step === this.activeStep) {
        useNext = true;
      }
    }
  }

  async proceed() {
    this.sent = true;
    const result = await this.componentRef.instance.proceed();
    this.done = true;
    this.sent = false;
  }

  goToList() {
    this.stateService.go('^');
  }

  newOperation() {
    this.done = false;
    this.selectStep(this.steps.find(step => step.id === 'selectAction'));
  }

  selectStep(step = null) {
    this.activeStep = step;
    if (this.activeStep && this.activeStep.enabled) {
      this.isValid = () => true;
    }
    this.stepHostDirective.viewContainerRef.clear();
    if (this.activeStep && this.activeStep.component) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.activeStep.component);
      this.componentRef = this.stepHostDirective.viewContainerRef.createComponent(factory);
      this.componentRef.instance.data = this.data;
      this.componentRef.instance.callbacks = this.callbacks;
      this.componentRef.instance.newOperation = this.newOperation;
      this.componentRef.instance.goToList = this.goToList;
    }

    let enable = !!this.activeStep && this.activeStep !== this.steps[this.steps.length - 1];
    this.finalStep = this.activeStep === this.steps[this.steps.length - 2];

    this.steps.forEach((step) => {
      step.enabled = enable;
      if (!enable) {
        if (this.activeStep && !this.done) {
          delete this.data[step.id];
        }
      }
      if (step === this.activeStep) {
        enable = false;
      }
    });
  }

  clickStep(step) {
    if (!this.sent && step.enabled && step !== this.activeStep) {
      this.selectStep(step);
    }
  }
}
