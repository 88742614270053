import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'case-owner',
  templateUrl: './case-owner.component.html',
  styleUrls: ['./case-owner.component.scss']
})
export class CaseOwnerComponent implements OnInit {

  @Input() canEdit;
  @Input() caseSubject;
  @Input() caseOwnership;
  @Input() obligationType;
  @Input() obligationBonusPeriod;
  @Input() titles;
  @Input() index;
  @Input() editFunction: Function;
  @Input() manualRentPriceSet: boolean;

  constructor() { }

  ngOnInit() {
  }

}
