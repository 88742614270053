import { Component, OnInit } from '@angular/core';
import { PricingExpertOpinionStepComponent } from '@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component';
import { DecimalPipe } from '@app/common/pipes/decimal.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { OpinionService } from '@app/ps/services/opinion.service';
import { OpinionRequestService } from '@app/ps/services/opinion-request.service';
import { OpinionRequestStatusEnum } from '@app/ps/enums/opinion-request-status.enum';
import { Restangular } from 'ngx-restangular';
import { RoundingService } from '@app/ps/services/rounding.service';
import {ParcelPriceService} from "@app/ps/services/parcel-price.service";
import { Decimal } from 'decimal.js';

@Component({
  selector: 'pricing-expert-opinion-summary',
  templateUrl: './pricing-expert-opinion-summary.component.html',
})
export class PricingExpertOpinionSummaryComponent extends PricingExpertOpinionStepComponent implements OnInit {

  priceDefault = '0';
  priceCoefficient = '0';

  constructor(
    private restangular: Restangular,
    private opinionService: OpinionService,
    private opinionRequestService: OpinionRequestService,
    private decimalPipe: DecimalPipe,
    protected authService: AuthService,
    private parcelPriceService: ParcelPriceService,
    private roundingService: RoundingService,
  ) {
    super(authService);
    this.onSave = this.onSave.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  ngOnInit() {
    this.setupComputedPrices();

    if (!this.data.updated) {
      this.computeFromExisting();
    } else {
      this.computeFromInitial();
    }

    this.data.updated = false;

    this.loading = false;
  }

  onBack() {
    this.backCallback.emit();
  }

  onSave(): Promise<any> {
    if (this.data.opinion.id) {
      return this.opinionService
        .updateOne(this.data.opinion.id, this.data.opinion)
        .then(() => this.submitCallback.emit());
    }

    // create opinion and create opinion request or update existing one
    return this.opinionService
      .createOne(this.data.opinion)
      .then(opinion => {
        if (this.data.opinionRequest) {
          this.data.opinionRequest.opinion = opinion.plain();
          this.data.opinionRequest.status = OpinionRequestStatusEnum.PRICES_FILLED;
          return this.opinionRequestService.update(this.data.opinionRequest);
        } else {
          this.data.opinion.id = opinion.id;
          return this.opinionRequestService.createByOpinion(this.data.opinion);
        }
      })
      .then(opinionRequest => {
        return this.opinionRequestService.createDocuments(opinionRequest, this.data.files ? this.data.files : []);
      })
      .then(() => this.submitCallback.emit());
  }

  isFormValid() {
    const defaultPriceValid = this.data.sum && isFinite(this.data.sum.priceDefaultRounded) && this.data.sum.priceDefaultRounded;
    const coefficientPriceValid = this.data.sum && isFinite(this.data.sum.priceCoefficientRounded);
    return defaultPriceValid && coefficientPriceValid;
  }

  computeDefaultPrice(init = false) {
    this.data.sum.priceDefault = '0';
    this.data.sum.priceDefaultWithLand = '0';
    this.data.sum.priceDefaultWithoutLand = '0';
    this.data.sum.countWithLand = 0;

    this.data.opinion.parcelPrices.forEach((p) => {
      let priceDefault : Decimal = null;
      let hasLand = false;
      if (this.data.occupationType === 'P') {
        const landPrice = this.parcelPriceService.computeLandPriceDefault(p, this.data.opinion.expertOpinionPricingMethod);
        hasLand = this.data.opinion.expertOpinionLand && landPrice.cmp(0)>0;
        priceDefault = landPrice
           .add(p.expertOpinionVegetationPriceDefault || '0')
           .add(p.expertOpinionBuildingsPriceDefault || '0');
      } else {
        priceDefault = this.decimalPipe.transform(p.expertOpinionCompensationPriceDefault);
      }
      this.data.sum.priceDefault = new Decimal(this.data.sum.priceDefault).add(priceDefault).toString() || '0';

      if (hasLand) {
        this.data.sum.priceDefaultWithLand = new Decimal(this.data.sum.priceDefaultWithLand).add(priceDefault).toString();
        this.data.sum.countWithLand += 1;
      } else {
        this.data.sum.priceDefaultWithoutLand = new Decimal(this.data.sum.priceDefaultWithoutLand).add(priceDefault);
      }
    });

    if (init) {
      this.data.sum.priceDefaultRounded = this.data.sum.priceDefault;
    }
  }

  computeCoefficientPrice(init = false) {
    this.data.sum.priceCoefficient = '0';
    this.data.sum.priceCoefficientWithLand = '0';
    this.data.sum.priceCoefficientWithoutLand = '0';

    let parcelCount = this.data.sum.countWithLand;
    const rounded = this.decimalPipe.transform(this.data.sum.priceDefaultRounded);
    let priceDefaultRoundedRemain = rounded ? rounded.sub(this.data.sum.priceDefaultWithoutLand) : null;

    // Compute default price proportionally to input summary amount
    // Then computed price with coefficient applied
    this.data.opinion.parcelPrices.forEach((p) => {
      // Rounding is applied only to land price
      if (this.data.opinion.expertOpinionLand && priceDefaultRoundedRemain && rounded && new Decimal(p.expertOpinionLandSquarePrice).cmp(0) > 0) {
        if (!--parcelCount) {
          p.expertOpinionLandPriceDefault = this.roundingService.roundProject(priceDefaultRoundedRemain).toString();
        } else {
          const priceDefault = this.parcelPriceService.computeLandPriceDefault(p, this.data.opinion.expertOpinionPricingMethod)
            .add(p.expertOpinionVegetationPriceDefault || '0')
            .add(p.expertOpinionBuildingsPriceDefault || '0');

          p.expertOpinionLandPriceDefault = this.roundingService.roundProject(
            rounded
            .sub(this.data.sum.priceDefaultWithoutLand)
            .mul(priceDefault)
            .div(this.data.sum.priceDefaultWithLand)).toString();
        }

        p.expertOpinionLandPriceDefault = Decimal.max(new Decimal(p.expertOpinionLandPriceDefault)
          .sub(p.expertOpinionVegetationPriceDefault || '0')
          .sub(p.expertOpinionBuildingsPriceDefault || '0'), 0).toString();

        priceDefaultRoundedRemain = priceDefaultRoundedRemain
          .sub(p.expertOpinionLandPriceDefault)
          .sub(p.expertOpinionVegetationPriceDefault || '0')
          .sub(p.expertOpinionBuildingsPriceDefault || '0');
      } else {
        p.expertOpinionLandPriceDefault = '0';
      }

      p.expertOpinionParcelPriceDefaultRounded = new Decimal(p.expertOpinionLandPriceDefault || '0')
        .add(p.expertOpinionVegetationPriceDefault || '0')
        .add(p.expertOpinionBuildingsPriceDefault || '0').toString();

      // Compute coefficient price by rounded land price
      p.expertOpinionLandPriceCoefficient = this.parcelPriceService.computeLandPriceCoefficientFromDefault(p, this.data.opinion.expertOpinionPricingMethod).toString();

      const sum = this.roundingService.roundProject(
        new Decimal(p.expertOpinionLandPriceCoefficient || '0')
          .add(p.expertOpinionCompensationPrice || '0')
          .add(p.expertOpinionVegetationPrice || '0')
          .add(p.expertOpinionBuildingsPrice || '0')
      );

      this.data.sum.priceCoefficient = sum.add(this.data.sum.priceCoefficient).toString();

      if (new Decimal(p.expertOpinionLandPriceCoefficient).cmp(0) > 0) {
        this.data.sum.priceCoefficientWithLand = sum.add(this.data.sum.priceCoefficientWithLand).toString();
      } else {
        this.data.sum.priceCoefficientWithoutLand = sum.add(this.data.sum.priceCoefficientWithoutLand).toString();
      }

      if (init) {
        this.data.sum.priceCoefficientRounded = this.data.sum.priceCoefficient;
      }
    });
  }

  computeRoundedPrice() {
    let parcelCount = this.data.sum.countWithLand;
    const coefficientRoundedPrice = this.decimalPipe.transform(this.data.sum.priceCoefficientRounded);
    let priceCoefficientRoundedRemain = coefficientRoundedPrice ? coefficientRoundedPrice.sub(this.data.sum.priceCoefficientWithoutLand) : null;
    this.data.sum.price = '0';

    // Compute final price proportionally to input summary amount
    this.data.opinion.parcelPrices.forEach((p) => {
      if (this.hasCoefficient) {
        if (this.data.opinion.expertOpinionLand && priceCoefficientRoundedRemain && coefficientRoundedPrice && new Decimal(p.expertOpinionLandSquarePrice).cmp(0) > 0) {
          if (this.data.sum.priceCoefficientWithLand == '0') {
            p.expertOpinionLandPrice = '0';
          } else if (!--parcelCount) {
            p.expertOpinionLandPrice = this.roundingService.roundProject(priceCoefficientRoundedRemain).toString();
          } else {
            p.expertOpinionLandPrice = this.roundingService.roundProject(coefficientRoundedPrice.sub(this.data.sum.priceCoefficientWithoutLand)
             .mul(new Decimal(p.expertOpinionLandPriceCoefficient || '0').add(p.expertOpinionVegetationPrice || '0').add(p.expertOpinionBuildingsPrice || '0'))
             .div(this.data.sum.priceCoefficientWithLand)
            ).toString();
          }
          p.expertOpinionLandPrice = Decimal.max(new Decimal(p.expertOpinionLandPrice)
            .sub(p.expertOpinionVegetationPrice || '0')
            .sub(p.expertOpinionBuildingsPrice || '0'), 0).toString();

          priceCoefficientRoundedRemain = priceCoefficientRoundedRemain
            .sub(p.expertOpinionLandPrice || '0')
            .sub(p.expertOpinionVegetationPrice || '0')
            .sub(p.expertOpinionBuildingsPrice || '0');
        } else {
          p.expertOpinionLandPrice = null;
        }
      } else {
        p.expertOpinionLandPrice = p.expertOpinionLandPriceCoefficient;
      }
    });
  }

  private computeFromInitial() {
    this.data.sum = { ...PricingExpertOpinionSummaryComponent.DEFAULT_SUM };

    this.computeDefaultPrice(true);
    this.computeCoefficientPrice(true);
    this.computeRoundedPrice();
  }

  private setupComputedPrices() {
    this.priceDefault = '0';
    this.priceCoefficient = '0';

    this.data.opinion.parcelPrices.forEach((p) => {
      if (this.data.opinion.expertOpinionLand && p.parcel) {
        this.priceDefault = new Decimal(this.priceDefault).add(
          this.data.opinion.expertOpinionPricingMethod === 'A' ?
          new Decimal(p.expertOpinionLandAreaPrice) :
          this.roundingService.roundProject(new Decimal(p.expertOpinionLandSquarePrice).mul(p.parcel.vymera))
        ).toString();
        this.priceCoefficient = new Decimal(this.priceCoefficient).add(this.parcelPriceService.computeLandPriceCoefficient(p, this.data.opinion.expertOpinionPricingMethod)).toString();
      }

      if (this.data.opinion.expertOpinionCompensation) {
        this.priceDefault = new Decimal(this.priceDefault).add(p.expertOpinionCompensationPriceDefault).toString();
        this.priceCoefficient = new Decimal(this.priceCoefficient).add(p.expertOpinionCompensationPrice).toString();
      }

      if (this.data.opinion.expertOpinionVegetation) {
        this.priceDefault = new Decimal(this.priceDefault).add(p.expertOpinionVegetationPriceDefault).toString();
        this.priceCoefficient = new Decimal(this.priceCoefficient).add(p.expertOpinionVegetationPrice).toString();
      }

      if (this.data.opinion.expertOpinionBuildings) {
        this.priceDefault = new Decimal(this.priceDefault).add(p.expertOpinionBuildingsPriceDefault).toString();
        this.priceCoefficient = new Decimal(this.priceCoefficient).add(p.expertOpinionBuildingsPrice).toString();
      }
    });
  }

  private computeFromExisting() {
    this.data.sum = { ...PricingExpertOpinionSummaryComponent.DEFAULT_SUM };

    this.data.opinion.parcelPrices.forEach(parcelPrice => {
      const expertOpinionVegetationPriceDefault = new Decimal(parcelPrice.expertOpinionVegetationPriceDefault && this.data.opinion.expertOpinionVegetation ? parcelPrice.expertOpinionVegetationPriceDefault : '0');
      const expertOpinionBuildingsPriceDefault = new Decimal(parcelPrice.expertOpinionBuildingsPriceDefault && this.data.opinion.expertOpinionBuildings ? parcelPrice.expertOpinionBuildingsPriceDefault : '0');
      const expertOpinionCompensationPriceDefault = new Decimal(parcelPrice.expertOpinionCompensationPriceDefault && this.data.opinion.expertOpinionCompensation ? parcelPrice.expertOpinionCompensationPriceDefault : '0');
      const expertOpinionLandPriceDefault = new Decimal(parcelPrice.expertOpinionLandPriceDefault && this.data.opinion.expertOpinionLand ? parcelPrice.expertOpinionLandPriceDefault : '0');

      parcelPrice.expertOpinionParcelPriceDefault = this.roundingService.roundProject(expertOpinionLandPriceDefault.add(expertOpinionVegetationPriceDefault).add(expertOpinionBuildingsPriceDefault).add(expertOpinionCompensationPriceDefault)).toString();
      parcelPrice.expertOpinionParcelPriceDefaultRounded = parcelPrice.expertOpinionParcelPriceDefault;

      this.data.sum.priceDefault = new Decimal(this.data.sum.priceDefault).add(parcelPrice.expertOpinionParcelPriceDefault).toString();
      this.data.sum.priceDefaultRounded = this.data.sum.priceDefault;

      this.data.sum.priceCoefficient = new Decimal(this.data.sum.priceCoefficient)
          .add(parcelPrice.expertOpinionLandPriceCoefficient || '0')
          .add(parcelPrice.expertOpinionVegetationPrice || '0')
          .add(parcelPrice.expertOpinionBuildingsPrice || '0')
          .add(parcelPrice.expertOpinionCompensationPrice || '0').toString();

      this.data.sum.priceCoefficientRounded = new Decimal(this.data.sum.priceCoefficientRounded)
        .add(parcelPrice.expertOpinionLandPrice || '0')
        .add(parcelPrice.expertOpinionVegetationPrice || '0')
        .add(parcelPrice.expertOpinionBuildingsPrice || '0')
        .add(parcelPrice.expertOpinionCompensationPrice || '0').toString();
    });
  }
}
