export const variables = {
  GOOGLEANALYTICSKEYS: {
    production: "UA-103005224-3",
    development: "UA-103005224-2",
  },
  MULTI_SCENE_CLOUD: {
    key: "gmtech", // use "demo" for localhost
    projects: {
      'di_d35_objizdne_trasy_201902': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_d35_objizdne_trasy_201911': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_d35_objizdne_trasy_202103': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_pardubicky_kraj': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_pardubice_2_okresy': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_pardubice': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_statinany_objizdne_trasy_201902': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_statinany_objizdne_trasy_202103': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_statinany_objizdne_trasy_202109': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/sus_pce_mms/data_v0.json',
      'di_pardubicky_kraj_i_35': 'https://multiscenecloud02.blob.core.windows.net/multiscenecloud-data-02/rsd_pce_seceni/data_dimap.json',
    }
  },
  LOCALSTORAGEVERSION: 18,
  SERVICEDESK: {
    BASEURL: "https://gmtech-cz.atlassian.net/servicedesk/customer",
    PORTALSY: 7,
    PORTALDI: 55,
    PORTALPRO: 56,
    PORTALSETTINGS: 57,
    CREATELOGINERROR: 17
  },
  MAINTANANCE: false
, VERSION: '24-08' };