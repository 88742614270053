﻿import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'title-detail-create-case-confirm',
  templateUrl: './create-case-confirm.component.html',
})
export class CreateCaseConfirmComponent implements OnInit {
  @Input()
  public data: any;
  @Output()
  private submitCallback = new EventEmitter();
  public priceTotal = 0;
  public parcelCount = 0;

  constructor(
  ) {
    this.create = this.create.bind(this);
  }

  ngOnInit() {
    this.parcelCount = new Set([...this.data.easements.map(easement => easement.parcel.id), ...this.data.occupations.map(occupation => occupation.parcel.id)]).size;
    if (this.data.obligation.computePrice) {
      this.data.caseOwnerships.forEach((caseOwnership) => {
        this.priceTotal += caseOwnership.easementPrice || caseOwnership.price;
      });
    }
  }

  create() {
    this.submitCallback.emit();
  }
}
