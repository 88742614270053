import { Component, Input, OnInit } from '@angular/core';
import { ParcelModel } from '@app/common/models/parcel.model';
import {
  ExpertOpinionCoefficientsFlatten,
  ParcelPriceCoefficient,
  ParcelPriceModel
} from '@app/ps/models/parcel-price.model';
import { DecimalPipe } from '@app/common/pipes/decimal.pipe';
import { OpinionModel } from '@app/ps/models/opinion.model';
import {ParcelPriceService} from "@app/ps/services/parcel-price.service";
import { RoundingService } from '@app/ps/services/rounding.service';
import { Decimal } from 'decimal.js';
import * as _ from 'lodash';

@Component({
  selector: '[pricing-expert-opinion-row]',
  templateUrl: './pricing-expert-opinion-row.component.html',
  styleUrls: ['pricing-expert-opinion-row.component.scss']
})
export class PricingExpertOpinionRowComponent implements OnInit {

  @Input() parcelPrice: ParcelPriceModel;
  @Input() parcel: ParcelModel;
  @Input() building: any;
  @Input() hasCoefficient: boolean;

  @Input() opinion: OpinionModel;
  @Input() selectable: boolean;
  @Input() selected: boolean;
  @Input() showParcel: boolean;

  readonly COEFFICIENT_8 = ParcelPriceCoefficient.COEFFICIENT_8;
  readonly COEFFICIENT_15 = ParcelPriceCoefficient.COEFFICIENT_15;
  readonly COEFFICIENT_1 = ParcelPriceCoefficient.COEFFICIENT_1;

  constructor(
    private decimalPipe: DecimalPipe,
    private parcelPriceService: ParcelPriceService,
    private roundingService: RoundingService,
  ) {
  }

  ngOnInit() {
  }

  isParcelPriceEnabled(): boolean {
    const hasLand = (this.parcel && !this.parcel.validBuyoutOpinionLand) || !this.opinion.expertOpinionLand;
    const hasVegetation = (this.parcel && !this.parcel.validBuyoutOpinionVegetation) || !this.opinion.expertOpinionVegetation;
    const hasBuilding = (this.parcel && !this.parcel.validBuyoutOpinionBuildings) || !this.opinion.expertOpinionBuildings;
    const isBuilding = this.building && (!this.building.validBuyoutOpinionBuildings || !this.opinion.expertOpinionBuildings);
    return (hasLand && hasVegetation && hasBuilding) || isBuilding;
  }

  computeParcelPrice() {
    const parcelPrice = this.parcelPrice;
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    parcelPrice.expertOpinionParcelPriceDefault = '0';
    parcelPrice.expertOpinionLandPriceCoefficient = '0';

    // Land price
    if (this.opinion.expertOpinionLand && parcelPrice.parcel) {
      const squarePrice = this.decimalPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
      parcelPrice.expertOpinionParcelPriceDefault = squarePrice ? squarePrice.mul(parcelPrice.parcel.vymera).toString() : null;
      parcelPrice.expertOpinionLandPriceCoefficient = this.parcelPriceService.computeLandPriceCoefficient(parcelPrice, this.opinion.expertOpinionPricingMethod).toString();
    } else {
      parcelPrice.expertOpinionLandSquarePrice = null;
      parcelPrice.expertOpinionLandAreaPrice = null;
    }

    ['compensation', 'vegetation', 'buildings'].forEach(k => {
      const upperK = _.upperFirst(k);
      if (this.opinion[`expertOpinion${upperK}`]) {
        const price = this.decimalPipe.transform(parcelPrice[`expertOpinion${upperK}PriceDefault`]);
        const priceDefault = this.decimalPipe.transform(parcelPrice.expertOpinionParcelPriceDefault);
        parcelPrice.expertOpinionParcelPriceDefault = price && priceDefault ? priceDefault.add(price).toString() : null;
        parcelPrice[`expertOpinion${upperK}Price`] = price ? this.roundingService.roundProject(price.mul(coefficients[`${k}Coefficient`])).toString() : null;
      } else {
        parcelPrice[`expertOpinion${upperK}Price`] = null;
        parcelPrice[`expertOpinion${upperK}PriceDefault`] = null;
      }
    });

    // Store configuration
    parcelPrice.expertOpinionCoefficients = ExpertOpinionCoefficientsFlatten.toCoefficients(coefficients);
  }

  toggleMultiply(type: 'compensation' | 'vegetation' | 'buildings') {
    const coefficients = this.parcelPrice.expertOpinionCoefficientsFlatten;
    let firstLandCoefficient = null;

    if (coefficients.land8Multiply) {
      firstLandCoefficient = this.COEFFICIENT_8;
    } else if (coefficients.land15Multiply) {
      firstLandCoefficient = this.COEFFICIENT_15;
    }

    coefficients[type + 'Multiply'] = !coefficients[type + 'Multiply'];

    if (firstLandCoefficient && coefficients[type + 'Coefficient'] == this.COEFFICIENT_1) {
      coefficients[type + 'Coefficient'] = firstLandCoefficient;
    }
  }

  multipleLandCoefficient(parcelPrice: ParcelPriceModel) {
    return this.parcelPriceService.multipleLandCoefficient(parcelPrice);
  }

  multipleLandCoefficientAreaMatches(parcelPrice: ParcelPriceModel) {
    return this.parcelPriceService.multipleLandCoefficientAreaMatches(parcelPrice);
  }
}
