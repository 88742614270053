﻿import { Component, Input, OnInit } from "@angular/core";
import { StateService, TransitionService } from "@uirouter/angular";
import { TitleModel } from '@app/common/models/title.model';
import { DialogService } from '@app/common/services/dialog.service';
import { AlertComponent } from '@app/common/components/alert/alert.component';
import * as _ from 'lodash';
import { TitleDetailCreateCaseSelectParentService } from '@app/ps/services/title-detail-create-case-select-parent.service';
import { TitleDetailCreateCaseDataService } from '@app/ps/services/title-detail-create-case-data.service';
import { ClassName } from '@app/common/enums/class-name.enum';
import { CreateCaseSelectOwnerDialogComponent } from '@app/ps/titles/components/create-case-select-owner-dialog/create-case-select-owner-dialog.component';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './create-case.component.html',
})
export class CreateCaseComponent implements OnInit {
  @Input() titleId: number;
  @Input() caseId: number;
  @Input() title: TitleModel;
  @Input() private obligationType: string;
  step: any;
  finished = false;
  data: any;
  helpId: number;
  titleDataPromise: Promise<any>;
  generalDataPromise: Promise<any>;
  displayConfirm = true;
  loading = true;
  helpIds = HelpService.HELP_IDS;

  private steps: any[];
  private deregisterLeaveListener: Function;
  private onHelpIdChanged: Function;

  constructor(
    private restangular: Restangular,
    private transitionService: TransitionService,
    private stateService: StateService,
    private dialogService: DialogService,
    private titleDetailCreateCaseDataService: TitleDetailCreateCaseDataService,
    private titleDetailCreateCaseSelectParentService: TitleDetailCreateCaseSelectParentService,
  ) {
    this.hasStep = this.hasStep.bind(this);
    this.onSelectParentOwnership = this.onSelectParentOwnership.bind(this);
  }

  menuSteps: {name: string, ids: string[], enabled: boolean}[] = [
    {name: 'Typ případu', ids: ['select-obligation'], enabled: false},
    {name: 'Předmět případu a vlastníci', ids: ['select-objects', 'select-parent-ownership'], enabled: false},
    {name: 'Další LV vlastníka', ids: ['select-titles', 'select-parent-ownership2'], enabled: false},
    {name: 'Detaily případu', ids: ['detail'], enabled: false},
    {name: 'Potvrzení', ids: ['confirm'], enabled: false},
    {name: 'Výsledek', ids: ['summary'], enabled: false},
  ];

  // Prevent exit without users will
  beforeExit(transition) {
    if (transition && transition.to() === transition.from()) {
      return;
    }
    return this.dialogService.confirmDialogPromise('Opravdu chcete odejít? Všechna zadaná data budou ztracena.')
      .then((data) => {
        if (data === true) {
          this.deregisterLeaveListener();
        } else {
          return Promise.resolve(false);
        }
      });
  }

  async ngOnInit() {
    this.steps = [];
    this.data = {};

    if (this.caseId) {
      this.data.fromCase = await this.restangular.one('cases', this.caseId).get({loadCollections: [
        'caseOccupations',
        'caseEasements',
        'titles',
        'caseOwnerships',
        'caseBuildings', ]}
      ).toPromise();

      // výběr lv
      this.titleId = this.data.fromCase.titles[0].id;
    }

    if (this.titleId) {
      if (!this.caseId) {
        this.stepChanged('select-obligation');
      }

      this.titleDataPromise = this.titleDetailCreateCaseDataService.loadTitleData(this.titleId);
      this.generalDataPromise = this.titleDetailCreateCaseDataService.loadGeneralData(this.titleId, this.titleDataPromise);

      this.generalDataPromise.then(() => { this.loading = false; });
    }

    if (this.caseId) {
      this.generalDataPromise.then(data => {
        this.data.obligation = data.dataObligationsFiltered.find(obligation => obligation.type === this.obligationType);
        if (!this.data.obligation) {
          const dialog = this.dialogService.open(AlertComponent, {
            data: {
              msg: 'Pro uvedený list vlastnictví není k dispozici typ případu!',
            },
            className: ClassName.ADJUSTED_DIALOG,
          });

          const sub = dialog.afterClosed.subscribe((result: boolean) => {
            sub.unsubscribe();
            this.deregisterLeaveListener();
            this.stateService.go('^');
          });
          return;
        }

        this.onSelectObligation();
      });
    }

    // If any link is clicked
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
  }

  onSelectObligation() {
    this.stepChanged('select-objects');
  }

  showSelectionError() {
    this.step = null;
    const dialog = this.dialogService.open(AlertComponent, {
      data: {
        msg: 'V aktuálním stavu dat není možné založit případ!',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      sub.unsubscribe();
      this.deregisterLeaveListener();
      this.stateService.go('^');
    });
  }

  onSelectObjects() {
    if (!this.data.caseOwnerships) {
      this.showSelectionError();
      return;
    }
    this.stepChanged('select-parent-ownership');
  }

  onSelectParentOwnership(skipped = false) {
    if (skipped) {
      this.steps.pop();
    }
    // For second step pass go to detail otherwise to select-titles
    if (this.step.key === 'select-parent-ownership2') {
      if (this.data.fromCase && (this.data.fromCase.caseEasements.length !== this.data.easements.length ||
         this.data.fromCase.caseOccupations.length !== this.data.occupations.length ||
         this.data.fromCase.caseBuildings.length !== this.data.buildings.length ||
         this.data.fromCase.caseOwnerships.length !== this.data.caseOwnerships.length)
      ) {
        this.showSelectionError();
        return;
      }
      this.stepChanged('detail');
    } else {
      return this.checkCantBeParent();
    }
  }

  onSelectTitles(skipped = false) {
    if (skipped) {
      this.steps.pop();
    }
    // Second step repetition
    window.setTimeout(() => this.stepChanged('select-parent-ownership2'));
  }

  onDetail(error = false) {
    if (error) {
      this.steps.pop();
      this.onBack();
    } else {
      this.stepChanged('confirm');
    }
  }

  onConfirm() {
    this.finished = true;
    this.deregisterLeaveListener();
    this.stepChanged('summary');
  }

  onSummary() {
    this.stateService.go('^');
  }

  onBack() {
    const step = this.steps.pop();
    this.data = step.data;
    this.stepChanged(step.key);
    this.displayConfirm = false;
  }

  hasStep(stepKey): boolean {
    return this.steps.some(step => step.key === stepKey);
  }

  onClickStep(menuStep) {
    if (!menuStep.enabled) {
      return;
    }
    while (menuStep.ids[0] !== this.step.key && this.steps.length) {
      this.steps.pop();
      this.onBack();
    }
  }

  private stepChanged(stepKey) {
    // identify forward step
    if (this.steps.length && !this.steps[this.steps.length - 1].data) {
      // create new instance to store state of data
      this.steps[this.steps.length - 1].data = _.cloneDeep(this.data);
      this.displayConfirm = true;
    }
    this.step = {
      key: stepKey,
      data: null, // Object is set as soon as leaving step
    };
    this.steps.push(this.step);
    switch (this.step.key) {
      case 'select-obligation':
        this.helpId = null;
        break;
      case 'select-objects':
        this.helpId = this.helpIds.TITLE_DETAIL_CREATE_CASE_SELECT;
        break;
      case 'select-titles':
        this.helpId = this.helpIds.TITLE_DETAIL_CREATE_CASE_SELECT_TITLES;
        break;
      case 'select-parent-ownership':
      case 'select-parent-ownership2':
        this.helpId = this.helpIds.TITLE_DETAIL_CREATE_CASE_SELECT_PARENT_OWNERSHIP;
        break;
      case 'detail':
        this.helpId = null;
        break;
      case 'confirm':
        this.helpId = this.helpIds.TITLE_DETAIL_CREATE_CASE_CONFIRM;
        break;
      case 'summary':
        this.helpId = this.helpIds.TITLE_DETAIL_CREATE_CASE_SUMMARY;
        break;
      default:
        this.helpId = null;
    }
    if (this.onHelpIdChanged) {
      this.onHelpIdChanged(this.helpId);
    }

    this.menuSteps.forEach((step) => {
      step.enabled = !this.caseId && ((step.ids.includes('summary') && this.step.key === 'summary') || (this.step.key !== 'summary' && step.ids.some(this.hasStep)));
    });
  }

  private checkCantBeParent() {
    const ownershipsPossibleParents = this.titleDetailCreateCaseSelectParentService.ownershipsPossibleParents(this.data.caseOwnerships);
    const ownershipsWithoutParentPromise = ownershipsPossibleParents.length ? this.titleDetailCreateCaseSelectParentService.filterOwnershipsWithoutParent(this.data.caseOwnerships, this.data.obligation.type) : Promise.resolve([]);
    return ownershipsWithoutParentPromise.then((ownershipsWithoutParent) => {
      if (ownershipsWithoutParent.length) {
        const dialog = this.dialogService.open(CreateCaseSelectOwnerDialogComponent, { data: {
            ownershipsWithoutParent: ownershipsWithoutParent,
            ownershipsPossibleParents: ownershipsPossibleParents
        }});

        const sub = dialog.afterClosed.subscribe((res: boolean) => {
          sub.unsubscribe();
          if (res) {
            this.stepChanged('select-titles');
          } else {
            do {
              this.onBack();
            } while (this.step.key !== 'select-objects');
          }
        });
      } else {
        this.stepChanged('select-titles');
      }
    });
  }

  public onCallbackRegister($event) {
    this.onHelpIdChanged = $event.onHelpIdChanged;
  }
}
