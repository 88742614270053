<div class="list" [loading]="loading">
  <div *ngIf="!loading">
    <div class="row">
      <div class="col-6"><a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Manažerský report</a></div>
      <div class="col-6 text-right">
        <action-buttons name="Možnosti">
          <a (click)="onExportXlsx()" class="bordered-bottom p-10"><i class="fa fa-download"></i> Export XLSX</a>
          <a (click)="onExportPdf()" class="bordered-bottom p-10"><i class="fa fa-download"></i> Export PDF</a>
          <a *ngIf="canVisitDetail" visibleByBrand="mpv" class="bordered-bottom p-10" uiSref="symap.project.map" [uiParams]="{ projectKey: projectKey, tool: 'print-project' }" target="_blank"><i class="fa fa-map"></i> Tisk mapového přehledu akce do PDF</a>
          <a *ngIf="canVisitDetail" class="bordered-bottom p-10" uiSref="symap.project.home" [uiParams]="{ projectKey: projectKey }" target="_blank"><i class="fa fa-arrow-right"></i> Detail akce</a>
        </action-buttons>
      </div>
    </div>

    <div id="print">
      <h1>{{ project.name }}</h1>

      <h2>Data o akci</h2>
      <fieldset class="bordered">
        <div visibleByBrand="mpv">
          <div class="column-50">
            <label class="txt--blue">Evidenční číslo</label>
            <div class="info txt--blue"><b>{{ project.isprofond }}</b></div>
            <label class="txt--blue">Komunikace</label>
            <div class="info txt--blue"><b>{{ project.infrastructure }}</b></div>
            <label class="txt--blue">Kraj</label>
            <div class="info txt--blue"><b>{{ project.region }}</b></div>
          </div>
          <div class="column-50">
            <label class="txt--blue">Organizační jednotka</label>
            <div class="info txt--blue"><b>{{ project.investor }}</b></div>
            <label class="txt--blue">Manažer akce</label>
            <div class="info txt--blue"><b>{{ project.manager }}</b></div>
            <label class="txt--blue">Stav akce</label>
            <div class="info txt--blue"><b>{{ project.stateName }}</b></div>
            <label class="txt--blue">Typ akce</label>
            <div class="info txt--blue"><b>{{ project.type }}</b></div>
          </div>
        </div>
        <div visibleByBrand="maja">
          <div class="column-50">
            <label class="txt--blue">Evidenční číslo</label>
            <div class="info txt--blue"><b>{{ project.isprofond }}</b></div>
            <label class="txt--blue">Organizační jednotka</label>
            <div class="info txt--blue"><b>{{ project.investor }}</b></div>
            <label class="txt--blue">Kraj</label>
            <div class="info txt--blue"><b>{{ project.region }}</b></div>
          </div>
          <div class="column-50">
            <label class="txt--blue">Manažer akce</label>
            <div class="info txt--blue"><b>{{ project.manager }}</b></div>
            <label class="txt--blue">Stav akce</label>
            <div class="info txt--blue"><b>{{ project.stateName }}</b></div>
          </div>
        </div>
      </fieldset>

      <ng-container *ngIf="totalAvailable">
        <h2>Stav vypořádání případů <a (click)="showPercentage = !showPercentage" class="pl-10 fa" title="{{ showPercentage ? 'Nezobrazovat procenta' : 'Zobrazit procenta' }}}" [ngClass]="{'fa-bar-chart': showPercentage, 'fa-percent': !showPercentage}"></a></h2>
        <perfect-scrollbar [wheelPropagation]="true" [horizontal]="true" class="category-select bordered">
          <h3 class="ml-20">Celkový stav vypořádání případů akce</h3>
          <div class="flexbox-container">
            <ng-container *ngFor="let category of totalCategoryTabs; index as i">
              <div *ngIf="i === totalCategoryTabs.length - 1" class="category-separator"></div>
              <div class="flex1 basis0">
                <div class="category">
                  <div class="category-nav">
                    <div class="count">
                      <span *ngIf="!showPercentage || category.total === undefined">{{ category.value }}</span>
                      <span *ngIf="showPercentage && category.total !== undefined">{{ category.value | percentage:category.total }}</span>
                    </div>
                    <div class="label">{{ category.name }}</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </perfect-scrollbar>

        <fieldset class="bordered no-border-top">
          <div class="row">
            <div *ngFor="let tab of casesCategoryTabs" class="col-12 col-xl-3 statistics">
              <div *ngIf="tab.available">
                <h3 class="text-center">{{ tab.name }}</h3>
                <h4 class="text-center">{{ tab.total }} případů ({{ showPercentage ? tab.donePercentage : tab.done }} vypořádáno)</h4>
                <div class="pie-container">
                  <gmt-pie-chart [data]="tab.chartData" [title]="showPercentage ? tab.donePercentage : tab.done" subTitle="vypořádáno" [subSubTitle]="tab.partialData ? '(částečná data)' : undefined"></gmt-pie-chart>
                </div>
                <p class="text-center"><b class="text-uppercase">{{ tab.name }}:</b></p>
                <div class="pie-legend">
                  <div class="mb-5"><span class="status status--to-create"></span> Nezaloženo {{ showPercentage ? tab.toCreatePercentage : tab.toCreate }} <a uiSref="symap.project.map" *ngIf="canVisitDetail" [uiParams]="{ projectKey: projectKey, layer: [tab.mapLayerPrefix + 'not_in_progress', 'geom_ku', 'kn_latest'] }" target="_blank" title="Zobrazit v mapovém okně"><i class="fa fa-map"></i></a></div>
                  <div class="mb-5"><span class="status status--progress"></span> V řešení {{ showPercentage ? tab.progressPercentage : tab.progress }} <a uiSref="symap.project.map" *ngIf="canVisitDetail" [uiParams]="{ projectKey: projectKey, layer: [tab.mapLayerPrefix + 'in_progress', 'geom_ku', 'kn_latest'] }" target="_blank" title="Zobrazit v mapovém okně"><i class="fa fa-map"></i></a></div>
                  <div class="mb-5"><span class="status status--done"></span> Vypořádáno {{ showPercentage ? tab.donePercentage : tab.done }} <a uiSref="symap.project.map" *ngIf="canVisitDetail" [uiParams]="{ projectKey: projectKey, layer: [tab.mapLayerPrefix + 'resolved', 'geom_ku', 'kn_latest'] }" target="_blank" title="Zobrazit v mapovém okně"><i class="fa fa-map"></i></a></div>
                </div>
              </div>
              <div *ngIf="!tab.available">
                <h3 class="text-center disabled">{{ tab.name }}</h3>
                <h4 class="text-center"></h4>
                <div class="pie-container">
                  <gmt-pie-chart [data]="[{ value: 1, color: '#909090'}]" [tooltip]="false" [title]="showPercentage ? '-%' : '-'" [subTitle]="'(' + ((tab.readiness | readiness) || 'neobsahuje data') + ')'"></gmt-pie-chart>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </ng-container>
    </div>

    <div *ngIf="canVisitDetail">
      <h2>Vyhledání</h2>
      <div *ngIf="selectedEntity">
        <filter *ngIf="selectedEntity.list.filter.filters.searchText" [list]="selectedEntity.list" (onChanged)="selectedEntity.pageableList.onFilterChanged($event)" filterButtonName="Vyhledat">
          <span style="padding-right: 10px; padding-top: 5px;">Zvolit oblast vyhledání</span> <gmt-switch [options]="entityTabs" (select)="onChangeEntity($event.id)" [selected]="selectedEntity"></gmt-switch>
          <div class="column-100">
            <input class="fulltext" autocomplete="off" type="text" placeholder="Fultextové hledání..." [(ngModel)]="selectedEntity.list.filter.filters.searchText.values[0].id"/>
          </div>
        </filter>

        <div [loading]="!selectedEntity.list || selectedEntity.list.loading || tabsLoading">
          <div *ngIf="selectedEntity.list">
            <table-cases *ngIf="selectedEntity.id === 'cases'" [list]="selectedEntity.list" (sort)="selectedEntity.pageableList.onSort($event.column, $event.ascOrDesc)" [customColumns]="selectedEntity.columns" [customProjectKey]="projectKey" [showNavIcons]="true" [caseStatusType]="'progress'"></table-cases>
            <table-parcels *ngIf="selectedEntity.id === 'parcels'" [list]="selectedEntity.list" (sort)="selectedEntity.pageableList.onSort($event.column, $event.ascOrDesc)" [customProjectKey]="projectKey" [geometricPlanModuleEnabled]="false" [showNavIcons]="true" [opinionModuleEnabled]="false"></table-parcels>
            <table-titles *ngIf="selectedEntity.id === 'titles'" [list]="selectedEntity.list" (sort)="selectedEntity.pageableList.onSort($event.column, $event.ascOrDesc)" [customColumns]="selectedEntity.columns" [customProjectKey]="projectKey" [showNavIcons]="true"></table-titles>
            <table-subjects *ngIf="selectedEntity.id === 'subjects'" [list]="selectedEntity.list" (sort)="selectedEntity.pageableList.onSort($event.column, $event.ascOrDesc)" [customProjectKey]="projectKey" [showNavIcons]="true"></table-subjects>
            <table-cases *ngIf="selectedEntity.id === 'expropriation'" [list]="selectedEntity.list" (sort)="selectedEntity.pageableList.onSort($event.column, $event.ascOrDesc)" [customColumns]="selectedEntity.columns" [customProjectKey]="projectKey" [showNavIcons]="true" [caseStatusType]="'progress'"></table-cases>
          </div>
        </div>

        <div class="flexbox-container">
          <itemscounter *ngIf="selectedEntity.list.itemCount && selectedEntity.list.list !== null" [list]="selectedEntity.list" (callbackRegister)="selectedEntity.pageableList.itemcounterCallbackRegister($event)"></itemscounter>
          <pagescounter *ngIf="selectedEntity.list.itemCount && selectedEntity.list.list !== null" [list]="selectedEntity.list" (callbackRegister)="selectedEntity.pageableList.pagescounterCallbackRegister($event)" class="flex1 mt-5 text-center"></pagescounter>
          <div class="page-count-select mt-5" *ngIf="selectedEntity.list.itemCount && selectedEntity.list.list !== null">
            <span>Záznamů na stránce</span>
            <gmt-select required="true" [data]="selectedEntity.pageableList.dataPaging" field="itemsPerPage" [optionItems]="selectedEntity.pageableList.itemsPerPageItems" (changed)="selectedEntity.pageableList.changePageItems()" class="min-width-unset"></gmt-select>
          </div>
        </div>

        <div>
          <pagination
            *ngIf="selectedEntity.list.itemCount && selectedEntity.list.list !== null"
            [shouldUpdateUrl]="false"
            [list]="selectedEntity.list"
            (pageChanged)="onPageChanged($event)"
            (callbackRegister)="selectedEntity.pageableList.paginationCallbackRegister($event)"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</div>
