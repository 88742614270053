import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { TitleModel } from '@app/common/models/title.model';
import { CaseModel } from '../../models/case.model';
import { ListModel } from '@app/common/models/list.model';
import { SettingsService } from '@app/ps/services/settings.service';
import { TableService } from '@app/ps/services/table.service';

@Component({
  selector: 'table-cases',
  templateUrl: './table-cases.component.html',
  styleUrls: ['./table-cases.component.scss']
})
export class TableCasesComponent {
  @Input() list: ListModel<CaseModel>;
  @Input() isChecked: Function;
  @Input() inverseSelection: boolean;
  @Input() columnNames: any;
  @Input() caseStatusType: 'normal' | 'progress' = 'normal';
  @Input() customColumns: string[]; // avoid setting service
  @Input() customProjectKey: string;
  @Input() showNavIcons = false;
  @Output() checkAll: EventEmitter<any> = new EventEmitter();
  @Output() sort: EventEmitter<{}> = new EventEmitter();
  @Output() toggleCheckItem: EventEmitter<TitleModel> = new EventEmitter();
  private readonly TABLE_ID = 'cases';
  public isArray = Array.isArray;

  constructor(
    private tableService: TableService,
    private settingsService: SettingsService,
  ) {}

  onCheckAll() {
    this.checkAll.emit();
  }

  onToggleCheckItem(title: TitleModel) {
    this.toggleCheckItem.emit(title);
  }

  onSort(column: string, ascOrDesc: string) {
     this.sort.emit({
       column,
       ascOrDesc,
     });
  }

  hideColumn(columnId: string) {
    if (this.customColumns) {
      return !this.customColumns.includes(columnId);
    }
    return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
  }

  getColumnName(columnId: string) {
    if (this.columnNames && this.columnNames[columnId]) {
      return this.columnNames[columnId];
    }
    const column = this.tableService.getColumnById(this.TABLE_ID, columnId);
    return column ? column.name : '';
  }
}
