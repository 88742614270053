import { Inject, Injectable } from '@angular/core';
import { HttpService } from '@app/common/services/http.service';
import { APP_BRAND, APP_CONFIG } from '@app/common/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private settings = null;
  private settingsLoader = null;
  private canceller;

  constructor(
    @Inject(APP_CONFIG) private config: any,
    @Inject(APP_BRAND) private APP_BRAND: any,
    private httpService: HttpService,
  ) {}

  getSettingsLoader(): Promise<any> {
    return this.settingsLoader;
  }

  clearSettings() {
    this.settings = null;
    if (this.canceller) {
      this.canceller();
    }
  }

  loadSettings(projectId: number): Promise<any> {
    if (!Number.isInteger(projectId)) {
      return Promise.reject(`activeProjectId is not valid integer value ${projectId}.`);
    }

    if (this.settings) {
      return Promise.resolve(this.settings);
    } else if (this.settingsLoader) {
      return this.settingsLoader;
    } else {
      const cancelPromise = new Promise<any>((resolve, reject) => {
        this.canceller = resolve;
      });

      this.settingsLoader = this.httpService.call({
        path: `projects/${projectId}/settings`,
        baseUrl: this.config.BACKEND_OPTIONS.restUrlSY + '/app',
        method: 'GET',
        cancelPromise: cancelPromise
      }).then(
        (settings) => {
          this.settings = settings;
          this.settingsLoader = null;
          this.canceller = undefined;
          return this.settings;
        },
        (reason) => {
          this.settingsLoader = null;
          this.canceller = undefined;
          return Promise.reject(reason);
        }
      );
      return this.settingsLoader;
    }
  }

  shouldHideColumn(tableId: string, columnId: string): boolean {
    const settings = this.getSettings();
    // Temp hack
    if (tableId === 'cases' && columnId === 'officer' && this.APP_BRAND.NAME === 'RSD') {
      return true;
    }

    if (tableId === 'cases' && columnId === 'internalCaseNumber' && this.APP_BRAND.NAME !== 'RSD') {
      return true;
    }

    if (settings) {
      const columnsVisibility = settings.columnSettings;
      if (columnsVisibility.hidden) {
        const searchColumnCfg = columnsVisibility.hidden.find(columnCfg => columnCfg.name === tableId);
        return searchColumnCfg && searchColumnCfg.columns.includes(columnId);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getSettings() {
    return this.settings;
  }

  settingsUpload(projectId: number, settings: any): Promise<any> {
    return this.httpService.call({
      path: `projects/${projectId}/settings/1`, // hack - BE needs settings id bit not use it
      baseUrl: this.config.BACKEND_OPTIONS.restUrlSY + '/app',
      method: 'PUT',
      data: settings
    });
  }
}
