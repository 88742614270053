﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { AuthService } from '../services/auth.service';
import { RoundingPrecisionTypeEnum } from '@app/ps/enums/rounding-precision-type.enum';

@Pipe({ name: 'price' })
@Injectable({
  providedIn: 'root'
})
export class PricePipe implements PipeTransform {
  nbsp = String.fromCharCode(160);

  constructor(
    private authService: AuthService
  ) {}

  transform(amount: string, showCurrency: boolean = true) {
    if (!amount) {
      return amount;
    }
    let textValue = amount + "";
    const decimalDivider = textValue.indexOf(",") > -1 ? "," : ".";
    const [wholePart, decimalPart] = textValue.split(decimalDivider);

    if (decimalPart || this.authService.getActualProject().priceRoundingPrecisionType == RoundingPrecisionTypeEnum.PENNIES) {
        if (!decimalPart) {
          textValue = wholePart + ",-";
        } else {
          textValue = wholePart + "," + decimalPart.padEnd(2, '0');
        }
    }
    if (showCurrency) {
      return `${textValue}${this.nbsp}Kč`;
    } else {
      return textValue;
    }
  }
}
