// leaflet
require('leaflet-draw');
require('leaflet-easybutton');
require('leaflet-graphicscale');
require('leaflet-tilecorrection');

import 'leaflet';
import 'proj4leaflet';

// leaflet.fullscreen po kompilaci schází ve verzi 2.0.0 nedefinovaná proměnná
import * as screenfull from 'leaflet.fullscreen';
(window as any).screenfull = screenfull;

// chart
import * as d3 from 'd3';
import * as d3tip from 'd3-tip';
d3.tip = d3tip;

// other
require('perfect-scrollbar');

import { enableProdMode, NgZone } from '@angular/core';
import { UIRouter, UrlService } from '@uirouter/core';
import { TransitionService } from '@uirouter/angular';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { StringUtils } from '@app/common/utils/string.utils';
import { AppModule } from '@app/app.module';

// configuration
// @ts-ignore
import { environment } from './environments/environment';
// @ts-ignore
import { variables } from './environments/variables';
// @ts-ignore
import { backend } from './environments/backend';
// @ts-ignore
import { brand } from './environments/brand';

(window as any).SYMAP_CONFIG = {
  BACKEND_OPTIONS: backend,
  APP_BRAND: brand,
  MULTI_SCENE_CLOUD: variables.MULTI_SCENE_CLOUD,
  SERVICE_DESK: variables.SERVICEDESK,
  VERSION: variables.VERSION,
  GOOGLEANALYTICSKEY: variables.GOOGLEANALYTICSKEYS[environment.production ? 'production' : 'development'],
  LOCALSTORAGEVERSION: variables.LOCALSTORAGEVERSION,
  ENV: environment.production ? 'production' : 'development',
  MAINTANANCE: variables.MAINTANANCE
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(platformRef => {
    if ((window as any).ngRef) {
      (window as any).ngRef.destroy();
    }
    (window as any).ngRef = platformRef;
  })
  .catch(err => console.error(err));
