import {Component, Input, OnInit} from "@angular/core";

export interface CaseOwnershipValidation {
  field: string;
  msg: string;
  id?: number;
}

@Component({
  selector: 'field-validation-msg',
  templateUrl: './field-validation-msg.component.html',
})
export class FieldValidationMsgComponent implements OnInit {
  @Input() requirements: CaseOwnershipValidation[];
  @Input() errors: CaseOwnershipValidation[];
  @Input() type: string;
  @Input() field: string;
  @Input() id: number;

  msg = '';

  ngOnInit() {
  }

  getMsg() {
    return this[this.type]
      .filter(v => v.field === this.field && (!this.id || this.id === v.id))
      .map(v => v.msg)
      .join(', ');
  }
}
