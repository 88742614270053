import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { AuthService } from '@app/common/services/auth.service';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import fields from './form-fields';
import { ListService } from '@app/common/services/list.service';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { SettingsService } from '@app/ps/services/settings.service';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { Restangular } from 'ngx-restangular';
import {RoleEnum} from "@app/common/enums/role.enum";

@Component({
  selector: 'form-case-update',
  templateUrl: './form-case-update.component.html',
  styleUrls: ['./form-case-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCaseUpdateComponent implements OnInit {
  caseObject: CaseModel;
  availableFields: any[] = fields;
  displayFields: any = {};
  displayFieldsEnabled: boolean;
  distributionSystemParts: any;
  distributionSystemPartsOptions: any;
  distributionSystemPartsEnabled: boolean;
  helpIds = HelpService.HELP_IDS;
  manualRentPriceAllowed = false;
  expropriationAppealSides = [
    {
      id: 'I',
      name: 'Investor'
    },
    {
      id: 'O',
      name: 'Vlastník'
    }
  ];
  mandataryFilter = {
    filters: {
      searchText: {
        values: [],
      },
      applications: {
        values: [this.authService.getActiveApplication()],
      },
      permission: {
        values: ['assignable'],
      },
      projects: {
        values: [this.authService.getActualProject().key],
      },
    },
    sortOrder: [
      { sortBy: 'surname' },
      { sortBy: 'name' },
    ],
  };

  officerFilter = {
    filters: {
      searchText: {
        values: [],
      },
      applications: {
        values: [this.authService.getActiveApplication()],
      },
      roles: {
        values: [RoleEnum.REFERENT, RoleEnum.REFERENT_VEDOUCI],
      },
      projects: {
        values: [this.authService.getActualProject().key],
      },
    },
    sortOrder: [
      { sortBy: 'surname' },
      { sortBy: 'name' },
    ],
  };

  constructor(
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    private restangular: Restangular,
    public userNamePipe: UserNamePipe,
    private authService: AuthService,
    private caseService: CaseService,
    private cdr: ChangeDetectorRef,
    private config: DialogConfig,
    private dialog: DialogRef,
    private listService: ListService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.onAddTenant = this.onAddTenant.bind(this);
    this.onExpropriationAppealSideSelect = this.onExpropriationAppealSideSelect.bind(this);
    this.onRemoveTenant = this.onRemoveTenant.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.manualRentPriceAllowed = this.authService.getActualProject().manualRentPriceAllowed;

    this.caseObject = (<DialogConfigData>this.config.data).caseObject;
    this.caseObject.tenantNames = this.caseObject.tenantNames || [];
    this.distributionSystemPartsEnabled = this.caseService.showEasementDistributionSystemParts(this.caseObject.obligation);

    if (this.distributionSystemPartsEnabled) {
      this.caseObject.distributionSystemParts = this.caseObject.distributionSystemParts || [];
      this.initializeDistributionSystemPartsOptions();
    }

    if (this.canUpdateCaseData()) {
      this.initializeDates();
    }
  }

  onExpropriationAppealSideSelect(appealSide: SwitchOption) {
    this.caseObject.expropriationAppealSide = appealSide;
  }

  onPublicationExceptionSelect(value: string) {
    this.caseObject.contractRegisterPublicationException = value;
  }

  onAddTenant() {
    this.caseObject.tenantNames.push('');
  }

  onRemoveTenant(index: number) {
    if (index > - 1) {
      this.caseObject.tenantNames.splice(index, 1);
    }
  }

  async onUpdate(): Promise<CaseModel> {
    await this.restangular
      .one('cases', this.caseObject.id)
      .customPUT(this.caseObject)
      .toPromise();

    return this.restangular
      .one('cases', this.caseObject.id)
      .get()
      .toPromise()
      .then((data: CaseModel) => {
        this.dialog.close(data);
      });
  }

  trackByFn(index: number) {
    return index;
  }

  private initializeDates() {
    if (!this.caseObject.obligation.bonusPeriod && !this.caseObject.bonusDate) {
      _.pull(this.availableFields, 'bonusPossible');
    }

    if (!this.caseObject.bonusDate) {
      _.pull(this.availableFields, 'bonusGranted');
    }

    this.displayFields = this.getDisplayedFields();
    this.displayFieldsEnabled = !!Object.keys(this.displayFields).length;
  }

  private canUpdateCaseData(): boolean {
    return this.authService.canUpdateCaseData();
  }

  private getDisplayedFields(): {[key: string]: boolean} {
    const activeFields = this.availableFields.filter((item: string) => this.caseObject[item] || this.caseObject[item] === false);
    const result = _.fromPairs(activeFields.map((field: string) => [field, true]));
    result.dueDate = true;

    return result;
  }

  private initializeDistributionSystemPartsOptions() {
    const list = this.listService.createList('distribution-system-parts', { limit: undefined });
    this.listService
      .fetchResult(list)
      .then(() => {
        this.distributionSystemPartsOptions = list.list;
        this.cdr.markForCheck();
      });
  }

  public hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('cases', columnId);
  }
}

