<div class="flexbox-container" (click)="caseOwnership.opened = !caseOwnership.opened">
  <div class="pr-10">
    <a><i class="fa" [ngClass]="{'fa-chevron-up': caseOwnership.opened, 'fa-chevron-down': ! caseOwnership.opened}"></i></a>
  </div>
  <div *ngIf="caseOwnership.ownership.isBsm">
    SJM {{ caseOwnership.caseSubjects[0].subject | subjectName }} a {{ caseOwnership.caseSubjects[1].subject | subjectName }}
  </div>
  <div *ngIf="!caseOwnership.ownership.isBsm">
    {{ caseOwnership.caseSubjects[0].subject | subjectName }}
  </div>
</div>

<div *ngIf="!caseOwnership.opened" class="mt-5">
  <div *ngIf="isSubjectSigned(caseOwnership.caseSubjects[0]) || (caseOwnership.ownership.isBsm && isSubjectSigned(caseOwnership.caseSubjects[1]))">
    <div *ngIf="caseOwnership.requirements.length">
      <i class="fa fa-exclamation-triangle warning-message"></i> <span class="pl-5 txt--muted">Je potřeba doplnit údaje ({{ caseOwnership.requirements.length }})</span>
    </div>
  </div>
  <div *ngIf="caseOwnership.errors.length">
    <i class="fa fa-exclamation-triangle error-message"></i> <span class="pl-5 txt--muted">Formulář obsahuje chyby ({{ caseOwnership.errors.length }})</span>
  </div>
</div>

<div *ngIf="caseOwnership.opened" class="mt-10">
  <field-validation-msg [requirements]="caseOwnership.requirements" type="requirements" field="address"></field-validation-msg>

  <div class="column-100">
    <label>Podíl</label>
    <div class="info">{{ caseOwnership.podil }}</div>

    <div *ngIf="regularPriceObligation">
      <label>Cena</label>
      <div class="info">
        <span>{{ caseOwnership.sumPrice | number | price }}</span>
        <field-validation-msg [errors]="caseOwnership.errors" type="errors" field="sjmPrice"></field-validation-msg>
      </div>
    </div>
  </div>

  <ng-container *ngIf="hasPrice && caseOwnership.ownership.isBsm">
    <div class="column-100">
      <gmt-input label="Jedna platba za podíl SJM">
        <gmt-checkbox [gmtChecked]="caseOwnership.singlePayment" (click)="caseOwnership.singlePayment = !caseOwnership.singlePayment"></gmt-checkbox>
      </gmt-input>

      <gmt-input label="Cena" *ngIf="fulfillmentPrice && caseOwnership.singlePayment" [required]="isSubjectSigned(caseOwnership.caseSubjects[0]) || isSubjectSigned(caseOwnership.caseSubjects[1])">
        <input type="text" [(ngModel)]="caseOwnership.caseSubjects[0].fulfillmentPriceSjm" gmtInputNumber/>
        <field-validation-msg [errors]="caseOwnership.errors" type="errors" field="sjmPrice"></field-validation-msg>
      </gmt-input>
    </div>

    <payment-form
      *ngIf="caseOwnership.singlePayment"
      [paymentOptions]="caseOwnership"
      [nestedForm]="true"
      [required]="isSubjectSigned(caseOwnership.caseSubjects[0]) || isSubjectSigned(caseOwnership.caseSubjects[1])"
    ></payment-form>
  </ng-container>

  <ng-container *ngFor="let caseSubject of caseOwnership.caseSubjects">
    <div *ngIf="caseOwnership.ownership.isBsm" class="column-100 bordered-top mt-10 mb-10" style="border-color: #ececec">
      <label>Jméno</label>
      <div class="info">{{ caseSubject.subject | subjectName }}</div>
    </div>

    <div class="column-100" *ngIf="(fulfillmentPrice && !caseOwnership.ownership.isBsm) || (caseOwnership.ownership.isBsm && !caseOwnership.singlePayment)">
      <gmt-input label="Cena" [required]="isSubjectSigned(caseSubject)">
        <input type="text" *ngIf="caseOwnership.ownership.isBsm" [(ngModel)]="caseSubject.fulfillmentPriceSjm" gmtInputNumber/>
        <input type="text" *ngIf="!caseOwnership.ownership.isBsm" [(ngModel)]="caseSubject.fulfillmentPrice" gmtInputNumber/>
        <field-validation-msg [errors]="caseOwnership.errors" type="errors" field="subjectPrice" [id]="caseSubject.subject.opsubId"></field-validation-msg>
      </gmt-input>
    </div>

    <div *ngIf="caseSubject.subject.opsubType === 'OPO'" class="column-100">
      <gmt-input label="IČ" [required]="!caseSubject.stat && isSubjectSigned(caseSubject)">
        <input type="text" [(ngModel)]="caseSubject.identificationNumber"/>
      </gmt-input>
    </div>

    <div *ngIf="caseSubject.subject.opsubType !== 'OPO'" class="column-100">
      <gmt-input label="Rodné číslo" [required]="!caseSubject.stat && isSubjectSigned(caseSubject)">
        <input type="text" [(ngModel)]="caseSubject.personalIdentificationNumber"/>
      </gmt-input>

      <gmt-input *ngIf="hasPrice && !caseOwnership.ownership.isBsm && !caseSubject.stat" label="Platba na základě faktury OSVČ">
        <gmt-checkbox
          gmtType="checkbox"
          [gmtChecked]="caseSubject.invoicePayment"
          (click)="caseSubject.invoicePayment = !caseSubject.invoicePayment">
        </gmt-checkbox>
      </gmt-input>

      <gmt-input *ngIf="caseSubject.invoicePayment" label="IČO (OSVČ)" [required]="caseSubject.invoicePayment">
        <input type="text" [(ngModel)]="caseSubject.identificationNumber"/>
      </gmt-input>
    </div>

    <div *ngIf="hasPrice && (!caseOwnership.ownership.isBsm || !caseOwnership.singlePayment)">
      <payment-form [paymentOptions]="caseSubject" [nestedForm]="true" [required]="isSubjectSigned(caseSubject)"></payment-form>
    </div>
  </ng-container>
</div>

<div class="cb"></div>
<div class="bordered-bottom mb-20 mt-20"></div>




