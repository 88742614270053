import { Injectable } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { RoundingPrecisionTypeEnum } from '@app/ps/enums/rounding-precision-type.enum';
import * as _ from 'lodash';
import { Decimal } from 'decimal.js';

@Injectable({
  providedIn: 'root'
})
export class RoundingService {

  constructor(
    private authService: AuthService,
  ) {
  }

  roundProject(number: Decimal) : Decimal {
    switch (this.authService.getActualProject().priceRoundingPrecisionType) {
      case RoundingPrecisionTypeEnum.PENNIES:
        return number.toDP(2);
      break;
    }

    return number.round();
  }

  round(number: number, scale: number = 0) {
    return _.round(number, scale);
  }
}
