﻿import {Component, Input, OnInit, Output, EventEmitter, OnDestroy, Inject} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { uniqueId } from 'lodash';
import { Restangular } from 'ngx-restangular';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'title-detail-create-case-summary',
  templateUrl: './create-case-summary.component.html',
})
export class CreateCaseSummaryComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  loading = true;
  @Output()
  private submitCallback = new EventEmitter();
  processSteps = [];
  errors = [];
  private processInterval;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private stateService: StateService,
    private restangular: Restangular,
  ) {
    this.submit = this.submit.bind(this);
    this.create = this.create.bind(this);
    this.showCreatedCase = this.showCreatedCase.bind(this);
  }

  ngOnInit() {
    setTimeout(() => {
      this.create();
    });
  }

  ngOnDestroy() {
    clearInterval(this.processInterval);
  }

  submit() {
    this.submitCallback.emit();
  }

  showCreatedCase() {
    this.stateService.go('symap.project.cases.detail', { id: this.data.id });
  }

  create() {
    this.loading = true;
    this.processSteps = [];
    this.errors = [];

    clearInterval(this.processInterval);

    // save note and return data to detail
    const queryParams = {loadCollections: []};
    if (this.data.occupations.length) {
      queryParams.loadCollections.push('occupations');
    }
    if (this.data.easements.length) {
      queryParams.loadCollections.push('easements');
    }
    if (this.data.buildings.length) {
      queryParams.loadCollections.push('caseBuildings');
    }

    const processId = uniqueId();

    this.data.processId = processId;

    if (this.APP_BRAND.NAME === 'RSD') {
      this.processInterval = setInterval(() => {
        this.restangular.all('cases/processes/' + processId).customGET().toPromise().then(data => {
          this.processSteps = data.steps;
        });
      }, 700);
    }

    return this.restangular.all('cases/create').post(this.data, queryParams).toPromise().then((data) => {
      setTimeout(() => {
        this.loading = false;
        Object.assign(this.data, data);
      }, 500);
    }, (e) => {
      setTimeout(() => {
        this.loading = false;
        this.errors.push(e);
      }, 500);
    });
  }
}
