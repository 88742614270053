<create-case-status visibleByBrand="mpv" [isLoading]="loading" [processSteps]="processSteps" [errors]="errors"></create-case-status>

<div visibleByBrand="maja" [loading]="loading"></div>

<ng-container *ngIf="!loading">
  <ng-container *ngIf="data.id">
    <div class="jumbotron centered-text">
      Případ <a uiSref="symap.project.cases.detail" [uiParams]="{ id: data.id }">{{ data | caseName }}</a> byl založen.
    </div>
    <div class="mt-20">
      <gmt-button [clickAction]="submit">{{data.fromCase ? 'Návrat na případ' : 'Návrat na LV'}}</gmt-button>
      <gmt-button [clickAction]="showCreatedCase">Zobrazit případ</gmt-button>
    </div>
  </ng-container>

  <ng-container *ngIf="!data.id">
    <div class="jumbotron centered-text">
      Případ se nepodařilo založit.
    </div>

    <div class="mt-20">
      <gmt-button [clickAction]="submit">{{data.fromCase ? 'Návrat na případ' : 'Návrat na LV'}}</gmt-button>
      <gmt-button [clickAction]="create">Zkusit znovu</gmt-button>
    </div>
  </ng-container>
</ng-container>
