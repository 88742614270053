<div class="flex-centered modal-container"
    (keydown)="onEscape($event)"
    (click)="onOverlayClicked($event)">
    <div class="modal {{ className }}" tabindex="0">
        <a *ngIf="className !== 'unclosable-dialog'" class="close fa fa-times" (click)="onClosed($event)"></a>
        <div class="modal-content">
            <perfect-scrollbar (callbackRegister)="onScrollbarRegister($event.updateScrollbar)">
                <ng-template appInsertion> </ng-template>
            </perfect-scrollbar>
        </div>
    </div>
</div>

