<div class="jumbotron">
  <div [loading]="isLoading"></div>

  <ng-container *ngIf="processSteps?.length">
    <div class="centered-text"><b>Detailní průběh:</b></div>

    <div class="centered-text mt-5" *ngFor="let step of processSteps">
      <span *ngIf="step.type === stepEnum.HELIOS_NUMBER_OBTAINING">Kontaktování HELIOS pro poskytnutí čísla smluvního případu</span>
      <span *ngIf="step.type === stepEnum.HELIOS_NUMBER_OBTAINED">Přijetí čísla smluvního případu HELIOS</span>
      <span *ngIf="step.type === stepEnum.CASE_CREATED">Případ je založen</span>
    </div>
  </ng-container>

  <div class="centered-text error-message" *ngFor="let error of errors">
    <ng-container *ngIf="error.status === 400 && error.data.code === 0; else commonError">
      {{ error.data.message }}
    </ng-container>

    <ng-template #commonError>
      {{ error.status === 403 ? 'Akce není povolena' : 'Vyskytla se neočekávaná chyba' }}
    </ng-template>
  </div>
</div>
