import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { CaseOwnershipModel } from '@app/ps/models/case-ownership.model';
import { StringUtils } from '@app/common/utils/string.utils';
import { CaseSubjectModel } from '@app/ps/models/case-subject.model';

@Component({
  selector: 'sign-owner-validation',
  templateUrl: './sign-owner-validation.component.html',
  styleUrls: ['./sign-owner-validation.component.scss']
})
export class SignOwnerValidationComponent implements OnInit, DoCheck {
  @Input() caseOwnership: CaseOwnershipModel;
  @Input() isSubjectSigned: Function;
  @Input() fulfillmentPrice = 0;
  @Input() regularPriceObligation = false;
  hasPrice = false;

  constructor() {
  }

  ngOnInit() {
    this.hasPrice = this.regularPriceObligation || !!this.fulfillmentPrice;

    // fix ico
    this.caseOwnership.caseSubjects.forEach(cs => {
      if (!cs.identificationNumber && cs.subject.ico) {
        cs.identificationNumber = StringUtils.valueToString(cs.subject.ico);
      }
    });
    this.onValidation();
  }

  ngDoCheck() {
    this.onValidation();
  }

  priceValidation() {
    if (!this.hasPrice) {
      return;
    }

    if (this.regularPriceObligation) {
      if (this.caseOwnership.ownership.isBsm) {
        if (!this.caseOwnership.singlePayment) {
          if (StringUtils.isEmpty(this.caseOwnership.caseSubjects[0].fulfillmentPriceSjm)) {
            this.caseOwnership.requirements.push({field: 'subjectPrice', msg: 'Cena u prvního z manželů musí být vyplněna'});
          } else if (StringUtils.isEmpty(this.caseOwnership.caseSubjects[1].fulfillmentPriceSjm)) {
            this.caseOwnership.requirements.push({field: 'subjectPrice', msg: 'Cena u druhého z manželů musí být vyplněna'});
          } else if (this.caseOwnership.caseSubjects[0].fulfillmentPriceSjm + this.caseOwnership.caseSubjects[1].fulfillmentPriceSjm !== this.caseOwnership.sumPrice) {
            this.caseOwnership.errors.push({field: 'sjmPrice', msg: 'Dílčí ceny neodpovídají celkové ceně'});
          }
        }
      }
    } else if (this.fulfillmentPrice) {
      if (this.caseOwnership.ownership.isBsm) {
        if (this.caseOwnership.singlePayment) {
          if (StringUtils.isEmpty(this.caseOwnership.caseSubjects[0].fulfillmentPriceSjm)) {
            this.caseOwnership.requirements.push({field: 'sjmPrice', msg: 'Cena musí být vyplněna'});
          } else if (this.caseOwnership.caseSubjects[0].fulfillmentPriceSjm > this.fulfillmentPrice) {
            this.caseOwnership.errors.push({field: 'sjmPrice', msg: 'Cena nemůže být větší než cena smlouvy'});
          }
        } else {
          if (StringUtils.isEmpty(this.caseOwnership.caseSubjects[0].fulfillmentPriceSjm)) {
            this.caseOwnership.requirements.push({field: 'subjectPrice', msg: 'Cena u prvního z manželů musí být vyplněna'});
          } else if (this.caseOwnership.caseSubjects[0].fulfillmentPriceSjm > this.fulfillmentPrice) {
            this.caseOwnership.errors.push({field: 'subjectPrice', msg: 'Cena u prvního z manželů nemůže být větší než cena smlouvy', id: this.caseOwnership.caseSubjects[0].subject.opsubId});
          }

          if (StringUtils.isEmpty(this.caseOwnership.caseSubjects[1].fulfillmentPriceSjm)) {
            this.caseOwnership.requirements.push({field: 'subjectPrice', msg: 'Cena u druhého z manželů musí být vyplněna'});
          } else if (this.caseOwnership.caseSubjects[1].fulfillmentPriceSjm > this.fulfillmentPrice) {
            this.caseOwnership.errors.push({field: 'subjectPrice', msg: 'Cena u druhého z manželů nemůže být větší než cena smlouvy', id: this.caseOwnership.caseSubjects[1].subject.opsubId});
          }
        }
      } else {
        if (StringUtils.isEmpty(this.caseOwnership.caseSubjects[0].fulfillmentPrice)) {
          this.caseOwnership.requirements.push({field: 'subjectPrice', msg: 'Cena musí být vyplněna'});
        } else if (this.caseOwnership.caseSubjects[0].fulfillmentPrice > this.fulfillmentPrice) {
          this.caseOwnership.errors.push({field: 'subjectPrice', msg: 'Cena nemůže být větší než cena smlouvy', id: this.caseOwnership.caseSubjects[0].subject.opsubId});
        }
      }
    }
  }

  subjectValidation(caseSubject: CaseSubjectModel) {
    const czSubject = (
      !caseSubject.stat
      || caseSubject.stat === 'Česká republika'
      || caseSubject.stat === 'ČR'
      || caseSubject.stat === 'CZ'
      || (caseSubject.kodStatuAl2 && caseSubject.kodStatuAl2 === 'CZ')
    );

    if (caseSubject.subject.opsubType === 'OPO') {
      if (!caseSubject.identificationNumber && czSubject) {
        this.caseOwnership.requirements.push({field: 'ico', msg: 'IČO musí být vyplněno'});
      }
    } else {
      if (!caseSubject.personalIdentificationNumber && czSubject) {
        this.caseOwnership.requirements.push({field: 'rc', msg: 'RČ musí být vyplněno'});
      }

      if (caseSubject.invoicePayment && !caseSubject.identificationNumber) {
        this.caseOwnership.requirements.push({field: 'ico', msg: 'IČO musí být vyplněno'});
      }
    }

    const address = [];

    if (!caseSubject.cisloDomovni) {
      address.push('číslo domovní');
    }

    if (!caseSubject.obec) {
      address.push('obec');
    }

    if (!caseSubject.psc) {
      address.push('PSČ');
    }

    if (!czSubject && !caseSubject.kodStatuAl2) {
      address.push('dvojmístný kód státu');
    }

    if (address.length) {
      this.caseOwnership.requirements.push({field: 'address', msg: 'Nedostatečně vyplněná adresa (' + address.join(', ') + ')' });
    }
  }

  paymentValidation(obj: CaseSubjectModel | CaseOwnershipModel) {
    if (!this.hasPrice) {
      return;
    }

    if (StringUtils.isEmpty(obj.paymentType) || obj.paymentType === 'X') {
      this.caseOwnership.requirements.push({field: 'payment', msg: 'Platba musí být nastavena'});
    } else if (obj.paymentType === 'B') {
      if (!obj.accountNumber) {
        this.caseOwnership.requirements.push({field: 'account', msg: 'Platba musí být nastavena'});
      }
      if (!obj.bankCode) {
        this.caseOwnership.requirements.push({field: 'bank', msg: 'Platba musí být nastavena'});
      }
    }
  }

  onValidation() {
    this.caseOwnership.requirements = [];
    this.caseOwnership.errors = [];

    this.priceValidation();

    if (this.caseOwnership.ownership.isBsm && this.caseOwnership.singlePayment) {
      this.paymentValidation(this.caseOwnership);
    }

    for (const caseSubject of this.caseOwnership.caseSubjects) {
      this.subjectValidation(caseSubject);
      if (!this.caseOwnership.ownership.isBsm || (this.caseOwnership.ownership.isBsm && !this.caseOwnership.singlePayment)) {
        this.paymentValidation(caseSubject);
      }
    }
  }
}
