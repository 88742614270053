import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { HttpClientModule } from '@angular/common/http';

import { CommonModule } from './../common/common.module';

import { MainComponent } from './components/main/main.component';
import { UserCredentialsComponent } from './../common/components/user-credentials/user-credentials.component';
import { ValidationComponent } from './components/validation/validation.component';
import { GenerateComponent } from '@app/vfze/components/generate/generate.component';
import { ImportComponent } from '@app/vfze/components/import/import.component';

import { ProjectNamePipe } from '@app/vfze/pipes/project-name.pipe';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { MapComponent } from '@app/vfze/components/map/map.component';
import { Transition } from '@uirouter/core';
import { IndexedDbStorageService } from '@app/common/services/indexed-db-storage.service';

registerLocaleData(localeCs, 'cs');

export function onExitModule(transition: Transition) {
  const indexedDbStorage: IndexedDbStorageService = transition.injector().get(IndexedDbStorageService);
  indexedDbStorage.remove('vfze');
}

export const vfzeState: Ng2StateDeclaration = {
  name: 'vfze',
  url: '/vfze',
  abstract: true,
  component: MainComponent,
  onExit: onExitModule
};

export const vfzeValidatorState: Ng2StateDeclaration = {
  name: 'vfze.validation',
  url: '/validation',
  data: { title: 'Validátor VFZE' },
  views: {
    'content@vfze': {
      component: ValidationComponent
    }
  }
};

export const vfzeGenerateState: Ng2StateDeclaration = {
  name: 'vfze.generate',
  url: '/generate',
  data: { title: 'Generování VFZE' },
  views: {
    'content@vfze': {
      component: GenerateComponent
    }
  }
};

export const importGenerateState: Ng2StateDeclaration = {
  name: 'vfze.import',
  url: '/import',
  data: { title: 'Import VFZE' },
  views: {
    'content@vfze': {
      component: ImportComponent
    }
  }
};

export const vfzeSettingsState: Ng2StateDeclaration = {
  name: 'vfze.settings',
  url: '/settings',
  data: {
    title: 'Nastavení',
  },
  views: {
    'content@vfze': {
      component: UserCredentialsComponent,
    }
  },
  params: {
    backSref: {
      type: 'string',
      value: 'vfze.validation'
    },
  }
};

export const vfzeMapState: Ng2StateDeclaration = {
  name: 'vfze.map',
  url: '/nahled',
  data: { title: 'VFZE náhled' },
  views: {
    'content@vfze': {
      component: MapComponent
    }
  },
};

@NgModule({
  imports: [
    NgbModule,
    HttpClientModule,
    CommonModule,
    UIRouterModule.forChild({
      states: [
        vfzeState,
        vfzeValidatorState,
        vfzeSettingsState,
        vfzeGenerateState,
        importGenerateState,
        vfzeMapState,
      ]
    }),
  ],
  providers: [
  ],
  declarations: [
    MainComponent,
    ValidationComponent,
    GenerateComponent,
    ImportComponent,
    MapComponent,

    ProjectNamePipe,
  ],
  entryComponents: [
  ]
})


export class VfzeAppModule {}
