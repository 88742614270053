import { NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader, LOCALE_ID, Inject } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';

import { LoginComponent } from './components/login/login.component';
import { MenuComponent } from './components/menu/menu.component';
import { FileUploadComponent } from './components/fileupload/fileupload.component';
import { ButtonComponent } from './components/button/button.component';
import { SortByComponent } from './components/sortby/sortby.component';
import { HelpComponent } from './components/help/help.component';
import { FilterComponent } from './components/filter/filter.component';
import { PagesCounterComponent } from './components/pagescounter/pagescounter.component';
import { SelectComponent } from './components/select/select.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ItemsCounterComponent } from './components/itemscounter/itemscounter.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { PerfectScrollbarComponent } from './components/perfectscrollbar/perfectscrollbar.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { RadioComponent } from './components/radio/radio.component';
import { PickADateComponent } from './components/pickadate/pickadate.component';
import { TooltipOwnershipsComponent } from './components/tooltip-ownerships/tooltip-ownerships.component';
import { CountOwnershipsComponent } from './components/count-ownerships/count-ownerships.component';
import { TooltipCasesComponent } from './components/tooltip-cases/tooltip-cases.component';
import { UserCredentialsComponent } from './components/user-credentials/user-credentials.component';
import { CellOwnershipComponent } from './components/cell-ownership/cell-ownership.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { TabbedBlockComponent } from './components/tabbed-block/tabbed-block.component';
import { CellParcelComponent } from './components/cell-parcel/cell-parcel.component';
import { TooltipParcelComponent } from './components/tooltip-parcel/tooltip-parcel.component';
import { CellCasesComponent } from './components/cell-cases/cell-cases.component';
import { CellOccupationComponent } from './components/cell-occupation/cell-occupation.component';
import { LabeledBlockComponent } from './components/labeled-block/labeled-block.component';
import { CellPriceComponent } from './components/cell-price/cell-price.component';
import { TooltipEasementPriceComponent } from './components/tooltip-easement-price/tooltip-easement-price.component';
import { TooltipOccupationPriceComponent } from './components/tooltip-occupation-price/tooltip-occupation-price.component';
import { InputComponent } from './components/input/input.component';
import { PostCodePipe } from './pipes/post-code.pipe';
import { SwitchComponent } from './components/switch/switch.component';
import { TooltipSystemMetadataComponent } from './components/tooltip-system-metadata/tooltip-system-metadata.component';
import { TooltipParcelsComponent } from './components/tooltip-parcels/tooltip-parcels.component';
import { ProgressBarComponent } from '@app/common/components/progress-bar/progress-bar.component';
import { TrendComponent } from '@app/common/components/trend/trend.component';
import { ProjectSelectComponent } from '@app/common/components/project-select/project-select.component';
import { EmailFormComponent } from '@app/common/components/email-form/email-form.component';
import { TooltipDisabledOccupationComponent } from '@app/common/components/tooltip-disabled-occupation/tooltip-disabled-occupation.component';
import { CellParcelsComponent } from '@app/common/components/cell-parcels/cell-parcels.component';
import { TooltipParcelsBaseComponent } from '@app/common/components/tooltip-parcels-base/tooltip-parcels-base.component';
import { NotificationComponent } from './components/notification/notification.component';
import { MirroredListComponent } from './components/mirrored-list/mirrored-list.component';
import { UserPermissionsComponent } from './components/user-permissions/user-permissions.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { VfzeProblemsComponent } from './components/vfze-problems/vfze-problems.component';
import { TooltipVfzeProblemsComponent } from './components/tooltip-vfze-problems/tooltip-vfze-problems.component';
import { UserEditComponent } from '@app/common/components/user-edit/user-edit.component';
import { ChangelogComponent } from '@app/common/components/changelog/changelog.component';
import { TooltipParcelPricesComponent } from '@app/common/components/tooltip-parcel-prices/tooltip-parcel-prices.component';
import { ArticlesComponent } from '@app/common/components/articles/articles.component';
import { ArticleEditComponent } from '@app/common/components/article-edit/article-edit.component';
import { ArticleAttachmentComponent } from '@app/common/components/article-attachment/article-attachment.component';
import { DocumentsComponent } from '@app/common/components/documents/documents.component';
import { DocumentEditFormComponent } from '@app/common/components/document-edit-form/document-edit-form.component';
import { DocumentChooseTypeFormComponent } from '@app/common/components/document-choose-type-form/document-choose-type-form.component';
import { NoteFormComponent } from '@app/common/components/note-form/note-form.component';
import { NotesComponent } from '@app/common/components/notes/notes.component';
import { SimpleTextModalComponent } from '@app/common/components/simple-text-modal/simple-text-modal.component';
import { TermsOfUseComponent } from '@app/common/components/terms-of-use/terms-of-use.component';
import { PieChartComponent } from '@app/common/components/pie-chart/pie-chart.component';
import { TooltipDimapParcelComponent } from '@app/common/components/tooltip-dimap-parcel/tooltip-dimap-parcel.component';
import { TooltipDimapOwnershipsComponent } from '@app/common/components/tooltip-dimap-ownerships/tooltip-dimap-ownerships.component';
import { TooltipConstructionObjectsComponent } from '@app/common/components/tooltip-construction-objects/tooltip-construction-objects.component';
import { CellConstructionObjectsComponent } from '@app/common/components/cell-construction-objects/cell-construction-objects.component';
import { HelpEditComponent } from '@app/common/components/help-edit/help-edit.component';
import { ChangePasswordComponent } from '@app/common/components/change-password/change-password.component';
import { ConstructionObjectListComponent } from '@app/common/components/construction-object-list/construction-object-list.component';
import { TooltipDimapParcelsComponent } from '@app/common/components/tooltip-dimap-parcels/tooltip-dimap-parcels.component';
import { ActionButtonsComponent } from '@app/common/components/action-buttons/action-buttons.component';
import { TooltipEasementsComponent } from '@app/common/components/tooltip-easements/tooltip-easements.component';

import { LoadingDirective } from './directives/loading.directive';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { TooltipContentDirective } from './directives/tooltip/tooltipContent.directive';
import { TooltipHostDirective } from './directives/tooltip/tooltipHost.directive';
import { ApplicationsHostDirective } from './directives/applications/applicationsHost.directive';
import { PickADateDirective } from './directives/pickadate/pickadate.directive';
import { AuthDirective } from './directives/auth.directive';
import { AuthNotDirective } from './directives/auth-not.directive';
import { AuthProjectDirective } from '@app/common/directives/auth-project.directive';
import { DraggableDirective } from './directives/draggable.directive';
import { InputNumberDirective } from '@app/common/directives/input-number.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { BrandVisibilityDirective } from '@app/common/directives/brand-visibility.directive';
import { InputPostCodeDirective } from '@app/common/directives/input-post-code.directive';
import { ApproveTermsComponent } from '@app/common/components/approve-terms/approve-terms.component';

import { UserNamePipe } from './pipes/username.pipe';
import { SubjectNamePipe } from './pipes/subject-name.pipe';
import { OwnershipNamePipe } from './pipes/ownership-name.pipe';
import { TitleAreaNamesPipe } from './pipes/title-area-names.pipe';
import { TitleNamesPipe } from './pipes/title-names.pipe';
import { TitleUserUniqueNamesPipe } from './pipes/title-user-unique-names.pipe';
import { PricePipe } from './pipes/price.pipe';
import { OccupationCategoryNamePipe } from './pipes/occupation-category-name.pipe';
import { TitleNamePipe } from './pipes/title-name.pipe';
import { ParcelProtectionPipe } from './pipes/parcel-protection.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { UrlHighlightPipe } from './pipes/url-highlight.pipe';
import { WordTranslatePipe } from './pipes/word-translate.pipe';
import { ParcelNumberPipe } from './pipes/parcel-number.pipe';
import { DaysDiffPipe } from './pipes/days-diff.pipe';
import { DescriptionPipe } from './pipes/description.pipe';
import { CustomerNamePipe } from './pipes/customer-name.pipe';
import { CustomerContractNamePipe } from './pipes/customer-contract-name.pipe';
import { PersonalNumberPipe } from './pipes/personal-number.pipe';
import { NumericPipe } from '@app/common/pipes/numeric.pipe';
import { DecimalPipe } from '@app/common/pipes/decimal.pipe';
import { ParcelNamePipe } from '@app/common/pipes/parcel-name.pipe';
import { FullStreetPipe } from '@app/common/pipes/full-street.pipe';
import { RentalTypePipe } from '@app/common/pipes/rental-type.pipe';
import { FullMunicipalityPipe } from '@app/common/pipes/full-municipality.pipe';
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';
import { SafeHtmlPipe } from '@app/common/pipes/safe-html.pipe';
import { DocumentTypePipe } from '@app/common/pipes/document-type.pipe';
import { RolePipe } from '@app/common/pipes/role.pipe';
import { PercentagePipe } from '@app/common/pipes/percentage.pipe';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { TemplateOrganizationalUnitNamePipe } from '@app/common/pipes/template-organizational-unit-name.pipe';
import { BuildingNamePipe } from '@app/common/pipes/building-name.pipe';
import { ProjectNamePipe } from '@app/common/pipes/project-name.pipe';
import { ProjectTypeNamePipe } from '@app/common/pipes/project-type-name.pipe';
import { FullAddressPipe } from '@app/common/pipes/full-address.pipe';
import { ApplicationsPipe } from '@app/common/pipes/applications.pipe';
import { AddFieldPipe } from '@app/common/pipes/add-field.pipe';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { BuildingNumberPipe } from "@app/common/pipes/building-number.pipe";
import { DocumentTypeFullnamePipe } from "@app/common/pipes/document-type-fullname.pipe";

import { APP_BRAND, APP_CONFIG, APPLICATIONS, APPLICATIONS_SRC } from './services/config.service';
import { RESTANGULAR_GLOBAL, RestangularGlobalFactory } from './services/restangular-global.service';
import { RESTANGULAR_PROMAP, RestangularPromapFactory } from './services/restangular-promap.service';
import { RESTANGULAR_SYMAP, RestangularSymapFactory } from './services/restangular-symap.service';
import { RESTANGULAR_VFZE, RestangularVfzeFactory } from './services/restangular-vfze.service';
import { RESTANGULAR_SV, RestangularSVFactory } from './services/restangular-sv.service';
import { RESTANGULAR_PROMAP_PROXY, RestangularPromapProxyFactory } from '@app/common/services/restangular-promap-proxy.service';
import { RESTANGULAR_DIMAP, RestangularDimapFactory } from '@app/common/services/restangular-dimap.service';
import { RESTANGULAR_SETTINGS, RestangularSettingsFactory } from '@app/common/services/restangular-settings.service';
import { RESTANGULAR_CONFIGURATION, RestangularConfigurationFactory } from '@app/common/services/restangular-configuration.service';
import { Restangular, RestangularModule } from 'ngx-restangular';
import { transformListToFilter } from '@app/common/utils/list.utils';
import { LocalStorageModule } from 'angular-2-local-storage';
import { UIRouterModule } from '@uirouter/angular';
import { DialogComponent } from '@app/common/components/dialog/dialog.component';
import { InsertionDirective } from '@app/common/directives/insertion.directive';
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import { AlertComponent } from '@app/common/components/alert/alert.component';
import { HttpService } from '@app/common/services/http.service';


export function RestangularConfigFactory(RestangularProvider) {
  RestangularProvider.addResponseInterceptor((data, operation, path) => {
    let newData;

    // modify structure for all lists loaded from backend to behave like Array extended by itemCount attribute
    if (operation === 'getList' || (operation === 'post' && data && data.itemCount !== undefined && data.items !== undefined)) {
      newData = data.items || data;
      newData.itemCount = data.itemCount;
    } else if (typeof data === 'number' || typeof data === 'string') {
      newData = { data };
    } else {
      newData = data;
    }

    return newData;
  });

  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
    HttpService.fixData(element);
    HttpService.fixData(params);

    if (element && element.filter) {
      element.filter = transformListToFilter(element.filter);
    }

    if (operation === 'remove') {
      element = null;
    }

    return {
      params: params,
      headers: headers,
      element: element
    };
  });
}

registerLocaleData(localeCs, 'cs');

@NgModule({
  imports: [
    UIRouterModule.forChild(),
    BrowserModule,
    FormsModule,
    RestangularModule.forRoot(RestangularConfigFactory),
    LocalStorageModule.forRoot({
      prefix: 'ls',
      storageType: 'localStorage'
    }),
  ],
  providers: [
    {provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader},
    {provide: LOCALE_ID, useValue: 'cs'},
    DatePipe,
    {
      provide: APP_CONFIG,
      useFactory: () => {
        return (window as any).SYMAP_CONFIG;
      }
    },
    {
      provide: APP_BRAND,
      useFactory: (config) => {
        return config.APP_BRAND;
      },
      deps: [APP_CONFIG],
    },
    {
      provide: APPLICATIONS,
      useFactory: (brand) => {
        const apps = APPLICATIONS_SRC;
        apps.sy.title = brand.PS.TITLE;
        return apps;
      },
      deps: [APP_BRAND],
    },
    {
      provide: RESTANGULAR_GLOBAL,
      useFactory: RestangularGlobalFactory,
      deps: [Restangular, APP_CONFIG],
    },
    {
      provide: RESTANGULAR_PROMAP,
      useFactory: RestangularPromapFactory,
      deps: [Restangular, APP_CONFIG],
    },
    {
      provide: RESTANGULAR_PROMAP_PROXY,
      useFactory: RestangularPromapProxyFactory,
      deps: [Restangular, APP_CONFIG],
    },
    {
      provide: RESTANGULAR_SYMAP,
      useFactory: RestangularSymapFactory,
      deps: [Restangular, APP_CONFIG],
    },
    {
      provide: RESTANGULAR_DIMAP,
      useFactory: RestangularDimapFactory,
      deps: [Restangular, APP_CONFIG],
    },
    {
      provide: RESTANGULAR_VFZE,
      useFactory: RestangularVfzeFactory,
      deps: [Restangular, APP_CONFIG],
    },
    {
      provide: RESTANGULAR_SV,
      useFactory: RestangularSVFactory,
      deps: [Restangular, APP_CONFIG],
    },
    {
      provide: RESTANGULAR_SETTINGS,
      useFactory: RestangularSettingsFactory,
      deps: [Restangular, APP_CONFIG],
    },
    {
      provide: RESTANGULAR_CONFIGURATION,
      useFactory: RestangularConfigurationFactory,
      deps: [Restangular, APP_CONFIG],
    },
  ],
  declarations: [
    LoginComponent,
    SortByComponent,
    MenuComponent,
    FileUploadComponent,
    ButtonComponent,
    HelpComponent,
    FilterComponent,
    PagesCounterComponent,
    SelectComponent,
    PaginationComponent,
    ItemsCounterComponent,
    TooltipComponent,
    ApplicationsComponent,
    PerfectScrollbarComponent,
    CheckboxComponent,
    RadioComponent,
    PickADateComponent,
    TooltipOwnershipsComponent,
    TooltipDimapOwnershipsComponent,
    CountOwnershipsComponent,
    TooltipCasesComponent,
    UserCredentialsComponent,
    CellOwnershipComponent,
    NavbarComponent,
    BackButtonComponent,
    TabbedBlockComponent,
    CellParcelComponent,
    TooltipParcelComponent,
    TooltipDimapParcelComponent,
    CellCasesComponent,
    CellOccupationComponent,
    CellPriceComponent,
    TooltipEasementPriceComponent,
    TooltipOccupationPriceComponent,
    MonthPickerComponent,
    ProgressBarComponent,
    TrendComponent,
    ProjectSelectComponent,
    EmailFormComponent,
    TooltipDisabledOccupationComponent,
    CellParcelsComponent,
    TooltipParcelsBaseComponent,
    SwitchComponent,
    TooltipSystemMetadataComponent,
    TooltipParcelsComponent,
    TooltipEasementsComponent,
    NotificationComponent,
    AutofocusDirective,
    MirroredListComponent,
    UserPermissionsComponent,
    BackButtonComponent,
    InputComponent,
    LabeledBlockComponent,
    UserListComponent,
    UserDetailComponent,
    UserEditComponent,
    VfzeProblemsComponent,
    TooltipVfzeProblemsComponent,
    ChangelogComponent,
    TooltipParcelPricesComponent,
    ArticlesComponent,
    ArticleEditComponent,
    ArticleAttachmentComponent,
    DocumentsComponent,
    DocumentEditFormComponent,
    DocumentChooseTypeFormComponent,
    NotesComponent,
    NoteFormComponent,
    SimpleTextModalComponent,
    TermsOfUseComponent,
    PieChartComponent,
    TooltipConstructionObjectsComponent,
    CellConstructionObjectsComponent,
    ConstructionObjectListComponent,
    TooltipDimapParcelsComponent,
    HelpEditComponent,
    ApproveTermsComponent,
    ChangePasswordComponent,
    DialogComponent,
    ConfirmationComponent,
    AlertComponent,
    ActionButtonsComponent,

    LoadingDirective,
    TooltipDirective,
    TooltipHostDirective,
    TooltipContentDirective,
    ApplicationsHostDirective,
    PickADateDirective,
    AuthDirective,
    AuthNotDirective,
    AuthProjectDirective,
    DraggableDirective,
    InputNumberDirective,
    DraggableDirective,
    BrandVisibilityDirective,
    InputPostCodeDirective,
    InsertionDirective,

    UserNamePipe,
    SubjectNamePipe,
    OwnershipNamePipe,
    TitleAreaNamesPipe,
    TitleNamesPipe,
    TitleUserUniqueNamesPipe,
    PricePipe,
    TitleNamePipe,
    CustomerNamePipe,
    CustomerContractNamePipe,
    PersonalNumberPipe,
    NumericPipe,
    DecimalPipe,
    ParcelNamePipe,
    FullStreetPipe,
    RentalTypePipe,
    AddressPipe,
    UrlHighlightPipe,
    WordTranslatePipe,
    ParcelNumberPipe,
    DaysDiffPipe,
    DescriptionPipe,
    OccupationCategoryNamePipe,
    ParcelProtectionPipe,
    PostCodePipe,
    FullMunicipalityPipe,
    BuildingNamePipe,
    BrandTranslatePipe,
    ProjectNamePipe,
    ProjectTypeNamePipe,
    SafeHtmlPipe,
    DocumentTypePipe,
    RolePipe,
    PercentagePipe,
    OrganizationalUnitNamePipe,
    TemplateOrganizationalUnitNamePipe,
    AddFieldPipe,
    FullAddressPipe,
    ApplicationsPipe,
    ConstructionObjectNamePipe,
    BuildingNumberPipe,
    DocumentTypeFullnamePipe,
  ],
  entryComponents: [
    MenuComponent,
    TooltipComponent,
    ApplicationsComponent,
    PickADateComponent,
    TooltipSystemMetadataComponent,
    TooltipOwnershipsComponent,
    TooltipDimapOwnershipsComponent,
    TooltipCasesComponent,
    TooltipParcelComponent,
    TooltipDimapParcelComponent,
    TooltipEasementPriceComponent,
    TooltipOccupationPriceComponent,
    TooltipParcelsComponent,
    TooltipEasementsComponent,
    UserPermissionsComponent,
    TooltipDisabledOccupationComponent,
    TooltipParcelsBaseComponent,
    UserEditComponent,
    TooltipVfzeProblemsComponent,
    ChangelogComponent,
    TooltipParcelPricesComponent,
    ArticleEditComponent,
    ArticleAttachmentComponent,
    DocumentEditFormComponent,
    DocumentChooseTypeFormComponent,
    NotesComponent,
    NoteFormComponent,
    SimpleTextModalComponent,
    TooltipConstructionObjectsComponent,
    CellConstructionObjectsComponent,
    TooltipDimapParcelsComponent,
    HelpEditComponent,
    ApproveTermsComponent,
    ChangePasswordComponent,
    DialogComponent,
    ConfirmationComponent,
    AlertComponent,

    // for downgrade
    ConstructionObjectListComponent,
    PerfectScrollbarComponent,
    MonthPickerComponent,
    CellOwnershipComponent,
    ProjectSelectComponent,
    LoginComponent,
    EmailFormComponent,
    LabeledBlockComponent,
    CellCasesComponent,
    CellParcelComponent,
    CellOccupationComponent,
    DocumentsComponent,
    CheckboxComponent,
  ],
  exports: [
    BrowserModule,
    FormsModule,

    LoginComponent,
    SortByComponent,
    MenuComponent,
    FileUploadComponent,
    ButtonComponent,
    HelpComponent,
    FilterComponent,
    PagesCounterComponent,
    SelectComponent,
    PaginationComponent,
    ItemsCounterComponent,
    TooltipComponent,
    ApplicationsComponent,
    PerfectScrollbarComponent,
    CheckboxComponent,
    RadioComponent,
    PickADateComponent,
    TooltipOwnershipsComponent,
    TooltipDimapOwnershipsComponent,
    CountOwnershipsComponent,
    TooltipCasesComponent,
    UserCredentialsComponent,
    CellOwnershipComponent,
    LabeledBlockComponent,
    NavbarComponent,
    BackButtonComponent,
    TabbedBlockComponent,
    CellParcelComponent,
    TooltipParcelComponent,
    TooltipDimapParcelComponent,
    CellCasesComponent,
    CellOccupationComponent,
    CellPriceComponent,
    TooltipEasementPriceComponent,
    TooltipOccupationPriceComponent,
    InputComponent,
    SwitchComponent,
    MonthPickerComponent,
    TooltipParcelsComponent,
    TooltipEasementsComponent,
    ProgressBarComponent,
    TrendComponent,
    NotificationComponent,
    ProjectSelectComponent,
    EmailFormComponent,
    TooltipDisabledOccupationComponent,
    CellParcelsComponent,
    TooltipParcelsBaseComponent,
    UserListComponent,
    UserDetailComponent,
    UserEditComponent,
    TooltipVfzeProblemsComponent,
    VfzeProblemsComponent,
    ChangelogComponent,
    TooltipParcelPricesComponent,
    ArticlesComponent,
    ArticleEditComponent,
    ArticleAttachmentComponent,
    DocumentsComponent,
    DocumentEditFormComponent,
    DocumentChooseTypeFormComponent,
    NotesComponent,
    NoteFormComponent,
    SimpleTextModalComponent,
    TermsOfUseComponent,
    PieChartComponent,
    TooltipConstructionObjectsComponent,
    CellConstructionObjectsComponent,
    ConstructionObjectListComponent,
    TooltipDimapParcelsComponent,
    HelpEditComponent,
    ApproveTermsComponent,
    ChangePasswordComponent,
    DialogComponent,
    ConfirmationComponent,
    AlertComponent,
    ActionButtonsComponent,
    MirroredListComponent,

    LoadingDirective,
    TooltipDirective,
    TooltipHostDirective,
    TooltipContentDirective,
    ApplicationsHostDirective,
    PickADateDirective,
    AuthDirective,
    AuthNotDirective,
    AuthProjectDirective,
    DraggableDirective,
    InputNumberDirective,
    AutofocusDirective,
    BrandVisibilityDirective,
    InputPostCodeDirective,

    UserNamePipe,
    SubjectNamePipe,
    OwnershipNamePipe,
    TitleAreaNamesPipe,
    TitleNamesPipe,
    TitleUserUniqueNamesPipe,
    PricePipe,
    BackButtonComponent,
    AddressPipe,
    UrlHighlightPipe,
    WordTranslatePipe,
    ParcelNumberPipe,
    DaysDiffPipe,
    DescriptionPipe,
    OccupationCategoryNamePipe,
    ParcelProtectionPipe,
    PostCodePipe,
    TitleNamePipe,
    CustomerNamePipe,
    CustomerContractNamePipe,
    PersonalNumberPipe,
    NumericPipe,
    DecimalPipe,
    ParcelNamePipe,
    FullStreetPipe,
    RentalTypePipe,
    FullMunicipalityPipe,
    BuildingNamePipe,
    BrandTranslatePipe,
    ProjectNamePipe,
    ProjectTypeNamePipe,
    SafeHtmlPipe,
    DocumentTypePipe,
    RolePipe,
    PercentagePipe,
    OrganizationalUnitNamePipe,
    TemplateOrganizationalUnitNamePipe,
    AddFieldPipe,
    FullAddressPipe,
    ApplicationsPipe,
    ConstructionObjectNamePipe,
    BuildingNumberPipe,
    DocumentTypeFullnamePipe,
  ],
})
export class CommonModule {
  constructor() {}
}
