<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset *ngIf="data.opinion.expertOpinionLand">
      <legend>Způsob zadání ceny za pozemek</legend>
      <gmt-switch *ngIf="!data.readonly" [options]="pricingMethodOptions" [(selectedId)]="this.data.opinion.expertOpinionPricingMethod" (changedAfter)="onMethodChange()"></gmt-switch>
      <div *ngIf="data.readonly">
        <span *ngIf="data.opinion.expertOpinionPricingMethod === 'M'">Cena za m<sup>2</sup> pozemku</span>
        <span *ngIf="data.opinion.expertOpinionPricingMethod === 'A'">Cena za pozemek</span>
      </div>
    </fieldset>

    <h2>Zadání ceny ze znaleckého posudku</h2>
    <perfect-scrollbar [wheelPropagation]="true" class="bordered">
      <table>
        <thead>
        <tr>
          <th (click)="checkAll()" *ngIf="!data.readonly && !data.opinionRequest" class="centered-text">
            <gmt-checkbox gmtType="multiselect" [gmtChecked]="checkedAll"></gmt-checkbox>
          </th>
          <th>
            <sort-by [selected]="'parcel' === sortOrder.sortBy" (sort)="onSortParcel()" [sortDir]="sortOrder.direction">
              Parcela {{ data.occupationType === 'P' ? '/ Budova' : '' }}
            </sort-by>
          </th>
          <th *ngIf="data.opinion.expertOpinionLand">
            <ng-container *ngIf="!hasCoefficient && data.opinion.expertOpinionPricingMethod === 'M'">Cena za m<sup>2</sup> pozemku</ng-container>
            <ng-container *ngIf="!hasCoefficient && data.opinion.expertOpinionPricingMethod === 'A'">Cena za pozemek</ng-container>
            <ng-container *ngIf="hasCoefficient">Cena pozemku - Koeficient podle zákona 416/2009 Sb</ng-container>
          </th>
          <th *ngIf="data.opinion.expertOpinionCompensation">Cena náhrady</th>
          <th *ngIf="data.opinion.expertOpinionVegetation">Cena porost<br/>(vedlejší úpravy)</th>
          <th *ngIf="data.opinion.expertOpinionBuildings">Cena stavby</th>
          <th>Celková cena</th>
          <th *ngIf="hasCoefficient">Celková cena vč. koeficientu</th>
        </tr>
        </thead>

        <tbody *ngIf="!data.readonly">
        <tr *ngFor="let parcelPrice of data.parcelPrices" [ngClass]="{ 'parcel-price-selected' : data.checklistParcelPrices.isChecked(parcelPrice) }">
          <!-- checkbox -->
          <td *ngIf="!data.opinionRequest" (click)="toggleParcelPrice(parcelPrice)" class="centered-text">
            <gmt-checkbox [gmtDisabled]="!isParcelPriceEnabled(parcelPrice)" gmtType="multiselect" [gmtChecked]="data.checklistParcelPrices.isChecked(parcelPrice)"></gmt-checkbox>
          </td>

          <!-- parcela/budova -->
          <td *ngIf="parcelPrice.parcel" class="top no-padding cell-max-height">
            <cell-parcel
              [parcel]="parcelPrice.parcel"
              [tooltipType]="'parcel'"
              [tooltipData]="{ parcel: parcelPrice.parcel, withoutTitle: true }"
            ></cell-parcel>
          </td>
          <td *ngIf="parcelPrice.building" class="top">
            {{ parcelPrice.building | buildingNumber }}<br/>
            na parcel{{ parcelPrice.building.parcels.length > 1 ? 'ách' : 'e' }}
            <span class="comma-list"><span *ngFor="let itemParcel of parcelPrice.building.parcels">{{ itemParcel | parcelnumber }}</span></span>
          </td>

          <!-- Cena za pozemek -->
          <td *ngIf="data.opinion.expertOpinionLand" class="top">
            <ng-container *ngIf="parcelPrice.parcel">
              <ng-container *ngIf="hasCoefficient">
                <span (click)="parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply = !parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply; computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply"></gmt-checkbox> koeficient 8
                </span><br>

                <gmt-input *ngIf="multipleLandCoefficient(parcelPrice) && parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply">
                  <div class="flexbox-container mb-10">
                    <div *ngIf="data.opinion.expertOpinionPricingMethod === 'M'" class="price-input-container">Cena <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land8SquarePrice" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> Kč/m<sup>2</sup></div>
                    <div *ngIf="data.opinion.expertOpinionPricingMethod === 'A'" class="price-input-container">Cena <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land8Price" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> Kč</div>
                    <div class="price-input-container">Výměra <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land8Area" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> m<sup>2</sup></div>
                  </div>
                </gmt-input>

                <span (click)="parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply = !parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply; computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply"></gmt-checkbox> koeficient 1,5
                </span><br>

                <gmt-input *ngIf="multipleLandCoefficient(parcelPrice) && parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply">
                  <div class="flexbox-container mb-10">
                    <div *ngIf="data.opinion.expertOpinionPricingMethod === 'M'" class="price-input-container">Cena <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land15SquarePrice" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> Kč/m<sup>2</sup></div>
                    <div *ngIf="data.opinion.expertOpinionPricingMethod === 'A'" class="price-input-container">Cena <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land15Price" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> Kč</div>
                    <div class="price-input-container">Výměra <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land15Area" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> m<sup>2</sup></div>
                  </div>
                </gmt-input>

                <span  (click)="parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply = !parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply; computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply"></gmt-checkbox> bez koeficientu (§ 3b odst. 6)
                </span><br>

                <gmt-input *ngIf="multipleLandCoefficient(parcelPrice) && parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply">
                  <div class="flexbox-container mb-10">
                    <div *ngIf="data.opinion.expertOpinionPricingMethod === 'M'" class="price-input-container">Cena <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land1SquarePrice" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> Kč/m<sup>2</sup></div>
                    <div *ngIf="data.opinion.expertOpinionPricingMethod === 'A'" class="price-input-container">Cena <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land1Price" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> Kč</div>
                    <div class="price-input-container">Výměra <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land1Area" (ngModelChange)="populateBaseLandPrice(parcelPrice);computeParcelPrice(parcelPrice)" gmtInputNumber> m<sup>2</sup></div>
                  </div>
                </gmt-input>

                <div *ngIf="multipleLandCoefficient(parcelPrice) && !multipleLandCoefficientAreaMatches(parcelPrice)" class="error-message mb-10">
                  Součet částí musí být roven výměre parcely
                </div>
              </ng-container>

              <div *ngIf="!multipleLandCoefficient(parcelPrice)" class="price-input-container mt-10">
                Cena
                <ng-container *ngIf="data.opinion.expertOpinionPricingMethod === 'M'">
                  <input
                    *ngIf="parcelPrice.parcel"
                    [(ngModel)]="parcelPrice.expertOpinionLandSquarePrice"
                    (ngModelChange)="populateBaseLandPrice(parcelPrice); computeParcelPrice(parcelPrice)"
                    [attr.disabled]="multipleLandCoefficient(parcelPrice) ? true : null"
                    gmtInputNumber
                    type="text"
                    title="Cena za pozemek: {{ (parcelPrice.expertOpinionLandSquarePrice * parcelPrice.parcel.vymera) | number | price }}"
                  />
                </ng-container>

                <ng-container *ngIf="data.opinion.expertOpinionPricingMethod === 'A'">
                  <input
                    *ngIf="parcelPrice.parcel"
                    [(ngModel)]="parcelPrice.expertOpinionLandAreaPrice"
                    (ngModelChange)="populateBaseLandPrice(parcelPrice); computeParcelPrice(parcelPrice)"
                    [attr.disabled]="multipleLandCoefficient(parcelPrice) ? true : null"
                    gmtInputNumber
                    type="text"
                    title="Cena za pozemek: {{ (parcelPrice.expertOpinionLandSquarePrice * parcelPrice.parcel.vymera) | number | price }}"
                  />
                </ng-container>
                Kč<span *ngIf="data.opinion.expertOpinionPricingMethod === 'M'">/m<sup>2</sup></span>
              </div>
            </ng-container>
          </td>

          <!-- cena nahrady -->
          <td *ngIf="data.opinion.expertOpinionCompensation" class="top">
            <ng-container *ngIf="parcelPrice.parcel">
              <div *ngIf="hasCoefficient">
                <div (click)="toggleMultiply(parcelPrice, 'compensation'); computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="!parcelPrice.expertOpinionCoefficientsFlatten.compensationMultiply"></gmt-checkbox> bez koeficientu
                </div>
                <div (click)="toggleCoefficient(parcelPrice, 'compensation', COEFFICIENT_8); computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient == COEFFICIENT_8"></gmt-checkbox> koeficient 8
                </div>
                <div (click)="toggleCoefficient(parcelPrice, 'compensation', COEFFICIENT_15); computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient == COEFFICIENT_15"></gmt-checkbox> koeficient 1,5
                </div>
              </div>
              <div class="price-input-container mt-10">
                Cena
                <input
                  [(ngModel)]="parcelPrice.expertOpinionCompensationPriceDefault"
                  (ngModelChange)="computeParcelPrice(parcelPrice)"
                  gmtInputNumber
                  type="text"
                />
                Kč
              </div>
            </ng-container>
          </td>

          <!-- cena porost -->
          <td *ngIf="data.opinion.expertOpinionVegetation" class="top">
            <ng-container *ngIf="parcelPrice.parcel">
              <div *ngIf="hasCoefficient">
                <div (click)="toggleMultiply(parcelPrice, 'vegetation'); computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="!parcelPrice.expertOpinionCoefficientsFlatten.vegetationMultiply"></gmt-checkbox> bez koeficientu
                </div>
                <div (click)="toggleCoefficient(parcelPrice, 'vegetation', COEFFICIENT_8); computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient == COEFFICIENT_8"></gmt-checkbox> koeficient 8
                </div>
                <div (click)="toggleCoefficient(parcelPrice, 'vegetation', COEFFICIENT_15); computeParcelPrice(parcelPrice)">
                  <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient == COEFFICIENT_15"></gmt-checkbox> koeficient 1,5
                </div>
              </div>
              <div class="price-input-container mt-10">
                Cena
                <input
                  [(ngModel)]="parcelPrice.expertOpinionVegetationPriceDefault"
                  (ngModelChange)="computeParcelPrice(parcelPrice);"
                  gmtInputNumber
                  type="text"
                />
                Kč
              </div>
            </ng-container>
          </td>

          <!-- cena stavby -->
          <td *ngIf="data.opinion.expertOpinionBuildings" class="top">
            <div *ngIf="hasCoefficient">
              <div (click)="toggleMultiply(parcelPrice, 'buildings'); computeParcelPrice(parcelPrice)">
                <gmt-checkbox [gmtChecked]="!parcelPrice.expertOpinionCoefficientsFlatten.buildingsMultiply"></gmt-checkbox> bez koeficientu
              </div>
              <div (click)="toggleCoefficient(parcelPrice, 'buildings', COEFFICIENT_8); computeParcelPrice(parcelPrice)">
                <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient == COEFFICIENT_8"></gmt-checkbox> koeficient 8
              </div>
              <div (click)="toggleCoefficient(parcelPrice, 'buildings', COEFFICIENT_15); computeParcelPrice(parcelPrice)">
                <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient == COEFFICIENT_15"></gmt-checkbox> koeficient 1,5
              </div>
            </div>
            <div class="price-input-container mt-10">
              Cena
              <input
                [(ngModel)]="parcelPrice.expertOpinionBuildingsPriceDefault"
                (ngModelChange)="computeParcelPrice(parcelPrice);"
                gmtInputNumber
                type="text"
              />
              Kč
            </div>
          </td>

          <!-- celkova cena -->
          <td class="number top" style="padding-top: 17px;">
            {{ ((((parcelPrice.expertOpinionLandPriceDefault || 0) * 1) + ((parcelPrice.expertOpinionCompensationPriceDefault || 0) * 1) + ((parcelPrice.expertOpinionVegetationPriceDefault || 0) * 1) + ((parcelPrice.expertOpinionBuildingsPriceDefault || 0) * 1)) || 0) | number | price }}
          </td>

          <!-- celkova cena vc. koeficientu -->
          <td *ngIf="hasCoefficient" class="number top" style="padding-top: 17px;">
            {{ ((((parcelPrice.expertOpinionLandPrice || 0) * 1) + ((parcelPrice.expertOpinionCompensationPrice || 0) * 1) + ((parcelPrice.expertOpinionVegetationPrice || 0) * 1) + ((parcelPrice.expertOpinionBuildingsPrice || 0) * 1)) || 0) | number | price }}
          </td>
        </tr>
        </tbody>

        <tbody *ngIf="this.data.readonly">
        <tr *ngFor="let parcelPrice of data.parcelPrices">
          <td>
            <span *ngIf="parcelPrice.parcel">
              {{ parcelPrice.parcel | parcelnumber }}<br/>
              {{ parcelPrice.parcel.vymera }} m<sup>2</sup><br/>
              {{ parcelPrice.parcel.drupozNazev }}
            </span>
            <span *ngIf="parcelPrice.building">
                {{ parcelPrice.building | buildingNumber }}<br/>
                na parcel{{ parcelPrice.building.parcels.length > 1 ? 'ách' : 'e' }}
                <span class="comma-list"><span *ngFor="let itemParcel of parcelPrice.building.parcels">{{ itemParcel | parcelnumber }}</span></span>
              </span>
          </td>
          <td *ngIf="data.opinion.expertOpinionLand" class="number">
            <ng-container *ngIf="multipleLandCoefficient(parcelPrice);">
              <ng-container *ngFor="let coefficient of parcelPrice.expertOpinionCoefficients">
                <div *ngIf="coefficient.type === 'land' && coefficient.multiply" class="text-right">
                  <span *ngIf="data.opinion.expertOpinionPricingMethod === 'A'">{{ coefficient.price | number | price }}</span>
                  <span *ngIf="data.opinion.expertOpinionPricingMethod === 'M'">{{ coefficient.squarePrice | number | price }}</span>
                  (koeficient {{ coefficient.coefficient }}, výměra {{ coefficient.area }} m<sup>2</sup>)
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!multipleLandCoefficient(parcelPrice);">
              <ng-container *ngIf="data.opinion.expertOpinionPricingMethod === 'M'">
                {{ parcelPrice.expertOpinionLandSquarePrice | number | price }}
              </ng-container>
              <ng-container *ngIf="data.opinion.expertOpinionPricingMethod === 'A'">
                {{ parcelPrice.expertOpinionLandAreaPrice | number | price }}
              </ng-container>
              <ng-container *ngTemplateOutlet="coefficient; context: { parcelPrice: parcelPrice, type: 'land' }"></ng-container>
            </ng-container>
          </td>
          <td *ngIf="data.opinion.expertOpinionCompensation" class="number">
            {{ parcelPrice.expertOpinionCompensationPriceDefault | number | price }}

            <ng-container *ngTemplateOutlet="coefficient; context: { parcelPrice: parcelPrice, type: 'compensation' }"></ng-container>
          </td>
          <td *ngIf="data.opinion.expertOpinionVegetation" class="number">

            {{ parcelPrice.expertOpinionVegetationPriceDefault | number | price }}
            <ng-container *ngTemplateOutlet="coefficient; context: { parcelPrice: parcelPrice, type: 'vegetation' }"></ng-container>
          </td>
          <td *ngIf="data.opinion.expertOpinionBuildings" class="number">
            {{ parcelPrice.expertOpinionBuildingsPriceDefault | number | price }}
            <ng-container *ngTemplateOutlet="coefficient; context: { parcelPrice: parcelPrice, type: 'buildings' }"></ng-container>
          </td>
          <td class="number">
            {{ ((((parcelPrice.expertOpinionLandPriceDefault || 0) * 1) + ((parcelPrice.expertOpinionCompensationPriceDefault || 0) * 1) + ((parcelPrice.expertOpinionVegetationPriceDefault || 0) * 1) + ((parcelPrice.expertOpinionBuildingsPriceDefault || 0) * 1)) || 0) | number | price }}
          </td>
          <td *ngIf="hasCoefficient" class="number">
            {{ ((((parcelPrice.expertOpinionLandPriceCoefficient || 0) * 1) + ((parcelPrice.expertOpinionCompensationPrice || 0) * 1) + ((parcelPrice.expertOpinionVegetationPrice || 0) * 1) + ((parcelPrice.expertOpinionBuildingsPrice || 0) * 1)) || 0) | number | price }}
          </td>
        </tr>
        </tbody>
      </table>
    </perfect-scrollbar>

    <div class="mt-30">
      <gmt-button [clickAction]="onBack">Zpět</gmt-button>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isFormValid()">Pokračovat</gmt-button>
    </div>
  </div>
</div>

<ng-template #coefficient let-parcelPrice="parcelPrice" let-type=type>
  <ng-container *ngIf="parcelPrice.expertOpinionCoefficients?.length && isUsedCoefficient(parcelPrice.expertOpinionCoefficients)">
    <ng-container *ngFor="let coefficient of parcelPrice.expertOpinionCoefficients">
      <div *ngIf="coefficient.multiply && coefficient.type === type" class="text-right">
        <span>Koeficient {{ coefficient.coefficient | number }}</span>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
