<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>

    <!-- Status data part -->

    <fieldset>
      <div class="column-100">
        <gmt-input label="Datum přijetí podepsané smlouvy">
          <input type="text" pick-a-date [(ngModel)]="statusData.responseReceivedDate" (ngModelChange)="onResponseReceivedDataChanged()" [maxToday]="true"/>
        </gmt-input>
      </div>
    </fieldset>

    <fieldset>
      <legend>Datum podpisu smlouvy<span *ngIf="signatureVerificationPriceEnabled()"> a částka za ověření podpisu</span></legend>
      <div *ngFor="let caseSubject of statusData.caseSubjects" class="cb">
        <div class="column-50">
          <label [ngClass]="{ 'required': enabledSubjects[caseSubject.id] }">{{ caseSubject.subject | subjectName }}</label>
          <div class="input" *ngIf="enabledSubjects[caseSubject.id]">
            <input type="text" pick-a-date [(ngModel)]="caseSubject.signedDate" (ngModelChange)="onSubjectSignDateChanged($event)" [maxToday]="true"/>
          </div>
          <div class="info" *ngIf="!enabledSubjects[caseSubject.id]">
            {{ caseSubject.signedDate | date }}
          </div>
        </div>
        <div class="column-50" *ngIf="signatureVerificationPriceEnabled()">
          <label>Částka za ověření podpisu (Kč)</label>
          <div class="input" *ngIf="enabledSubjects[caseSubject.id]">
            <input type="text" [(ngModel)]="caseSubject.signatureVerificationPrice"/>
          </div>
          <div class="info" *ngIf="!enabledSubjects[caseSubject.id] && caseSubject.signatureVerificationPrice !== null">
            {{ caseSubject.signatureVerificationPrice | number | price }}
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Subjects data part -->
    <!-- Smlouvy s vypoctem ceny -->
    <fieldset *ngIf="data.obligation.bonusPeriod || data.obligation.validityPeriod">
      <div class="column-100" *ngIf="data.obligation.bonusPeriod">
        <label>Nárok na akcelerační příplatek</label>
        <div class="info" *ngIf="data.bonusDate">{{ data.bonusGranted ? 'ano' : 'ne' }} (do {{ data.bonusDate | date }})</div>
        <div class="info" *ngIf="!data.bonusDate">bez nároku</div>
      </div>
      <div class="column-100" *ngIf="data.obligation.validityPeriod">
        <label>Doručeno v termínu</label>
        <div class="info">{{ deliveredInTime ? 'ano' : 'ne' }} (do {{data.validityDate | date}})</div>
      </div>
    </fieldset>
    <fieldset *ngIf="!data.paymentOrderDate && (data.obligation.dueDateFromType === 'ResponseReceivedSignedDate' || data.obligation.dueDateFromType === 'SignedAllOwnersDate')">
      <div class="column-100">
        <label>Datum splatnosti</label>
        <div class="info">{{ data.dueDate | date }}</div>
      </div>
    </fieldset>

    <fieldset visibleByBrand="mpv">
      <legend>Finanční rekapitulace, zveřejnění v registru smluv</legend>

      <div *ngIf="!regularPriceObligation && data.fulfillmentPrice" class="bordered-bottom mb-20 pb-10">
        <div class="column-100">
          <div class="label">Finanční plnění</div>
          <div class="info">
            {{ data.fulfillmentPrice | number | price }}
            <span *ngIf="!isFulfillmentPriceValid()" class="error-message"> Zadané ceny u podílů neodpovídají celkové ceně</span>
          </div>
        </div>
        <div class="cb"></div>
      </div>

      <div *ngFor="let caseOwnership of subjectsData.dataCaseOwnerships">
        <sign-owner-validation
          [caseOwnership]="caseOwnership"
          [isSubjectSigned]="isSubjectSigned"
          [regularPriceObligation]="regularPriceObligation"
          [fulfillmentPrice]="data.fulfillmentPrice"
        ></sign-owner-validation>
      </div>

      <div class="column-100">
        <gmt-input label="Zveřejnění v registru smluv" [required]="statusData.caseSubjects.length === currentlySigned().length">
          <gmt-switch [options]="contractRegisterPublicationOptions" [(selectedId)]="contractRegisterPublication"></gmt-switch>
        </gmt-input>
      </div>
    </fieldset>

    <div>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isStatusValid() || !isSubjectsValid()">Uložit</gmt-button>
    </div>
  </div>
</div>
