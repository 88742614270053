import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from '@app/common/services/auth.service';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { EasementModel } from '@app/common/models/easement.model';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { ExpertModel } from '@app/ps/models/expert.model';
import { StringUtils } from '@app/common/utils/string.utils';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';
import { RoundingService } from '@app/ps/services/rounding.service';
import { Decimal } from 'decimal.js';
import { DecimalPipe } from '@app/common/pipes/decimal.pipe';

@Component({
  selector: 'update-easement-price',
  templateUrl: './update-easement-price.component.html',
  styleUrls: []
})
export class  UpdateEasementPriceComponent implements OnInit {

  loading = true;
  showCoefficient = false;
  easementId: number;
  calculatedPrice: string;
  easement: EasementModel;
  helpIds = HelpService.HELP_IDS;

  priceTypes: SwitchOption[] = [
    {
      id: 'S',
      value: 'Jednorázová cena podle 416/2009 Sb.',
    },
    {
      id: 'E',
      value: 'Znalecký posudek',
    },
    {
      id: 'O',
      value: 'Jiný způsob',
    },
    {
      id: null,
      value: 'Reset',
    },
  ];

  unitTypes: SwitchOption[] = [
    {
      id: 'A',
      value: 'Plocha',
    },
    {
      id: 'L',
      value: 'Délka',
    },
    {
      id: 'T',
      value: 'Jednorázová cena',
    },
  ];

  constructor(
    private restangular: Restangular,
    private authService: AuthService,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
    private roundingService: RoundingService,
    private decimalPipe: DecimalPipe,
  ) {
    this.easementId = (<DialogConfigData>dialogConfig.data).easementId;
    this.refreshDefaultPrice = this.refreshDefaultPrice.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  ngOnInit() {
    this.restangular
      .one('easements', this.easementId)
      .customPOST({constructionObjects: {}})
      .toPromise()
      .then((res) => {
        this.easement = UpdateEasementPriceComponent.normalizeData(res);
        this.showCoefficient = this.authService.getActualProject().showExpertOpinionCoefficient;
        if (!this.easement.priceType) {
          this.easement.priceType = 'S';
        }
        this.refreshDefaultPrice();
        this.loading = false;
      });
  }

  onExpertSelected(expert: ExpertModel) {
    this.easement.expertOpinionExpert = expert;
  }

  isFormValid(): boolean {
    const isAreaPriceValid = this.easement.unitType === 'A' && this.decimalPipe.transform(this.easement.areaPrice) && StringUtils.valueToString(this.easement.areaPrice) !== '';
    const isLengthPriceValid = this.easement.unitType === 'L' && this.decimalPipe.transform(this.easement.lengthPrice) && StringUtils.valueToString(this.easement.lengthPrice) !== '';
    const isTotalPriceValid = this.easement.unitType === 'T' && this.decimalPipe.transform(this.easement.totalPrice) && StringUtils.valueToString(this.easement.totalPrice) !== '';
    const isSinglePrice = this.easement.priceType === 'S' && this.decimalPipe.transform(this.easement.totalPrice) && StringUtils.valueToString(this.easement.totalPrice) !== '';
    const reset = this.easement.priceType === null;
    return !!(isAreaPriceValid || isLengthPriceValid || isTotalPriceValid || isSinglePrice || reset);
  }

  refreshDefaultPrice(id = '') {
    if (['L', 'A', 'T'].includes(id)) { // unitType changed
      this.easement.unitType = id;
    }
    if (['O', 'E', 'S', null].includes(id)) { // priceType changed
      this.easement.otherSource = undefined;
      this.easement.totalPrice = undefined;
      this.easement.priceType = id;
    }

    const price = this.calcDefaultPrice();
    this.easement.easementPriceDefault = price ? price.toString() : '0';
    this.refreshPrice();
  }

  refreshPrice() {
    let price = this.decimalPipe.transform(this.easement.easementPriceDefault);
    let coefficient = this.decimalPipe.transform(this.easement.priceCoefficient);
    if (this.easement.priceType !== 'S' && this.showCoefficient && this.easement.priceMultiply && price && coefficient) {
      price = price.mul(this.easement.priceCoefficient);
    }

    this.calculatedPrice = price ? price.toString() : '0'; // always displays a price

    if (!this.easement.useCustomRounding || this.easement.priceType === 'S') {
      this.easement.easementPrice = this.roundingService.roundProject(new Decimal(this.calculatedPrice)).toString();
    }
  }

  onUpdate() {
    const easement = UpdateEasementPriceComponent.denormalizeData(this.easement);
    return this.restangular
      .one('easements', this.easement.id)
      .customPUT(easement, undefined, { loadCollections: [ 'constructionObjects' ]})
      .toPromise()
      .then((res) => {
        this.dialogRef.close(res.plain());
      });
  }

  /**
   * Calculates default price by the unitType and given unit price.
   *
   * @returns {number}
   */
  private calcDefaultPrice() : Decimal {
    let finalPrice = null; // placeholder

    if (this.easement.priceType === 'S') {
      this.easement.otherSource = 'zákon 416/2009 Sb.';

      if (this.easement.totalPrice === undefined) {
        this.easement.totalPrice = '10000';
      }

      finalPrice = new Decimal(this.easement.totalPrice).mul(this.easement.constructionObjects.length);
    } else {
      switch (this.easement.unitType) {
        case 'A': // Area
          const areaPrice = this.decimalPipe.transform(this.easement.areaPrice);
          if (areaPrice && this.easement.vymera !== null) {
            finalPrice = areaPrice.mul(this.easement.vymera);
          }
          break;
        case 'L': // Length
          const lengthPrice = this.decimalPipe.transform(this.easement.lengthPrice);
          if (lengthPrice && this.easement.delka !== null) {
            finalPrice = lengthPrice.mul(this.easement.delka);
          }
          break;
        case 'T': // Total
          finalPrice = this.decimalPipe.transform(this.easement.totalPrice);
          break;
      }
    }

    return finalPrice;
  }

  /**
   * Adds general price properties to object to handle them easier.
   */
  private static normalizeData(data) {
    if (data.priceType === 'E') {
      data.unitType = data.expertOpinionUnitType || 'A';
    } else if (data.priceType === 'O') {
      data.unitType = data.otherUnitType || 'A';
    }

    data.areaPrice = data.expertOpinionAreaPrice || data.otherAreaPrice;
    data.lengthPrice = data.expertOpinionLengthPrice || data.otherLengthPrice;
    data.totalPrice = data.expertOpinionTotalPrice || data.otherTotalPrice;

    return data;
  }

  /**
   * Denormalizes the final data before passing them to the backend.
   * Using this approach, only values selected by user at the moment of saving
   * are actually saved.
   */
  private static denormalizeData(data2) {
    const data = {...data2};
    if (data.priceType === 'E') {
      switch (data.unitType) {
        case 'A':
          data.expertOpinionAreaPrice = data.areaPrice;
          data.expertOpinionLengthPrice = undefined;
          data.expertOpinionTotalPrice = undefined;
          break;
        case 'L':
          data.expertOpinionLengthPrice = data.lengthPrice;
          data.expertOpinionAreaPrice = undefined;
          data.expertOpinionTotalPrice = undefined;
          break;
        case 'T':
          data.expertOpinionTotalPrice = data.totalPrice;
          data.expertOpinionAreaPrice = undefined;
          data.expertOpinionLengthPrice = undefined;
      }

      data.expertOpinionUnitType = data.unitType;
      data.otherAreaPrice = undefined;
      data.otherLengthPrice = undefined;
      data.otherTotalPrice = undefined;
    } else if (data.priceType === 'O') {
      switch (data.unitType) {
        case 'A':
          data.otherAreaPrice = data.areaPrice;
          data.otherLengthPrice = undefined;
          data.otherTotalPrice = undefined;
          break;
        case 'L':
          data.otherLengthPrice = data.lengthPrice;
          data.otherAreaPrice = undefined;
          data.otherTotalPrice = undefined;
          break;
        case 'T':
          data.otherTotalPrice = data.totalPrice;
          data.otherAreaPrice = undefined;
          data.otherLengthPrice = undefined;
      }

      data.otherUnitType = data.unitType;
      data.expertOpinionAreaPrice = undefined;
      data.expertOpinionLengthPrice = undefined;
      data.expertOpinionTotalPrice = undefined;
    } else {
      data.otherTotalPrice = data.totalPrice;
      data.otherUnitType = undefined;
      data.expertOpinionAreaPrice = undefined;
      data.expertOpinionLengthPrice = undefined;
      data.expertOpinionTotalPrice = undefined;
    }

    delete data.areaPrice;
    delete data.lengthPrice;
    delete data.totalPrice;
    delete data.unitType;

    return data;
  }
}
