import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { MapModule } from '@app/map/map.module';
import * as  L from 'leaflet';
import * as  _ from 'lodash';
import { AuthService } from '@app/common/services/auth.service';
import { WordService } from "@app/common/services/word.service";
import { NewMapConfigService } from '@app/map/services/new-map-config.service';
import { MapService } from '@app/map/services/map.service';
import { MapHighlightService } from '@app/map/services/map-highlight.service';
import { MapLayerSelectorService } from '@app/map/services/map-layer-selector.service';
import { MapConfigService } from '@app/map/services/map-config.service';
import { Transition } from '@uirouter/core';
import { ParcelNamePipe } from '@app/common/pipes/parcel-name.pipe';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { OccupationNamePipe } from '@app/ps/pipes/occupation-name.pipe';
import { CaseNamePipe } from '@app/ps/pipes/case-name.pipe';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import { LocalStorageService } from 'angular-2-local-storage';
import { StateService } from '@uirouter/angular';
import {MapUtilsCrsService} from '@app/map/services/map-utils-crs.service';
import { MapPrintService } from '@app/ps/map/services/map-print.service';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
})
export class MapComponent implements OnInit {
  public searchResources: any[] = null;
  public mapConfigLoading: boolean = true;
  public defaultEnableCuzkInfo: boolean = false;
  public selectorConfig: any = null;
  public selectorOptions: any = null;
  public mapOptions: any = null;
  private localStorageCollapsedKey: string;
  private customCRS: any = {};
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APPLICATIONS) private APPLICATIONS: any,
    @Inject(APP_BRAND) private APP_BRAND: any,
    private localStorageService: LocalStorageService,
    private restangular: Restangular,
    private authService: AuthService,
    private wordService: WordService,
    private newMapConfigService: NewMapConfigService,
    @Inject('$transition$') private transition: Transition,
    private mapService: MapService,
    private mapHighlightService: MapHighlightService,
    private mapConfigService: MapConfigService,
    private mapLayerSelectorService: MapLayerSelectorService,
    private parcelNamePipe: ParcelNamePipe,
    private titleNamePipe: TitleNamePipe,
    private subjectNamePipe: SubjectNamePipe,
    private constructionObjectNamePipe: ConstructionObjectNamePipe,
    private occupationNamePipe: OccupationNamePipe,
    private caseNamePipe: CaseNamePipe,
    private mapPrintService: MapPrintService,
    private mapUtilsCrsService: MapUtilsCrsService,
  ) {
    this.toggleLayerSelector = this.toggleLayerSelector.bind(this);
    this.getCollapsed = this.getCollapsed.bind(this);
    this.fitHeight = this.fitHeight.bind(this);
  }

  ngOnInit() {
    const mapID = 'main-map';
    const project = this.authService.getActualProject();

    let coTranslatePromise;
    if (this.authService.getActualProject().isConstructionObjectGeometry) {
      coTranslatePromise = this.wordService.getWord('CONSTRUCTION_OBJECT');
    } else {
      coTranslatePromise = Promise.resolve();
    }
    coTranslatePromise.then((co) => {
      this.searchResources = [];
      this.searchResources.push({id: 'parcels', icon: 'fa-toggle-up', value: 'Parcela', pipe: this.parcelNamePipe, filter: {filters: {validity: 'valid'}}});
      this.searchResources.push({id: 'titles', icon: 'fa-users', value: 'List vlastnictví', pipe: this.titleNamePipe, filter: {filters: {validity: 'valid'}}});
      this.searchResources.push({id: 'cases', icon: 'fa-file', value: 'Případ', pipe: this.caseNamePipe});
      this.searchResources.push({id: 'subjects', icon: 'fa-user', value: 'Vlastník', pipe: this.subjectNamePipe, filter: {filters: {opsubType: {values: ['BSM'], negation: true}, validity: 'valid'}}});
      if (co) {
        this.searchResources.push({id: 'construction-objects', icon: 'fa-th', value: co, pipe: this.constructionObjectNamePipe, filter: {filters: {validity: 'valid'}}});
      }
      this.searchResources.push({id: 'occupations', icon: 'fa-map-pin', value: 'Zábor', pipe: this.occupationNamePipe, filter: {filters: {validity: 'valid'}}});
    });

    // Načtení nastavení z BE
    this.newMapConfigService.getLayersConfig(this.APPLICATIONS.sy.name, project.key).then(origLayersSettings => {
      const layersConfig = { ...origLayersSettings };
      // Rozšíří výchozí parametry vrstev o uživatelské nastavení
      this.mapConfigService.setLayers(layersConfig, mapID).then((layersConfigSet) => {
        const businessCaseId = this.transition.params().businessCase ? this.transition.params().businessCase : undefined;
        const selectedTool = this.transition.params().tool;
        const tools = ['info', 'measure', 'print-case'];

        if (this.APP_BRAND.NAME !== 'RSD') {
          tools.push('print');
        } else {
          tools.push('print-project');
        }

        const config = Object.assign({
          id: 'main-map',
          tools: tools,
          enableCuzkInfo: this.defaultEnableCuzkInfo,
          selectedTool: selectedTool,
          printCaseData: this.mapPrintService.getCasePrintData(),
          printData: this.mapPrintService.getPrintData(),
          projectPrintData: {
            formatTypes: MapPrintService.formatTypesProject,
            measureTypes: MapPrintService.measureTypesProject,
          }
        }, this.mapConfigService.filterMapConfig(layersConfigSet, layersConfig.layers));

        const processCustomCRS = (layers) => {
          layers.forEach(obj => {
            if (obj.crs) {
              const crsName = 'EPSG:' + obj.crs.srid + '_' + obj.crs.name;
              if (!this.customCRS[crsName]) {
                const crs = this.mapUtilsCrsService.getCrs(5514);
                this.customCRS[crsName] = {
                   startZoom: obj.crs.startZoom || 0,
                   crs: new L.Proj.CRS(crsName, MapModule.SRID_DEFS[obj.crs.srid], {
                     resolutions: crs.options.resolutions,
                     bounds: obj.crs.bounds,
                     origin: obj.crs.origin,
                   })
                 };
              }
            } else if (obj.layers) { processCustomCRS(obj.layers); }
          });
        };
        processCustomCRS(config.layers);
        this.selectorConfig = config;
        this.mapConfigLoading = false;
        this.selectorOptions = {
          saveLayersVisibility: true,
          saveGroupCollapseStatus: true,
          showCollapsedVisibleLayersCount: true,
          storagePrefix: project.key
        };
      });
    });

    if (this.transition.params().layer) {
      this.localStorageService.remove('map-' + project.key);
    }
    const savedPosition: any = this.localStorageService.get('map-' + project.key);
    let mapLat;
    let mapLon;
    let mapZoom;

    const defaultLat = project.mapLat ? project.mapLat : 49.75;
    const defaultLon = project.mapLon ? project.mapLon : 15.75;
    const defaultZoom = project.mapZoom ? project.mapZoom : 4;

    if (savedPosition) {
      mapLat = savedPosition.lat;
      mapLon = savedPosition.lon;
      mapZoom = savedPosition.zoom;
    } else {
      mapLat = defaultLat;
      mapLon = defaultLon;
      mapZoom = defaultZoom;
    }

    this.mapOptions = {
      id: mapID,
      srid: 5514,
      bounds: null,
      center: [mapLat, mapLon],
      zoom: mapZoom,
      minZoom: 4,
      maxZoom: 15,
      customCRS: this.customCRS,
    };

    // resizing window will change height of map frame
    $('#map-layer-selector').height(0);
    this.fitHeight();

    this.mapService.getMap(this.mapOptions.id).then((map) => {
      L.control.fullscreen({
        position: 'topleft',
        title: 'Celoobrazovkový režim',
        titleCancel: 'Ukončit celoobrazovkový režim'
      }).addTo(map);

      L.control.graphicScale({fill: 'hollow'}).addTo(map);

      const button = L.easyButton('fa-home map-home-icon', (btn, map) => {
        map.setView(new L.LatLng(defaultLat, defaultLon), defaultZoom);

        (<any>window).GoogleAnalytics('send', {
          hitType: 'event',
          eventCategory: 'mapModule',
          eventAction: '',
          eventLabel: 'home',
        });
      });
      button.addTo(map);

      /*const buttonCuzk = L.easyButton('cuzk small grayscale', (btn, map) => {
          this.defaultEnableCuzkInfo = !this.defaultEnableCuzkInfo;
          if (this.defaultEnableCuzkInfo) {
            $(btn.button).find('.cuzk').removeClass('grayscale');
          } else {
            $(btn.button).find('.cuzk').addClass('grayscale');
          }
          window.GoogleAnalytics('send', {
            hitType: 'event',
            eventCategory: 'mapModule',
            eventAction: '',
            eventLabel: 'map cuzk-show-position',
          });
          if (!this.selectorConfig) {
            return;
          }
          this.selectorConfig.enableCuzkInfo = this.defaultEnableCuzkInfo;
      });
      buttonCuzk.addTo(map);*/

      const logMapUrlHighlight = (type) => {
        (<any>window).GoogleAnalytics('send', {
          hitType: 'event',
          eventCategory: 'mapModule',
          eventAction: type,
          eventLabel: 'map url highlight',
        });
      };

      if (this.transition.params().title > 0) {
        const type = 'titles';
        logMapUrlHighlight(type);
        this.restangular.one(type, this.transition.params().title).get({loadCollections: ['geometry']}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      if (this.transition.params().parcel > 0) {
        const type = 'parcels';
        logMapUrlHighlight(type);
        this.restangular.one(type, this.transition.params().parcel).get({loadCollections: ['geometry']}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      if (this.transition.params().constructionObject > 0) {
        const type = 'construction-objects';
        logMapUrlHighlight(type);
        this.restangular.one(type, this.transition.params().constructionObject).get({loadCollections: ['geometry']}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      if (this.transition.params().occupation > 0) {
        const type = 'occupations';
        logMapUrlHighlight(type);
        this.restangular.one(type, this.transition.params().occupation).get().toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      if (this.transition.params().subject > 0) {
        const type = 'subjects';
        logMapUrlHighlight(type);
        this.restangular.one(type, this.transition.params().subject).customPOST({geometry: {}}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      if (this.transition.params().businessCase) {

        const type = 'cases';
        logMapUrlHighlight(type);
        this.restangular.one(type, this.transition.params().businessCase).get({loadCollections: ['geometry']}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry, undefined, undefined, data.plain());
        });
      }

      map.on('moveend', () => {
        const center = map.getCenter(),
          zoom = map.getZoom();

        const position = {
          lat: center.lat,
          lon: center.lng,
          zoom: zoom
        };
        this.localStorageService.set('map-' + project.key, position);
      });
    });

    this.localStorageCollapsedKey = project.key + ".layerSelector.collapsed";
    const collapsedStatus = this.localStorageService.get(this.localStorageCollapsedKey);

    this.mapLayerSelectorService.setCollapsedState((collapsedStatus === 1));
  }

  toggleLayerSelector() {
    const collapsed = !this.getCollapsed();
    this.localStorageService.set(this.localStorageCollapsedKey, collapsed ? 1 : 0);
    $('#map-layer-selector').height(0);
    this.mapLayerSelectorService.setCollapsedState(collapsed);
    // also forces recentering of map window
    setTimeout(this.fitHeight);
  }

  getCollapsed() {
    return this.mapLayerSelectorService.getCollapsedState();
  }

  @HostListener('window:resize')
  fitHeight() {
    const topBarHeight = $('#top-bar').outerHeight();
    const windowHeight = $(window).height();
    $('#map-window').height(windowHeight - topBarHeight);
    $('#map-content').height(windowHeight - topBarHeight);
    const layerSelectorHeight = windowHeight - topBarHeight - Math.round($('#selector-heading').outerHeight());
    $('#map-layer-selector').height(layerSelectorHeight);
  }
}
