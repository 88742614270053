import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { User } from '@app/models/user';
import { SettingsService } from '@app/ps/services/settings.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ListService } from '@app/common/services/list.service';
import { StatisticsService } from '@app/ps/services/statistics.service';
import { TableService } from '@app/ps/services/table.service';
import { WordService } from '@app/common/services/word.service';
import { SeriesService } from '@app/ps/services/series.service';
import { ModulesService } from '@app/common/services/modules.service';
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';
import { ObligationModel } from '@app/ps/models/obligation.model';
import { StateService } from '@uirouter/angular';
import { ObligationSettingsComponent } from '@app/ps/project-settings/components/obligation-settings/obligation-settings.component';
import { ObligationFlowSettingsComponent } from '@app/ps/project-settings/components/obligation-flow-settings/obligation-flow-settings.component';
import { DatePipe } from '@angular/common';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { StringUtils } from '@app/common/utils/string.utils';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { StatisticsSettingModel } from '@app/ps/models/statistics-setting.model';
import { APP_BRAND } from "@app/common/services/config.service";
import { RESTANGULAR_CONFIGURATION } from "@app/common/services/restangular-configuration.service";
import { RESTANGULAR_SYMAP } from "@app/common/services/restangular-symap.service";
import { LocalStorageService } from 'angular-2-local-storage';
import { Restangular } from 'ngx-restangular';
import {DialogService} from "@app/common/services/dialog.service";
import {ChecklistModel} from "@app/common/models/checklist.model";
import { RoundingPrecisionTypeEnum, priceRoundingPrecisionTypeOptions } from '@app/ps/enums/rounding-precision-type.enum';

@Component({
  selector: 'project-update',
  templateUrl: './project-update.component.html',
})
export class ProjectUpdateComponent implements OnInit {

  private static readonly PROJECT_FILTER = { loadCollections: [
    'contract',
    'customerWsdpAccount',
    'customer', 'series',
    'occupationTypes',
    'obligationTypes',
    'statisticsTypes',
    'exportTypes',
    'templateTypes',
    'investor',
    'investor.actingPersons',
    'investor.cadastreAgent',
    'investor.cadastreAgent.actingPersons',
    'manager',
    'designer',
    'propertyActivityProcessor',
    'landTakeDocumentationAuthor',
    'geodet',
    'propertyActivityHead',
    'templatesOrganizationalUnit',
    ]};

  @Input() prevState;
  @Input() goToPrevState = false;

  loading = true;
  data: any;
  selectedTab = 'G';
  tabs = [];
  user: User = this.authService.getUser();
  projectId = this.authService.getActualProject().id;
  project = this.authService.getActualProject();
  mapSettingsOnSaveCallback = null;
  settings = null;
  settingsSaving = false;
  dataCaseStatuses = null;
  dataCaseStatusesLoading = false;
  dataCaseStatuseEdited = null;
  dataTemplateTypes = null;
  checklistTemplates = null;
  dataExportTypes = null;
  checklistExportTypes = null;
  statisticsData = null;
  statisticsCallbackRegister = null;
  reloadStatistics: (statistics: StatisticsSettingModel[]) => void;
  statistics: StatisticsSettingModel[] = null;
  columnsVisibilityData = null;
  columnsVisibility = null;
  columnsRestrictionsTable = null;
  nomenclatureData = null;
  nomenclature = null;
  nomenclatureEdited = null;
  emailData = null;
  email = null;
  seriesParsed = null;
  cadastreDeliveryDate = null;
  downloadDataDate = null;
  downloadDataToInputDate = null;
  mapSettingsDefaults = null;
  dashboardSettingsOnSaveCallback = null;
  dashboardSettingsDefaults = null;
  projectSubjectUpdate: any;
  reloadOptionsOrganizationalUnit;
  organizationalUnitsNotCancelled: any;
  saveNotificationConfig: Function;
  currentState: any;

  cadastreDeliveryMonthPickerOptions = {
    minDate: moment().subtract(1, 'month').toDate(),
    maxDate: moment().add(1, 'month').toDate(),
    defaultDate: new Date()
  };

  downloadDataMonthPickerOptions = {
    minDate: moment().subtract(1, 'month').toDate(),
    maxDate: new Date(),
    defaultDate: new Date()
  };
  priceRoundingPrecisionTypeOptions = priceRoundingPrecisionTypeOptions;

  constructor(
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangularService: any,
    @Inject(RESTANGULAR_SYMAP) public restangularSymap: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    private restangular: Restangular,
    private localStorageService: LocalStorageService,
    private caseStatusService: CaseStatusService,
    public projectSubjectNamePipe: ProjectSubjectNamePipe,
    public organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    private elementRef: ElementRef,
    private tableService: TableService,
    private wordService: WordService,
    private statisticsService: StatisticsService,
    private listService: ListService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private seriesService: SeriesService,
    private modulesService: ModulesService,
    private dialogService: DialogService,
    private stateService: StateService,
    private datePipe: DatePipe,
  ) {
    this.getMapSettingsDefaults = this.getMapSettingsDefaults.bind(this);
    this.getDashboardSettingsDefaults = this.getDashboardSettingsDefaults.bind(this);
    this.displayEdit = this.displayEdit.bind(this);
    this.resetEdit = this.resetEdit.bind(this);
    this.columnIsVisible = this.columnIsVisible.bind(this);
    this.toggleHiddenColumn = this.toggleHiddenColumn.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.initFormData = this.initFormData.bind(this);
    this.onUpdateObligation = this.onUpdateObligation.bind(this);
    this.onUpdateObligationFlow = this.onUpdateObligationFlow.bind(this);
    this.update = this.update.bind(this);
    this.runCadastreDelivery = this.runCadastreDelivery.bind(this);
    this.runDownloadData = this.runDownloadData.bind(this);
    this.updateCadastreData = this.updateCadastreData.bind(this);
    this.downloadDataToInputTables = this.downloadDataToInputTables.bind(this);
    this.onSaveNotificationConfigRegister = this.onSaveNotificationConfigRegister.bind(this);
  }

  ngOnInit() {
    this.tabs = this.getTabs();
    // this.settingsService.clearSettings();
    this.modulesService.loadSyMAPProjectById(this.projectId).then(this.initFormData);
    this.organizationalUnitsNotCancelled = this.listService.createList('organizational-units', {filters: {cancelled: [false]}}, this.configurationRestangularService);
  }

  getMapSettingsDefaults(event) {
    this.mapSettingsDefaults = event.defaults;
    this.mapSettingsOnSaveCallback = event.onSave;
  }

  getDashboardSettingsDefaults(event) {
    this.dashboardSettingsDefaults = event.defaults;
    this.dashboardSettingsOnSaveCallback = event.onSave;
  }

  displayEdit(caseStatusIndex, event, type) {
    this.setEditItemIndexByType(type, caseStatusIndex);
    const inputs = event.currentTarget.getElementsByTagName('input');
    setTimeout(() => {
      for (const input of inputs) {
        input.focus();
      }
    });
  }

  onUpdateObligation(obligation: ObligationModel) {
    const dialog = this.dialogService.open(ObligationSettingsComponent, { data: { obligationId: obligation.id }});
    const sub = dialog.afterClosed.subscribe((updatedObligation: ObligationModel) => {
      if (updatedObligation) {
        Object.assign(obligation, updatedObligation);
      }
      sub.unsubscribe();
    });
  }

  onUpdateObligationFlow(obligation: ObligationModel) {
    const dialog = this.dialogService.open(ObligationFlowSettingsComponent, { data: { obligationId: obligation.id }});
    const sub = dialog.afterClosed.subscribe(() => {
      sub.unsubscribe();
    });
  }

  onPriceRoundingPrecisionTypeChanged(a) {
    a.promise = this.dialogService.confirmDialogPromise('Změna upravuje chování mezivýpočtů vstupních dialogů obsahujících cenové položky a dělení konečných částek mezi spoluvlastníky. Opravdu chcete změnu provést?');
  }

  resetEdit(caseStatus, type) {
    this.setEditItemIndexByType(type, null);

    setTimeout(() => {
      if (type === 'layer') {
        if (caseStatus.userlayer.name.trim() === '') {
          caseStatus.userlayer.name = caseStatus.commonName;
        }
      } else if (caseStatus.name.trim() === '') {
        caseStatus.name = caseStatus.commonName;
      }
    });
  }

  columnIsVisible(tableID, columnID) {
    const table = this.getTableByID(tableID);
    const hiddenColumns = table && table.columns;

    if (hiddenColumns) {
      return !hiddenColumns.includes(columnID);
    } else {
      return true;
    }
  }

  toggleHiddenColumn(tableID, columnID) {
    let table = this.getTableByID(tableID);
    if (!table) {
      table = { name: tableID, columns: [] };
      this.columnsVisibility.hidden.push(table);
    }
    const columnIndex = table.columns.indexOf(columnID);
    if (columnIndex > -1) {
      table.columns.splice(columnIndex, 1);
    } else {
      table.columns.push(columnID);
    }
  }

  selectTab(tab) {
    this.selectedTab = tab.id;
    if (tab.onClick) {
      tab.onClick();
    }
  }

  private initFormData(data) {
    this.data = data;
    this.currentState = data.state;
    data.investor = data.investor || {};
    data.investor = ProjectUpdateComponent.getInvestorDefaults(data.investor);
    data.customer = data.customer && data.customer.id ? data.customer : null;
    data.customerWsdpAccount = data.customerWsdpAccount && data.customerWsdpAccount.id ? data.customerWsdpAccount : null;
    data.exportTypes.forEach(exportType => {
      delete exportType.userParams;
    });

    // series tab
    this.seriesParsed = [];
    this.seriesService.parseSeries(data.series, this.seriesParsed);

    this.loading = false;
  }

  runCadastreDelivery() {
    return this.restangularSymap
      .all(`projects/${this.projectId}/cadastre/start-delivery/${this.cadastreDeliveryDate}`)
      .customPOST().toPromise();
  }

  runDownloadData() {
    return this.restangularSymap
      .all(`projects/${this.projectId}/cadastre/download-data`)
      .customPOST({}, '', { validityDate: this.downloadDataDate })
      .toPromise();
  }

  updateCadastreData() {
    return this.restangularSymap
      .all(`projects/${this.projectId}/cadastre/update-cadastre-data`)
      .customPOST({}, '')
      .toPromise();
  }

  downloadDataToInputTables() {
    return this.restangularSymap
      .all(`projects/${this.projectId}/cadastre/download-data-to-input-tables`)
      .customGET('', { validityDate: this.downloadDataToInputDate, truncate: true} )
      .toPromise();
  }

  onCadastreDeliveryDateChange(date) {
    this.cadastreDeliveryDate = this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  onDownloadDataDateChange(date) {
    this.downloadDataDate = this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  onDownloadDataToInputTablesChange(date) {
    this.downloadDataToInputDate = this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  onSaveNotificationConfigRegister(saveNotificationConfig) {
    this.saveNotificationConfig = saveNotificationConfig;
  }

  isValid() {
    if (!this.data.state) {
      return true;
    }

    return this.data['state' + StringUtils.capitalize(this.data.state) + 'Date']
      && (this.data.state !== 'STARTED' || (
        this.data.readinessPermanentOccupations !== undefined &&
        this.data.readinessTemporaryUnderOccupations !== undefined &&
        this.data.readinessTemporaryOverOccupations !== undefined &&
        this.data.readinessTemporaryEasements !== undefined &&
        this.data.readinessOk !== false &&
        (!!this.data.manager || this.APP_BRAND.NAME !== 'RSD')
      ));
  }

  update() {
    this.seriesService.sectionsToSeries(this.seriesParsed, this.data.series);
    this.settingsSaving = false;
    let tabIndex = -1;
    let responseData;

    const saveNext = () => {
      while (++tabIndex < this.tabs.length) {
        if (this.tabs[tabIndex].save) {
          const promise = this.tabs[tabIndex].save();

          if (promise) {
            return promise.then((data) => {
              if (tabIndex === 0) {
                responseData = data;
              }
              return saveNext();
            });
          }
        }
      }
      return Promise.resolve();
    };

    // save all tabs
    return saveNext().then(() => {
      if (responseData) {
        this.initFormData(responseData);

        const project = {};
        [
          'constructionObjectRealizationNotifyDaysBefore',
          'easementDistributionSystemParts',
          'easementPriceByShare',
          'mapLat',
          'mapLon',
          'mapZoom',
          'name',
          'obligationTypes',
          'series',
          'isprofond',
          'unit',
          'name',
          'infrastructure',
          'region',
          'phase',
          'contractName',
          'showExpertOpinionCoefficient',
          'statisticsTypes',
          'templateTypes',
          'updateCadastre',
          'investor',
          'manager',
          'designer',
          'propertyActivityProcessor',
          'landTakeDocumentationAuthor',
          'projectStartDate',
          'state',
          'readinessPermanentOccupations',
          'readinessTemporaryUnderOccupations',
          'readinessTemporaryOverOccupations',
          'readinessTemporaryEasements',
          'geodet',
          'propertyActivityHead',
          'templatesOrganizationalUnit',
          'type',
        ].forEach((attrName) => {
          project[attrName] = responseData[attrName];
        });

        Object.assign(this.project, project);
        Object.assign(_.find(this.authService.getActualProjects(), { id: this.projectId }), project);

        this.localStorageService.set('auth-projects', this.authService.getActualProjects());
        this.localStorageService.set('auth-project', this.authService.getActualProject());

        if (this.goToPrevState && this.prevState) {
          this.stateService.go(this.prevState);
        }
      }
    });
  }

  private getTableByID(tableID) {
    return this.columnsVisibility
      && this.columnsVisibility.hidden
      && this.columnsVisibility.hidden.find(tableCfg => tableCfg.name === tableID);
  }

  private saveSettings(): Promise<any> {
    if (!this.settingsSaving) {
      this.settingsSaving = true;
      return this.settings && this.settingsService.settingsUpload(this.projectId, this.settings);
    }
  }

  private setEditItemIndexByType(type, val) {
    switch (type) {
      case 'nomenclature':
        this.nomenclatureEdited = val;
        break;
      case 'CaseStatuse':
        this.dataCaseStatuseEdited = val;
        break;
    }
  }

  private getTabs() {
    const tabs = [
      {
        id: 'G',
        name: 'Základní',
        save: () => {
          return this.data.put(ProjectUpdateComponent.PROJECT_FILTER).toPromise();
        }
      },
      {
        id: 'KN',
        name: 'KN data, LPIS'
      },
      {
        id: 'WSDP',
        name: 'KN data, WSDP'
      },
      {
        id: 'ZE',
        name: 'Data ZE'
      },
      {
        id: 'MAP',
        name: 'Mapa',
        save: () => {
          if (typeof this.mapSettingsOnSaveCallback === 'function') {
            return this.mapSettingsOnSaveCallback();
          }
        },
      },
      {
        id: 'I',
        name: 'Návrh na vklad',
        onClick: () => {
        },
      },
      {
        id: 'N',
        name: 'Číselné řady'
      },
      {
        id: 'O',
        name: 'Případy'
      },
      {
        id: 'CS',
        name: 'Stavy případů',
        onClick: () => {
          if (!this.dataCaseStatuses) {
            this.dataCaseStatusesLoading = true;
            this.caseStatusService.loadStatuses().then(data => {
              this.dataCaseStatuses = data;
              this.dataCaseStatusesLoading = false;
            });
            this.dataCaseStatuseEdited = null;
          }
        },
        save: () => {
          return this.caseStatusService.saveStatuses(this.dataCaseStatuses);
        }
      },
      {
        id: 'T',
        name: 'Šablony',
        onClick: () => {
          if (!this.dataTemplateTypes) {
            this.dataTemplateTypes = this.listService.createList('templates/types-available', {});
            this.listService.fetchResult(this.dataTemplateTypes);
            this.checklistTemplates = new ChecklistModel(this.data.templateTypes);
            this.checklistTemplates.identity = (item) => ({ key: item.key });
          }
        }
      },
      {
        id: 'E',
        name: 'Exporty',
        onClick: () => {
          if (!this.dataExportTypes) {
            this.dataExportTypes = this.listService.createList('exports/types-available', {});
            this.listService.fetchResult(this.dataExportTypes).then(() => {
              this.dataExportTypes.list.forEach(exportType => {
                delete exportType.userParams;
              });
            });
            this.checklistExportTypes = new ChecklistModel(this.data.exportTypes);
            this.checklistExportTypes.identity = (item) => ({key: item.key});
          }
        }
      },
      {
        id: 'S',
        name: 'Statistiky',
        onClick: () => {
          if (!this.statisticsData) {
            this.statisticsCallbackRegister = ($event) => {
              this.reloadStatistics = $event.reload;
            };
            this.statisticsData = this.listService.createList(
              'statistics',
              { sortOrder: { sortBy: 'statisticsOrder' }, limit: null }
            );
            this.listService.fetchResult(this.statisticsData);
            this.statisticsData.promise.then(() => {
              this.statistics = this.statisticsData.list;
            });
          }
        },
        save: () => {
          if (this.statistics) {
            return this.restangular.all('statistics').customPUT(this.statistics).toPromise().then((newData) => {
              this.statistics = newData.plain();
              this.reloadStatistics(this.statistics);
              this.statisticsService.removeLoadedStatistics();
            });
          }
        }
      },
      {
        id: 'CL',
        name: 'Sloupce',
        onClick: () => {
          if (!this.columnsVisibilityData) {
            this.columnsVisibilityData = this.settingsService.loadSettings(this.projectId).then((settingsData) => {
              this.settings = this.settings || _.cloneDeep(settingsData);
              this.columnsVisibility = this.settings.columnSettings;
              if (!this.columnsVisibility.hidden) {
                this.columnsVisibility.hidden = [];
              }
            });
          }
          this.columnsRestrictionsTable = this.tableService.getTables();
        },
        save: () => {
          if (this.columnsVisibility) {
            return this.saveSettings();
          }
        }
      },
      {
        id: 'NL',
        name: 'Názvosloví',
        onClick: () => {
          if (!this.nomenclatureData) {
            this.nomenclatureData = this.settingsService.loadSettings(this.projectId).then((settingsData) => {
              this.settings = this.settings || _.cloneDeep(settingsData);

              // fallback if no nomenclature is filled
              if (this.settings.nomenclature.length === 0) {
                this.settings.nomenclature = Array
                  .from(this.wordService.getVocabulary())
                  .filter((w: any) => w.hasOwnProperty('editable') ? !w.editable === false : true);
              }

              const missingWords = this.wordService.getDifferenceWords(this.settings.nomenclature, this.wordService.getVocabulary());
              this.settings.nomenclature.push(...missingWords.filter((mw => mw.editable !== false)));
              this.nomenclature = this.settings.nomenclature;

            });
            this.nomenclatureEdited = null;
          }
        },
        save: () => {
          if (this.nomenclature) {
            return this.saveSettings();
          }
        }
      },
      {
        id: 'EM',
        name: 'Email',
        onClick: () => {
          if (!this.emailData) {
            this.emailData = this.settingsService.loadSettings(this.projectId).then((settingsData) => {
              this.settings = this.settings || _.cloneDeep(settingsData);

              if (!this.settings.email) {
                this.settings.email = { welcomeEmail: {} };
              }

              this.email = this.settings.email;
            });
          }
        },
        save: () => {
          if (this.saveNotificationConfig) {
            this.saveNotificationConfig();
          }
          if (this.email) {
            return this.saveSettings();
          }
        }
      },
      {
        id: 'DASHBOARD',
        name: 'Dashboard',
        save: () => {
          if (typeof this.dashboardSettingsOnSaveCallback === 'function') {
            return this.dashboardSettingsOnSaveCallback();
          }
        }
      },
    ];

    return tabs;
  }

  public static getInvestorDefaults(investor) {
    investor.useCaseNumberAsReferenceNumber = investor.useCaseNumberAsReferenceNumber || false;
    investor.useAccountNumberForProposal = investor.useAccountNumberForProposal || false;
    investor.deliveryUse = investor.deliveryUse || false;
    investor.discriminator = investor.discriminator || 'I';
    investor.subjectType = investor.type || investor.subjectType || 'OPO';
    investor.actingPersons = investor.actingPersons || [];
    investor.showPublicationNote = investor.showPublicationNote || false;
    investor.showNoPublicationNote = investor.showNoPublicationNote || false;
    investor.publicationNoteTemplate = investor.publicationNoteTemplate === undefined
      ? 'Tato smlouva byla uveřejněna v registru smluv pod id [ID]'
      : investor.publicationNoteTemplate;
    investor.noPublicationNoteTemplate = investor.noPublicationNoteTemplate === undefined
      ? 'Tato smlouva nebyla uveřejněna v registru smluv. Výjimka: [VYJIMKA]'
      : investor.noPublicationNoteTemplate;
    investor.easementDescription = investor.easementDescription === undefined
      ? 'Věcné břemeno - služebnost inženýrské sítě spočívající v právu zřídit, provozovat a udržovat na pozemku inženýrskou síť'
      : investor.easementDescription;

    delete investor.abbreviation;
    delete investor.idText;
    delete investor.type;
    delete investor.cuzkWsdpUsername;
    delete investor.rootCustomer;
    delete investor.businessRegisterRegionalCourt;

    return investor;
  }
}
