<ng-container>
  <!-- checkbox -->
  <td *ngIf="selectable" class="centered-text" (click)="selected = !selected">
    <gmt-checkbox [gmtDisabled]="isParcelPriceEnabled()" gmtType="multiselect" [gmtChecked]="selected"></gmt-checkbox>
  </td>

  <!-- parcela/budova -->
  <td *ngIf="parcel && showParcel" class="top no-padding cell-max-height">
    <cell-parcel
      [parcel]="parcel"
      [tooltipType]="'parcel'"
      [tooltipData]="{ parcel: parcel, withoutTitle: true }"
    ></cell-parcel>
  </td>

  <td class="top" *ngIf="building">
    {{building | buildingNumber }}<br/>
    na parcel{{building.parcels.length > 1 ? 'ách' : 'e'}} <span class="comma-list"><span *ngFor="let itemParcel of building.parcels">{{ itemParcel | parcelnumber}}</span></span>
  </td>

  <!-- cena za m2 pozemku -->
  <td *ngIf="opinion.expertOpinionLand" class="top">
    <input
      *ngIf="parcel"
      (input)="computeParcelPrice()"
      [(ngModel)]="parcelPrice.expertOpinionLandSquarePrice"
      gmtInputNumber
      title="Cena za pozemek: {{ (parcelPrice.expertOpinionLandSquarePrice * parcel.vymera) | number | price }}"
      type="text"
    />
  </td>

  <!-- koeficient parcely -->
  <td *ngIf="hasCoefficient" class="top">
    <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply = !parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply; computeParcelPrice()">
      <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply"></gmt-checkbox> koeficient 8
    </div>

    <gmt-input *ngIf="multipleLandCoefficient(parcelPrice) && parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply">
      <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land8Area" (ngModelChange)="computeParcelPrice()" gmtInputNumber>
    </gmt-input>

    <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply = !parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply; computeParcelPrice()">
      <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply"></gmt-checkbox> koeficient 1,5
    </div>

    <gmt-input *ngIf="multipleLandCoefficient(parcelPrice) && parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply">
      <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land15Area" (ngModelChange)="computeParcelPrice()" gmtInputNumber>
    </gmt-input>

    <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply = !parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply; computeParcelPrice()">
      <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply"></gmt-checkbox> bez koeficientu (§ 3b odst. 6)
    </div>

    <gmt-input *ngIf="multipleLandCoefficient(parcelPrice) && parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply">
      <input type="text" [(ngModel)]="parcelPrice.expertOpinionCoefficientsFlatten.land1Area" (ngModelChange)="computeParcelPrice()" gmtInputNumber>
    </gmt-input>

    <div *ngIf="multipleLandCoefficient(parcelPrice) && !multipleLandCoefficientAreaMatches(parcelPrice)" class="error-message">
      Součet částí musí být roven výměre parcely
    </div>
  </td>

  <!-- cena nahrady -->
  <td *ngIf="opinion.expertOpinionCompensation" class="top">
    <input
      *ngIf="parcelPrice.parcel"
      [(ngModel)]="parcelPrice.expertOpinionCompensationPriceDefault"
      (ngModelChange)="computeParcelPrice()"
      gmtInputNumber
      type="text"
    />

    <div *ngIf="parcelPrice.parcel && hasCoefficient">
      <div (click)="toggleMultiply('compensation'); computeParcelPrice()">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.compensationMultiply"></gmt-checkbox> Násobit koeficientem
      </div>
      <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient == COEFFICIENT_8 ? parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient = COEFFICIENT_1 : parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient = COEFFICIENT_8; computeParcelPrice()">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient == COEFFICIENT_8"></gmt-checkbox> koeficient 8
      </div>
      <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient == COEFFICIENT_15 ? parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient = COEFFICIENT_1 : parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient = COEFFICIENT_15; computeParcelPrice()">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.compensationCoefficient == COEFFICIENT_15"></gmt-checkbox> koeficient 1,5
      </div>
    </div>
  </td>

  <!-- cena porost -->
  <td *ngIf="opinion.expertOpinionVegetation" class="top">
    <input
      *ngIf="parcelPrice.parcel"
      [(ngModel)]="parcelPrice.expertOpinionVegetationPriceDefault"
      (ngModelChange)="computeParcelPrice();"
      gmtInputNumber
      type="text"
    />

    <div *ngIf="parcelPrice.parcel && hasCoefficient" >
      <div (click)="toggleMultiply('vegetation'); computeParcelPrice()">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.vegetationMultiply"></gmt-checkbox> Násobit koeficientem
      </div>
      <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient == COEFFICIENT_8 ? parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient = COEFFICIENT_1 : parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient = COEFFICIENT_8; computeParcelPrice(parcelPrice)">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient == COEFFICIENT_8"></gmt-checkbox> koeficient 8
      </div>
      <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient == COEFFICIENT_15 ? parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient = COEFFICIENT_1 : parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient = COEFFICIENT_15; computeParcelPrice(parcelPrice)">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.vegetationCoefficient == COEFFICIENT_15"></gmt-checkbox> koeficient 1,5
      </div>
    </div>
  </td>

  <!-- cena stavby -->
  <td *ngIf="opinion.expertOpinionBuildings" class="top">
    <input
      [(ngModel)]="parcelPrice.expertOpinionBuildingsPriceDefault"
      (ngModelChange)="computeParcelPrice();"
      gmtInputNumber
      type="text"
    />

    <div *ngIf="hasCoefficient">
      <div (click)="toggleMultiply('buildings'); computeParcelPrice()">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.buildingsMultiply"></gmt-checkbox> Násobit koeficientem
      </div>
      <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient == COEFFICIENT_8 ? parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient = COEFFICIENT_1 : parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient = COEFFICIENT_8; computeParcelPrice(parcelPrice)">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient == COEFFICIENT_8"></gmt-checkbox> koeficient 8
      </div>
      <div (click)="parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient == COEFFICIENT_15 ? parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient = COEFFICIENT_1 : parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient = COEFFICIENT_15; computeParcelPrice(parcelPrice)">
        <gmt-checkbox [gmtChecked]="parcelPrice.expertOpinionCoefficientsFlatten.buildingsCoefficient == COEFFICIENT_15"></gmt-checkbox> koeficient 1,5
      </div>
    </div>
  </td>

  <!-- celkova cena -->
  <td *ngIf="!hasCoefficient || opinion.occupationType === 'P'" class="top" style="padding-top: 17px;">
    {{ parcelPrice.expertOpinionParcelPriceDefault | number | price }}
  </td>

  <!-- celkova cena vc. koeficientu -->
  <td *ngIf="hasCoefficient" class="top" style="padding-top: 17px;">
    {{ (((parcelPrice.expertOpinionCompensationPrice * 1) + (parcelPrice.expertOpinionLandPriceCoefficient * 1) + (parcelPrice.expertOpinionVegetationPrice * 1) + (parcelPrice.expertOpinionBuildingsPrice * 1)) || 0) | number | price }}
  </td>
</ng-container>
