﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { RoundingPrecisionTypeEnum, priceRoundingPrecisionTypeOptions } from '@app/ps/enums/rounding-precision-type.enum';
/**
 * Display name precision type
 */
@Pipe({
  name: 'priceRoundingPrecisionTypeName',
})
@Injectable({
  providedIn: 'root'
})
export class PriceRoundingPrecisionTypeNamePipe implements PipeTransform {

  constructor(
  ) {}


  transform(type: RoundingPrecisionTypeEnum) {
    if (!type) {
        return null;
    }

    const o = priceRoundingPrecisionTypeOptions.find(o => o.id == type);
    return o ? o.value : null;
  }
}
