import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { CaseModel } from '@app/ps/models/case.model';
import { SubjectModel } from '@app/common/models/subject.model';

export class CaseSubjectModel {
  subject: SubjectModel;
  actingPersons: ActingPersonModel[];
  paymentType: string;
  variableSymbol: string;
  specificSymbol: string;
  constantSymbol: string;
  accountNumber: string;
  bankCode: string;
  easementPrice: number;
  priceInWords: string;
  businessRegisterEntry: string;
  businessRegisterSection: string;
  businessRegisterRegionalCourt: Object;
  maritalStatus: Object;
  dic: string;
  identificationNumber: string;
  id: number;
  phoneNumber: string;
  email: string;
  databoxId: string;
  personalIdentificationNumber: string;
  dateOfBirth: string;
  sentDate: string;
  undeliveredDate: string;
  receivedDate: string;
  signedDate: string;
  responseReceivedDate: string;
  declinedDate: string;
  personalVisitDate: string;
  signatureVerificationPrice: number;
  bonusPrice: number;
  mestskaCast: string;
  ulice: string;
  cisloDomovni: number;
  cisloOrientacni: string;
  castObce: string;
  obec: string;
  okres: string;
  psc: string;
  stat: string;
  kodStatu: string;
  kodStatuAl2: string;
  dorucovaciUse: boolean;
  dorucovaciMestskaCast: string;
  dorucovaciUlice: string;
  dorucovaciCisloDomovni: number;
  dorucovaciCisloOrientacni: string;
  dorucovaciCastObce: string;
  dorucovaciObec: string;
  dorucovaciOkres: string;
  dorucovaciPsc: string;
  dorucovaciStat: string;
  dorucovaciKodStatu: string;
  dorucovaciKodStatuAl2: string;
  invoicePayment: boolean;
  businessCase: CaseModel;
  vatPayer: boolean;
  mestskyObvod: string;
  dorucovaciMestskyObvod: string;
  gender: Object;
  contactPersonTitleBefore: string;
  contactPersonFirstName: string;
  contactPersonSurname: string;
  contactPersonDegreeAfter: string;
  contactPersonFunction: string;
  contactPersonPhoneNumber: string;
  contactPersonEmail: string;
  acceptedGeometricalPlanPossibleDate?: Date;
  dunningLetterDate?: Date;
  callBeforeExpropriationDate?: Date;
  callBeforeExpropriationReceivedDate?: Date;
  expropriationCaseInProgressDate?: Date;
  expropriationCaseStoppedDate?: Date;
  expropriationCaseFinishedDate?: Date;
  fulfillmentPrice?: any;
  fulfillmentPriceSjm?: any;
}
