<h1 class="centered-text">Probíhá tisk přehledu {{ "ps.projectGenitive" | brandTranslate | lowercase }} do PDF</h1>

<div class="mt-20 mb-20 text-center">
  Probíhá tisk přehledu {{ "ps.projectGenitive" | brandTranslate | lowercase }} do PDF.
  <br>
  <br>
  Operace může trvat i několik minut, <strong>prosíme vyčkejte a nezavírejte tento dialog</strong>.
</div>

<div [loading]="true"></div>

<div class="actions">
  <gmt-button class="red" [clickAction]="onCancel">Zrušit</gmt-button>
</div>
