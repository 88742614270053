import { Component, Inject, OnInit } from '@angular/core';

import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'map-module-print-download',
  templateUrl: './map-module-print-download.component.html',
})
export class MapModulePrintDownloadComponent implements OnInit {
  request: any;
  opened = true;

  constructor(
    private restangular: Restangular,
    private config: DialogConfig,
    private dialog: DialogRef,
  ) {
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    this.request = (<DialogConfigData> this.config.data).request;

    this.restangular.all('map/project-pdf')
      .withHttpConfig({responseType: 'blob'})
      .customPOST(this.request)
      .toPromise()
      .then((res) => {
        this.dialog.close(res);
      });
  }

  onCancel() {
    this.dialog.close(false);
  }
}

