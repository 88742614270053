<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>seznam parcel</a>
      <div class="actions">
        <a uiSref="symap.project.map" [uiParams]="{ parcel: parcel.id }" class="button"><i class="fa fa-map-marker"></i>Zobrazit v mapovém okně</a>
        <a *ngIf="parcel.cuzk" href="{{ parcel.cuzk }}" class="button" target="_blank"><i class="cuzk"></i>Nahlížení do katastru</a>
        <a *ngIf="parcel.title?.id" uiSref="symap.project.titles.detail" [uiParams]="{ id: parcel.title.id, tab: null }" class="button"><i class="fa fa-list-alt"></i>Náhled listu vlastnictví</a>
      </div>
    </div>

    <h1>Parcela {{ parcel | parcelnumber }}
      <span>{{ parcel.cislNazev }}</span>
      <span *ngIf="parcel.endDate" class="data-ended"> / Data platná do aktualizace KN {{parcel.endDate| date: 'd.M.yyyy'}}</span>
      <span *ngIf="cadastreDataDate"> / Data KN aktuální k {{cadastreDataDate|date: 'd.M.yyyy'}}</span>
    </h1>
    <div class="case-status" *ngIf="parcel.knBudouc">
      <a *ngIf="parcel.knBudouc.knGp.geometricPlanRequest && geometricPlanModuleEnabled; else withoutLink"
         uiSref="symap.project.geometricPlanRequests.detail"
         [uiParams]="{ id: parcel.knBudouc.knGp.geometricPlanRequest.id, tab: 'parcels-budouc' }"
      >GP č. {{ parcel.knBudouc.knGp.cis }}</a>
      <ng-template #withoutLink>Geometrický plán č. {{ parcel.knBudouc.knGp.cis }}</ng-template>
    </div>

    <div class="case-top mt-30 mb-70">
      <div class="flex-rest">
        <div class="column-50">
          <label>Katastrální území</label>
          <div class="info">
            {{ parcel.area.name }}
          </div>
          <label>List vlastnictví</label>
          <div class="info">
            <a *ngIf="parcel.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: parcel.title.id, tab: null }" title="{{ parcel.title.area.name }}">
              {{ parcel.title.lv }}
            </a>
            <span *ngIf="!parcel.title">parcela není zapsána na LV</span>
          </div>
          <label>Výměra [<span class="unit">m<sup>2</sup></span>]</label>
          <div class="info">
            {{ parcel.vymera }}
          </div>
        </div>
        <div class="column-50">
          <label>Druh pozemku</label>
          <div class="info">
            {{ parcel.drupozNazev }}
          </div>
          <label>Ochrana</label>
          <div class="info">
            {{ parcel.parcelProtectionsText }}
          </div>
          <ng-container *ngIf="parcel.bpejList.length > 0">
            <label>BPEJ</label>
            <div class="info">
              <span *ngFor="let itemBpej of parcel.bpejList" class="pr-5">{{ itemBpej.bpejKod }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
        <div *ngIf="tab === 'occupations'">
          <div [loading]="occupationList.loading">
            <gmt-labeled-block *ngIf="occupationGroups?.permanent" [collapsed]="false" label="{{ 'PERMANENT_OCCUPATION' | wordtranslate }}">
              <table-occupations-permanent updateOccupationParcelPath=".updateOccupationParcel" [data]="occupationGroups.permanent" [columns]="occupationPermanentColumns"></table-occupations-permanent>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="occupationGroups?.permanentWithSolutionType" [collapsed]="false" label="Trvalý zábor bez výkupu (nájem)">
              <table-occupations-temporary updateOccupationParcelPath=".updateOccupationParcel" [data]="occupationGroups.permanentWithSolutionType" [columns]="permanentWithSolutionTypeColumns"></table-occupations-temporary>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="occupationGroups?.underOneYear" [collapsed]="false" [label]="occupationUnderYearTableName">
              <table-occupations-temporary updateOccupationParcelPath=".updateOccupationParcel" [data]="occupationGroups.underOneYear" [columns]="occupationUnderOneYearColumns"></table-occupations-temporary>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="occupationGroups?.overOneYear" [collapsed]="false" [label]="occupationOverYearTableName">
              <table-occupations-temporary updateOccupationParcelPath=".updateOccupationParcel" [data]="occupationGroups.overOneYear" [columns]="occupationOverOneYearColumns"></table-occupations-temporary>
            </gmt-labeled-block>
          </div>
        </div>
        <div *ngIf="tab === 'easements'">
          <div [loading]="easementList.loading">
            <gmt-labeled-block *ngIf="easementList.list && easementList.list.length > 0" [collapsed]="false" label="{{ 'EASEMENT' | wordtranslate }}">
              <table-easements [data]="easementList.list" [columns]="easementColumns"></table-easements>
            </gmt-labeled-block>
          </div>
        </div>
        <div *ngIf="tab === 'tenant'">
          <div class="edit" *ngIf="canEdit()">
            <gmt-button (click)="tenantEdit = !tenantEdit">{{ tenantEdit ? 'Přehled' : 'Upravit údaje' }}</gmt-button>
          </div>
          <parcel-tenant *ngIf="!tenantEdit" [parcel]="parcel"></parcel-tenant>
          <parcel-tenant-edit-form *ngIf="tenantEdit" [parcel]="parcel"></parcel-tenant-edit-form>
        </div>
        <div *ngIf="tab === 'cases'">
          <table-cases
            [loading]="caseList.loading"
            [list]="caseList"
            (sort)="onCaseTableSort($event)"
          ></table-cases>
        </div>
        <div *ngIf="tab === 'lpis'">
          <div class="column-100 mb-20">
            <label>Data LPIS aktualizovaná k:</label>
            <div class="info">
              {{ parcel.lpisBloky[0].platnostK | date }}
            </div>
          </div>
          <div *ngFor="let blok of parcel.lpisBloky" class="column-50 mb-20">
            <label>Národní kód DPB</label>
            <div class="info">
              {{ blok.nkodfb }}
            </div>
            <label>Zkrácený kód DPB</label>
            <div class="info">
              {{ blok.zkodfb }}
            </div>
            <label>Uživatel</label>
            <div class="info">
              {{{
                nazev: blok.lpisUzivatel.obchodniJmeno,
                titulZaJmenem: blok.lpisUzivatel.titulZaJmenem,
                jmeno: blok.lpisUzivatel.jmeno,
                prijmeni: blok.lpisUzivatel.prijmeni,
                titulPredJmenem: blok.lpisUzivatel.titulPredJmenem
              } | subjectName}}
            </div>
            <label>Adresa</label>
            <div class="info">
              {{{
                street: blok.lpisUzivatel.ulice,
                houseNumber: blok.lpisUzivatel.cisloPopisne,
                streetNumber: blok.lpisUzivatel.cisloOrientacni,
                neighbourhood: blok.lpisUzivatel.castObce,
                cpCe: 1,
                postCode: blok.lpisUzivatel.psc,
                municipality: blok.lpisUzivatel.obec,
                district: null
              } | fulladdress }}
            </div>
            <label>IČO</label>
            <div class="info">
              {{ blok.lpisUzivatel.ico }}
            </div>
            <label>Telefon</label>
            <div class="info">
              {{ blok.lpisUzivatel.telefon }}
            </div>
          </div>
        </div>
        <div *ngIf="tab === 'buildings'">
          <gmt-labeled-block label="Budova">
            <div class="bordered" [loading]="loading">
              <div *ngIf="!loading && !parcel.building" class="empty-info">Žádné budovy</div>
              <table *ngIf="!loading && parcel.building">
                <thead>
                <tr>
                  <th>LV budovy</th>
                  <th>Část obce</th>
                  <th>Číslo budovy</th>
                  <th>Typ a Využití</th>
                  <th>Případy</th>
                  <th>Cena [<span class="unit">Kč</span>]</th>
                </tr>
                </thead>
                <tbody class="hover">
                <tr *ngFor="let itemBuilding of [parcel.building];">
                  <td class="number">
                    <span *ngIf="!itemBuilding.title">{{ itemBuilding.cisloTel }}</span>
                    <a *ngIf="itemBuilding.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: itemBuilding.title.id, tab: null }">{{ itemBuilding.title.lv }}</a>
                  </td>
                  <td>{{ itemBuilding.castobce }}</td>
                  <td>{{ itemBuilding | buildingNumber:true }}</td>
                  <td>{{ itemBuilding.typ }}<br>{{ itemBuilding.vyuziti }}</td>
                  <td class="no-padding cell-max-height">
                    <cell-cases
                      [casesCount]="itemBuilding.countCases"
                      [tooltipType]="'cases'"
                      [tooltipData]="{ filter: { filters: { buildingId: itemBuilding.id, cancelStatus: 'notCancelled' }}}"
                    ></cell-cases>
                  </td>
                  <td>{{ itemBuilding.buildingsPrice | number | price : false }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </gmt-labeled-block>
        </div>
        <div *ngIf="tab === 'notes'">
          <gmt-notes
            [noteList]="noteList"
            [editable]="true"
            [allowType]="true"
            [defaultData]="{ parcel: { id: parcel.id }, systemType: false }"
            (updated)="onUpdateNoteList();loadHistory();"
          ></gmt-notes>
        </div>
        <div *ngIf="tab === 'costs'">
          <parcel-costs-form [parcel]="parcel"></parcel-costs-form>
          <div class="centered-text">
            <gmt-button [clickAction]="onSubmitCosts">Uložit údaje</gmt-button>
          </div>
        </div>
        <div *ngIf="tab === 'documents'">
          <gmt-documents
            [documentList]="documentList"
            [defaultData]="{idpar: parcel.idpar}"
            [authorizedUser]="canEdit()"
            [allowType]="false"
            documentCategory="parcel"
            (updated)="onUpdateDocumentList();loadHistory();"
          ></gmt-documents>
        </div>
        <div *ngIf="tab === 'history'">
          <gmt-notes
            [type]="'history'"
            [noteList]="historyList"
            [editable]="false"
            [allowType]="true"
          ></gmt-notes>
        </div>
      </gmt-navbar>
    </div>
  </div>
</div>
