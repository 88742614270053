import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';
import { CaseSubjectModel} from "@app/ps/models/case-subject.model";
import { APP_BRAND } from "@app/common/services/config.service";
import {SwitchOption} from "@app/common/components/switch/switch.component";
import {CallbackModel} from "@app/common/models/callback.model";

@Component({
  selector: 'sign-owner',
  templateUrl: './sign-owner.component.html',
  styleUrls: ['./sign-owner.component.scss']
})
export class SignOwnerComponent implements OnInit {
  @Input() caseId: number;
  @Input() callbacks: CallbackModel;

  loading = true;
  data: CaseModel;
  name: string;
  enabledSubjects = {};
  alreadySigned = 0;
  deliveredInTime = null;
  regularPriceObligation = false;
  contractRegisterPublication: string;

  statusData = {
    caseSubjects: null,
    responseReceivedDate: new Date(),
    contractRegisterPublication: null,
  };

  subjectsData = {
    dataCaseOwnerships: null,
    caseOwnerships: null,
    caseSubjects: null,
  };

  contractRegisterPublicationOptions: SwitchOption[] = [
    { id: 'N', value: 'Bez zveřejnění' },
    { id: 'Y', value: 'Zveřejnit' },
  ];

  constructor(
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
    private caseService: CaseService,
    private stateService: StateService,
    private datePipe: DatePipe,
    @Inject(APP_BRAND) private APP_BRAND: any,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.isStatusValid = this.isStatusValid.bind(this);
    this.onSubjectSignDateChanged = this.onSubjectSignDateChanged.bind(this);
    this.onResponseReceivedDataChanged = this.onResponseReceivedDataChanged.bind(this);
    this.signatureVerificationPriceEnabled = this.signatureVerificationPriceEnabled.bind(this);
    this.isSubjectSigned = this.isSubjectSigned.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get({loadCollections: ['caseSubjects', 'caseOwnerships']}).toPromise();
    this.name = (await this.caseStatusService.loadStatusName('SignedOwner')).name;

    this.statusData.caseSubjects = this.data.caseSubjects.filter(
      (caseSubject) => caseSubject.subject.opsubType !== 'BSM' &&
        this.data.caseOwnerships.some((caseOwnership) => {
          let bsmSubject;
          if (caseOwnership.ownership.isBsm) {
            bsmSubject = this.data.caseSubjects.find((cs) => cs.subject.opsubId === caseOwnership.ownership.opsubId).subject;
          }
          return !caseOwnership.isParent && ((!bsmSubject && caseOwnership.ownership.opsubId === caseSubject.subject.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === caseSubject.subject.opsubId || bsmSubject.partner2OpsubId === caseSubject.subject.opsubId)));
        })
    );

    this.statusData.caseSubjects.forEach((item) => {
      this.enabledSubjects[item.id] = !item.signedDate;
      if (item.signatureVerificationPrice === null && this.signatureVerificationPriceEnabled()) {
        item.signatureVerificationPrice = 0;
      }
    });

    this.alreadySigned = this.statusData.caseSubjects.filter(subject => subject.signedDate).length;
    this.onResponseReceivedDataChanged();

    // subjects part
    this.regularPriceObligation = this.APP_BRAND.NAME === 'RSD' && this.priceObligationType();
    this.subjectsData.caseOwnerships = _.cloneDeep(this.data.caseOwnerships);
    this.subjectsData.caseSubjects = _.cloneDeep(this.data.caseSubjects);
    this.subjectsData.dataCaseOwnerships = this.caseService.getDataCaseOwnershipsUnique(this.subjectsData.caseOwnerships, this.subjectsData.caseSubjects);

    if (this.data.contractRegisterPublication !== undefined) {
      this.contractRegisterPublication = this.data.contractRegisterPublication ? 'Y' : 'N';
    }
    this.loading = false;
  }

  onSubjectSignDateChanged(signedDate: string) {
    if ((this.data.bonusDate || this.dueDateCompute())) {
      this.checkBonus();
    }
  }

  onResponseReceivedDataChanged() {
    if (this.data.validityDate || this.data.bonusDate || this.dueDateCompute()) {
      this.checkBonus();
      if (this.statusData.responseReceivedDate && this.data.validityDate) {
        this.deliveredInTime = +new Date(this.data.validityDate) >= +new Date(this.statusData.responseReceivedDate);
      } else {
        this.deliveredInTime = false;
      }
    }
  }

  isSubjectSigned(caseSubject: CaseSubjectModel) {
    return this.statusData.caseSubjects.find(cs => cs.id === caseSubject.id).signedDate;
  }

  isSubjectsValid() {
    if (this.APP_BRAND.NAME !== 'RSD') {
      return true;
    }
    for (const caseOwnership of this.subjectsData.dataCaseOwnerships) {
      if (caseOwnership.errors && caseOwnership.errors.length) {
        return false;
      }
      for (const caseSubject of caseOwnership.caseSubjects) {
        if (this.isSubjectSigned(caseSubject) && caseOwnership.requirements && caseOwnership.requirements.length) {
          return false;
        }
      }
    }

    // pokud jsou vsechny podpisy, je potreba vyplnit zverejneni
    return this.statusData.caseSubjects.length !== this.currentlySigned().length || this.contractRegisterPublication !== undefined;
  }

  isStatusValid() {
    return this.statusData.caseSubjects.filter(subject => subject.signedDate).length > this.alreadySigned;
  }

  currentlySigned() {
    return this.statusData.caseSubjects.filter(subject => subject.signedDate);
  }

  signatureVerificationPriceEnabled() {
    const whitelist = [
      'AccessToLandLiability',
      'AgreementOnAFutureNetworkConstructionContractLiability',
      'AgreementOnAFutureTransferContractLiability',
      'AgreementOnAOtherFutureContractLiability',
      'BuildingPlacementLiability',
      'BorrowingLiability',
      'OtherLiability',
      'OwnersStatementLiability',
      'RightToRealizeABuildingLiability',
      'FutureRealBurdenLiabilityAccelerated',
    ];

    return !whitelist.includes(this.data.obligation.type);
  }

  priceObligationType() {
    const whitelist = [
      'SalesContractLiability',
      'GratuitousTransferContractLiability',
      'NonGratuitousTransferContractLiability',
      'RentalContractShorterThan1YearLiability',
      'RentalContractLongerThan1YearLiability',
      'GiftContractLiability',
      'RealBurdenLiability',
      'RealBurdenLiabilityAccelerated',
      'RentalContractCommonLiability',
      'FutureRealBurdenLiabilityAccelerated'
    ];

    return whitelist.includes(this.data.obligation.type);
  }

  isFulfillmentPriceValid(): boolean {
    if (this.statusData.caseSubjects.length !== this.currentlySigned().length) {
      return true;
    }

    let price = 0;

    for (const caseOwnership of this.subjectsData.dataCaseOwnerships) {
      if (caseOwnership.ownership.isBsm && caseOwnership.singlePayment) {
        price += caseOwnership.caseSubjects[0].fulfillmentPriceSjm || 0;
      } else {
        for (const caseSubject of caseOwnership.caseSubjects) {
          price += (caseOwnership.ownership.isBsm ? caseSubject.fulfillmentPriceSjm : caseSubject.fulfillmentPrice) || 0;
        }
      }
    }

    return price === this.data.fulfillmentPrice;
  }

  async onSubmit(): Promise<any> {
    if (_.some(this.statusData.caseSubjects, (item) => {
      return item.signedDate && (!this.statusData.responseReceivedDate || (item.signatureVerificationPrice && !isFinite(item.signatureVerificationPrice)));
    })) {
      return;
    }

    this.caseService.fillCaseOwnershipsByMain(this.subjectsData.dataCaseOwnerships, this.subjectsData.caseOwnerships);

    await this.restangular
      .one('cases', this.caseId)
      .all('ownerships-subjects')
      .customPUT({
        caseOwnerships: this.subjectsData.caseOwnerships,
        caseSubjects: this.subjectsData.caseSubjects,
      })
      .toPromise();

    if (this.contractRegisterPublication !== undefined) {
      this.statusData.contractRegisterPublication = this.contractRegisterPublication === 'Y';
    }

    await this.restangular
      .one('cases', this.caseId)
      .customPUT(this.statusData, 'sign-owner')
      .toPromise();

    this.stateService.go('^');
  }

  private dueDateCompute() {
    const duePeriod = this.data.obligation.duePeriod;
    const dueDataFromType = this.data.obligation.dueDateFromType;
    return !this.data.paymentOrderDate && duePeriod && (dueDataFromType === 'ResponseReceivedSignedDate' || dueDataFromType === 'SignedAllOwnersDate');
  }

  private checkBonus() {
    let allSigned = false;
    let lastSignedDate = null;

    if ((this.data.bonusDate || this.dueDateCompute()) && this.statusData.responseReceivedDate) {
      allSigned = true;
      _.forEach(this.statusData.caseSubjects, (item) => {
        if (!item.signedDate) {
          allSigned = false;
        } else if (lastSignedDate === null || +lastSignedDate < +new Date(item.signedDate)) {
          lastSignedDate = new Date(item.signedDate);
        }
        return allSigned;
      });
    }

    if (this.data.bonusDate && this.statusData.responseReceivedDate) {
      this.data.bonusGranted = this.data.bonusPossible && allSigned && +new Date(this.data.bonusDate) >= +new Date(this.statusData.responseReceivedDate);
    } else {
      this.data.bonusGranted = false;
    }

    if (this.dueDateCompute()) {
      const duePeriod = this.data.obligation.duePeriod;
      const dueDataFromType = this.data.obligation.dueDateFromType;

      if (this.statusData.responseReceivedDate && allSigned) {
        const date = dueDataFromType === 'ResponseReceivedSignedDate' ? new Date(this.statusData.responseReceivedDate) : lastSignedDate;
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + duePeriod);
        this.data.dueDate = this.datePipe.transform(newDate, 'yyyy-MM-dd');
      } else {
        this.data.dueDate = null;
      }
    }
  }
}
