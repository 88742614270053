<div class="bordered" *ngIf="list.itemCount">
  <div class="table">
    <div class="head-group sticky">
      <div class="row">
        <span class="cell" *ngIf="isChecked"><gmt-checkbox [gmtChecked]="inverseSelection" (click)="onCheckAll()"></gmt-checkbox></span>
        <span *ngIf="!hideColumn('caseNumber')" class="cell">
          <sort-by [selected]="'caseNumber' === list.filter.sortOrder.sortBy" (sort)="onSort('caseNumber', $event)"
                   [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('caseNumber')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('internalCaseNumber')" class="cell">
          <sort-by [selected]="'internalCaseNumber' === list.filter.sortOrder.sortBy" (sort)="onSort('internalCaseNumber', $event)"
                   [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('internalCaseNumber')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('areaName')" class="cell">
          <sort-by [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[1].sortBy === 'minAreaName'" (sort)="onSort(['areaCount', 'minAreaName'], $event)" [sortDir]="list.filter.sortOrder[0]?.direction">
            {{getColumnName('areaName')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('lv')" class="cell">
          <sort-by [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[1].sortBy === 'minTitle'" (sort)="onSort(['titleCount', 'minTitle'], $event)" [sortDir]="list.filter.sortOrder[0]?.direction">
            {{getColumnName('lv')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('owner')" class="cell">
          <sort-by [selected]="list.filter.sortOrder.sortBy === 'minSubjectName'" (sort)="onSort('minSubjectName', $event)" [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('owner')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('caseType')" class="cell">
          <sort-by [selected]="'obligation.name' === list.filter.sortOrder.sortBy" (sort)="onSort('obligation.name', $event)" [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('caseType')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('caseStatus')" class="cell">
          <sort-by [selected]="'caseStatus.name' === list.filter.sortOrder.sortBy" (sort)="onSort('caseStatus.name', $event)" [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('caseStatus')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('respectiveDate')" class="cell">
          <sort-by [selected]="'statusRespectiveDate' === list.filter.sortOrder.sortBy" (sort)="onSort('statusRespectiveDate', $event)" [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('respectiveDate')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('constructionObjects')" class="cell">{{getColumnName('constructionObjects')}}</span>
        <span *ngIf="!hideColumn('price')" class="cell">
          <sort-by [selected]="'price' === list.filter.sortOrder.sortBy" (sort)="onSort('price', $event)" [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('price')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('mandatary')" class="cell">{{getColumnName('mandatary')}}</span>
        <span *ngIf="!hideColumn('officer')" class="cell">{{getColumnName('officer')}}</span>
        <span *ngIf="!hideColumn('titleUser')" class="cell">{{getColumnName('titleUser')}}</span>
      </div>
    </div>
    <div class="row-group">
      <a class="row" *ngFor="let case of list.list" (click)="onToggleCheckItem(case)" [uiSref]="isChecked ? undefined : 'symap.project.cases.detail'" [uiParams]="{id: case.id}">
        <span *ngIf="isChecked" class="cell text-center"><gmt-checkbox [gmtChecked]="isChecked(case) !== inverseSelection"></gmt-checkbox></span>
        <span *ngIf="!hideColumn('caseNumber')" class="cell">
          <div class="cell--flex__container">
            <div class="cell--flex__container__main">
              <a *ngIf="isChecked" uiSref="symap.project.cases.detail" [uiParams]="{id: case.id}">{{ case.mpp.number || '' }}</a>
              <span *ngIf="!isChecked || showNavIcons">{{ case.mpp.number || '' }}</span>
            </div>
            <div class="cell--flex__container_side">
              <span title="Sankce" class="tag tag--danger tag--small is-uppercase ml-5" *ngIf="case.ssEU || case.ssNonEU || case.ssRuskyVliv">Sankce</span>
              <ng-container *ngIf="showNavIcons">
                <a class="pl-5 pt-5" uiSref="symap.project.cases.detail" [uiParams]="{projectKey: customProjectKey, id: case.id}" target="_blank" title="Zobrazit detail akce" (click)="$event.stopPropagation()"><i class="fa fa-eye"></i></a>
                <a class="pl-5 pt-5" uiSref="symap.project.map" [uiParams]="{projectKey: customProjectKey, businessCase: case.id, layer: ['geom_ku', 'kn_latest']}" target="_blank" title="Zobrazit v mapovém okně" (click)="$event.stopPropagation()"><i class="fa fa-map"></i></a>
              </ng-container>
            </div>
          </div>
        </span>
        <span *ngIf="!hideColumn('internalCaseNumber')" class="cell">{{ case.internalCaseNumber || '' }}</span>
        <span *ngIf="!hideColumn('areaName')" class="cell">{{ case.titles | titleAreaNames }}</span>
        <span *ngIf="!hideColumn('lv')" class="cell number">
          <a *ngIf="case.titles.length === 1; else manyTitlesBlock" uiSref="symap.project.titles.detail" [uiParams]="{ id: case.titles[0].id, tab: null }" (click)="$event.stopPropagation()">
            {{case.titles[0].lv}}
          </a>
          <ng-template #manyTitlesBlock>
            {{ case.titles | titleNames }}
          </ng-template>
        </span>
        <span *ngIf="!hideColumn('owner')" class="cell no-padding cell-max-height">
          <cell-ownership
            [count]="case.countOwnerships"
            [ownerships]="case.caseOwnerships"
            [tooltipData]="{filter: {sortOrder: {sortBy: 'subjectName'},filters: {caseId: case.id}}, attributes: {subject: {load: false}}}"
            [tooltipType]="'ownership-list'"
          ></cell-ownership>
        </span>
        <span *ngIf="!hideColumn('caseType')" class="cell">{{ case.obligation.name }}</span>
        <span *ngIf="!hideColumn('caseStatus')" class="cell">
          <ng-container *ngIf="caseStatusType === 'normal'">
            <div>{{ case.caseStatus.name }}</div>
            <div *ngIf="case.caseStatusSubjects" class="comma-list">
              <span *ngFor="let subject of case.caseStatusSubjects">{{subject | subjectName}}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="caseStatusType === 'progress'">
            <cell-case-subjects [caseSubjects]="case.caseSubjects" [caseStatus]="case.caseStatus" [lastCaseChangeDate]="case.statusRespectiveDate"></cell-case-subjects>
          </ng-container>
        </span>
        <span *ngIf="!hideColumn('respectiveDate')" class="cell centered-text">{{ case.statusRespectiveDate| date }}</span>
        <span *ngIf="!hideColumn('constructionObjects')" class="cell no-padding">
          <cell-construction-objects
            [constructionObjects]="case.constructionObjects"
            [tooltipType]="'construction-objects'"
            [tooltipData]="{ constructionObjects: case.constructionObjects }"
          ></cell-construction-objects>
<!--          <gmt-construction-object-list [constructionObjects]="case.constructionObjects"></gmt-construction-object-list>-->
        </span>
        <span *ngIf="!hideColumn('price')" class="cell number">
          {{ case.opinionPricesChanged ? 'Změněno' : '' }}
          {{ case.price | number | price }}
        </span>
        <span *ngIf="!hideColumn('mandatary')" class="cell">
          {{case.mandatary | username }}
        </span>
        <span *ngIf="!hideColumn('officer')" class="cell">
          {{case.officer | username }}
        </span>
        <span *ngIf="!hideColumn('titleUser')" class="cell">
          {{case.titles | titleUserUniqueNames }}
        </span>
      </a>
    </div>
  </div>
</div>
<div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
  Nenalezena žádná data
</div>
