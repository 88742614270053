import {Component, Inject, Input, OnInit} from '@angular/core';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';
import { CaseModel } from "@app/ps/models/case.model";
import * as _ from 'lodash';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'expropriation-enforceable',
  templateUrl: './expropriation-enforceable.component.html',
  styleUrls: ['./expropriation-enforceable.component.scss']
})
export class ExpropriationEnforceableComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  statusData = {
    expropriationEnforceableDate: new Date(),
    contractRegisterPublication: null,
  };
  subjectsData = {
    dataCaseOwnerships: null,
    caseOwnerships: null,
    caseSubjects: null,
  };
  name: string;

  contractRegisterPublicationOptions: SwitchOption[] = [
    { id: 'N', value: 'Bez zveřejnění' },
    { id: 'Y', value: 'Zveřejnit' },
  ];
  contractRegisterPublication: string;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
    @Inject(APP_BRAND) private APP_BRAND: any,
  ) {
    this.onConfirm = this.onConfirm.bind(this);
    this.isSubjectSigned = this.isSubjectSigned.bind(this);
    this.isStatusValid = this.isStatusValid.bind(this);
  }

  async ngOnInit() {
    this.name = (await this.caseStatusService.loadStatusName('ExpropriationEnforceable')).name;
    this.data = await this.restangular.one('cases', this.caseId).get({loadCollections: ['caseSubjects', 'caseOwnerships']}).toPromise();

    // subjects part
    this.subjectsData.caseOwnerships = _.cloneDeep(this.data.caseOwnerships);
    this.subjectsData.caseSubjects = _.cloneDeep(this.data.caseSubjects);
    this.subjectsData.dataCaseOwnerships = this.caseService.getDataCaseOwnershipsUnique(this.subjectsData.caseOwnerships, this.subjectsData.caseSubjects);

    this.loading = false;
  }

  async onConfirm(): Promise<any> {
    this.caseService.fillCaseOwnershipsByMain(this.subjectsData.dataCaseOwnerships, this.subjectsData.caseOwnerships);

    await this.restangular
      .one('cases', this.caseId)
      .all('ownerships-subjects')
      .customPUT({
        caseOwnerships: this.subjectsData.caseOwnerships,
        caseSubjects: this.subjectsData.caseSubjects,
      })
      .toPromise();

    if (this.contractRegisterPublication !== undefined) {
      this.statusData.contractRegisterPublication = this.contractRegisterPublication === 'Y';
    }

    await this.restangular.one('cases', this.caseId).customPUT(this.statusData, 'expropriation-enforceable').toPromise();

    this.stateService.go('^');
  }

  isSubjectSigned() {
    return true;
  }

  isFulfillmentPriceValid(): boolean {
    let price = 0;

    for (const caseOwnership of this.subjectsData.dataCaseOwnerships) {
      if (caseOwnership.ownership.isBsm && caseOwnership.singlePayment) {
        price += caseOwnership.caseSubjects[0].fulfillmentPriceSjm || 0;
      } else {
        for (const caseSubject of caseOwnership.caseSubjects) {
          price += (caseOwnership.ownership.isBsm ? caseSubject.fulfillmentPriceSjm : caseSubject.fulfillmentPrice) || 0;
        }
      }
    }

    return price === this.data.fulfillmentPrice;
  }

  isSubjectsValid() {
    if (this.APP_BRAND.NAME !== 'RSD') {
      return true;
    }

    for (const caseOwnership of this.subjectsData.dataCaseOwnerships) {
      if (caseOwnership.errors && caseOwnership.errors.length) {
        return false;
      }
      for (const caseSubject of caseOwnership.caseSubjects) {
        if (caseOwnership.requirements && caseOwnership.requirements.length) {
          return false;
        }
      }
    }

    return this.contractRegisterPublication !== undefined;
  }

  isStatusValid() {
    return this.statusData.expropriationEnforceableDate;
  }
}
