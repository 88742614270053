import { Injectable } from '@angular/core';
import { BuildingModel } from '@app/common/models/building.model';
import { ParcelPriceCoefficient, ParcelPriceModel, ExpertOpinionCoefficientsFlatten } from '@app/ps/models/parcel-price.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { RoundingService } from '@app/ps/services/rounding.service';
import { Decimal } from 'decimal.js';
import { DecimalPipe } from '@app/common/pipes/decimal.pipe';

@Injectable({
  providedIn: 'root'
})
export class ParcelPriceService {

  public static coefficientsKeys = ['1', '15', '8'];

  constructor(
    private roundingService: RoundingService,
    private decimalPipe: DecimalPipe,
  ) {}

  public multipleLandCoefficient(parcelPrice: ParcelPriceModel): boolean {
    return ParcelPriceService.coefficientsKeys
      .map(k => parcelPrice.expertOpinionCoefficientsFlatten[`land${k}Multiply`])
      .filter(i => i).length > 1;
  }

  public multipleLandCoefficientAreaMatches(parcelPrice: ParcelPriceModel): boolean {
    let area = new Decimal(0);
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;
    if (ParcelPriceService.coefficientsKeys.some(k => {
      if (coefficients[`land${k}Multiply`]) {
        const parcelArea = this.decimalPipe.transform(coefficients[`land${k}Area`]);
        if (parcelArea) {
          area = area.add(
            parcelArea
          );
        } else {
          return true;
        }
      }
    })) {
      // nalezena nevalidní výměra
      return false;
    };

    return area.eq(parcelPrice.parcel.vymera);
  }

  /**
  Výpočet ceny za pozemek se započtením koeficientu
  */
  public computeLandPriceCoefficient(parcelPrice: ParcelPriceModel, pricingMethod: 'A'|'M'): Decimal {
    if (!parcelPrice.parcel) {
      return new Decimal(0);
    }

    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    if (this.multipleLandCoefficient(parcelPrice)) {
      let landPriceCoefficient = new Decimal(0);
      ParcelPriceService.coefficientsKeys.forEach(k => {
        if (coefficients[`land${k}Multiply`]) {
          const price = this.computeLandPriceCoefficientForCoefficient(coefficients, pricingMethod, k);
          if (!price) return;
          landPriceCoefficient = landPriceCoefficient.add(
            price
          );
        }
      });

      return this.roundingService.roundProject(landPriceCoefficient);
    } else {
      let coefficient = 1;
      ParcelPriceService.coefficientsKeys.some(k => {
        if (coefficients[`land${k}Multiply`]) {
          coefficient = ParcelPriceCoefficient[`COEFFICIENT_${k}`];
          return true;
        }
      });

      if (pricingMethod == 'A') {
        const price = this.decimalPipe.transform(parcelPrice.expertOpinionLandAreaPrice);
        return price ? this.roundingService.roundProject(price.mul(coefficient)) : null;
      } else {
        const sqPrice = this.decimalPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
        return sqPrice ? this.roundingService.roundProject(this.roundingService.roundProject(sqPrice.mul(parcelPrice.parcel.vymera)).mul(coefficient)) : null;
      }
    }
  }

  public computeLandPriceCoefficientFromDefault(parcelPrice: ParcelPriceModel, pricingMethod: 'A'|'M'): Decimal {
    if (!parcelPrice.parcel) {
      return new Decimal(0);
    }

    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    if (this.multipleLandCoefficient(parcelPrice)) {
      let landPrice = this.computeLandPriceFromMultiple(coefficients, pricingMethod);
      let landPriceCoefficientByDefault = new Decimal(0);
      ParcelPriceService.coefficientsKeys.forEach(k => {
        if (coefficients[`land${k}Multiply`]) {
          const price = this.computeLandPriceCoefficientForCoefficient(coefficients, pricingMethod, k);
          if (!price) return null;
          landPriceCoefficientByDefault = landPriceCoefficientByDefault.add(
            new Decimal(parcelPrice.expertOpinionLandPriceDefault)
              .mul(price)
              .div(landPrice)
          );
        }
      });

      return this.roundingService.roundProject(landPriceCoefficientByDefault);
    } else {
      let coefficient = 1;
      ParcelPriceService.coefficientsKeys.some(k => {
        if (coefficients[`land${k}Multiply`]) {
          coefficient = ParcelPriceCoefficient[`COEFFICIENT_${k}`];
          return true;
        }
      });

      const price = this.decimalPipe.transform(parcelPrice.expertOpinionLandPriceDefault);
      return price ? this.roundingService.roundProject(price.mul(coefficient)) : new Decimal(0);
    }
  }

  public computeLandPriceDefault(parcelPrice: ParcelPriceModel, pricingMethod: 'A'|'M'): Decimal {
    if (!parcelPrice.parcel) {
      return new Decimal(0);
    }

    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    if (this.multipleLandCoefficient(parcelPrice)) {
      return this.computeLandPriceFromMultiple(coefficients, pricingMethod);
    } else {
      const squarePrice = this.decimalPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
      return pricingMethod === 'A' ?
        this.decimalPipe.transform(parcelPrice.expertOpinionLandAreaPrice)
        : (squarePrice ? this.roundingService.roundProject(squarePrice.mul(parcelPrice.parcel.vymera)) : null);
    }
  }

  public computeLandPriceFromMultiple(coefficients: ExpertOpinionCoefficientsFlatten, pricingMethod: 'A'|'M') : Decimal {
      let landPrice = new Decimal(0);
      ParcelPriceService.coefficientsKeys.forEach(k => {
        if (coefficients[`land${k}Multiply`]) {
          const price = this.computeLandPriceForCoefficient(coefficients, pricingMethod, k);
          if (!price) return;
          landPrice = landPrice.add(
            price
          );
        }
      });
      return this.roundingService.roundProject(landPrice);
  }

  private computeLandPriceForCoefficient(coefficients: ExpertOpinionCoefficientsFlatten, pricingMethod: 'A'|'M', coefficientKey: string) {
    if (pricingMethod == 'A') {
      const price = this.decimalPipe.transform(coefficients[`land${coefficientKey}Price`]);
      return price ? this.roundingService.roundProject(price) : null;
    } else {
      const sqPrice = this.decimalPipe.transform(coefficients[`land${coefficientKey}SquarePrice`]);
      const area = this.decimalPipe.transform(coefficients[`land${coefficientKey}Area`]);
      if (sqPrice && area) {
        return this.roundingService.roundProject(sqPrice.mul(area));
      } else {
        return null;
      }
    }
  }

  private computeLandPriceCoefficientForCoefficient(coefficients: ExpertOpinionCoefficientsFlatten, pricingMethod: 'A'|'M', coefficientKey: string) {
    const price = this.computeLandPriceForCoefficient(coefficients, pricingMethod, coefficientKey);
    if (!price) return null;
    return this.roundingService.roundProject(
      price.mul(ParcelPriceCoefficient[`COEFFICIENT_${coefficientKey}`])
    );
  }

  public buildingsMatch(building: BuildingModel, parcelPrice: ParcelPriceModel): boolean {
    return parcelPrice.building && parcelPrice.building.budId === building.budId;
  }

  public parcelsMatch(parcel: ParcelModel, parcelPrice: ParcelPriceModel): boolean {
    return parcelPrice.parcel && parcelPrice.parcel.idpar === parcel.idpar;
  }
}
