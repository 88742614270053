<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 120px;">
        <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>seznam případů</a>
      </div>
      <div class="actions">
        <action-buttons name="Možnosti">
          <a uiSref="symap.project.map" [uiParams]="{ businessCase: data.id }" class="bordered-bottom p-10"><i class="fa fa-map-marker"></i>Zobrazit v mapovém okně</a>
          <a uiSref="symap.project.map" [uiParams]="{ businessCase: data.id, tool: 'print-case' }" class="bordered-bottom p-10"><i class="fa fa-map"></i>Generovat mapovou přílohu smlouvy</a>

          <a href="{{ restBaseUrl }}/cases/{{ data.id }}/white-paper?t={{ authToken }}" class="bordered-bottom p-10">
            <i class="fa fa-download"></i>Informace o případu
          </a>

          <a *ngIf="allowCadastreEntryProposal" uiSref="symap.project.cases.detail.cadastreEntryProposal" class="bordered-bottom p-10">
            <i class="fa fa-files-o"></i>Generovat návrh na vklad do KN
          </a>

          <a *ngFor="let actionButton of actionButtons" (click)="actionButton.click ? actionButton.click() : void()" uiSref="{{ actionButton.click ? '' : ('symap.project.cases.detail.' + actionButton.key) }}" class="bordered-bottom p-10">
            <i class="fa {{ actionButton.icon }}"></i>{{ actionButton.name }}
          </a>
        </action-buttons>
      </div>
    </div>

    <h1>Složka případu {{ data.mpp.number }}
      <span *ngIf="isThreeParty()">&nbsp;/ třístranná</span>
      <span *ngIf="data.obligation.type === 'RentalContractShorterThan1YearLiability' || data.obligation.type === 'RentalContractLongerThan1YearLiability' || data.obligation.type === 'RentalContractCommonLiability'">&nbsp;/ {{ data.activeRental ? 'aktivní' : 'neaktivní' }}</span>
      <span *ngIf="data.bonusGranted">/ s akceleračním příplatkem</span>
      <span *ngIf="!data.bonusPossible && data.obligation.bonusPeriod">/ bez akceleračního příplatku</span>
    </h1>

    <span class="tag tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="data.ssEU">Sankční seznam EU</span>
    <span class="tag tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="data.ssNonEU">Sankční seznam mimo EU</span>
    <span class="tag tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="data.ssRuskyVliv">Ruský vliv</span>

    <div style="padding-top: 10px;">
      <span class="case-status" style="padding-right: 50px;">Typ případu: <b>{{ data.obligation.name }}</b></span>
      <span style="padding-right: 50px;">
        <change-case-status [case]="data" [actionButtons]="statusActions" [previousState]="previousState" [previousEnabled]="!data.heliosImportStatus || loggedUser.superuser" (back)="backStatus()"></change-case-status>
      </span>
    </div>

    <div class="flexbox-container flex-column">
      <span *ngIf="!data.cadastreValid && data.checkCadastre">
        <span class="cadastre-invalid">Případ: Neodpovídá stavu KN</span>
      </span>
      <span *ngIf="!data.inputValid">
        <span class="cadastre-invalid">Případ: Neodpovídá stavu záborů/VB</span>
      </span>
      <span *ngIf="!data.checkCadastre">
        <span class="cadastre-invalid">Případ: Nesledován vůči aktuálnímu stavu KN</span>
      </span>
    </div>

    <div *ngIf="data.heliosImportStatus || data.isSignedByAll" class="mt-10">
      <span class="tag tag--not-clickable tag--active" *ngIf="data.isSignedByAll">Podepsáno všemi stranami</span>
      <ng-container *ngIf="data.heliosImportStatus">
        <span tooltip="K datu {{ data.heliosImportDate | date:'d.M.yyyy' }}">
          <span class="tag tag--not-clickable tag--inactive ml-5" *ngIf="data.heliosImportStatus === 'PREPARED'">Čeká na přenost dat do Helios</span>
          <span class="tag tag--not-clickable tag--warning ml-5" *ngIf="data.heliosImportStatus === 'RUNNING'">Probíhá přenost dat do Helios</span>
          <span class="tag tag--not-clickable tag--danger ml-5" *ngIf="data.heliosImportStatus === 'FAILED'">
            Přenos do Helios se nezdařil <span *ngIf="data.heliosImportFailedReason">({{ data.heliosImportFailedReason }}) <a *ngIf="loggedUser.superuser" (click)="heliosFailDetailShown = !heliosFailDetailShown"><i class="fa fa-eye"></i></a></span></span>
          <span class="tag tag--not-clickable tag--active ml-5" *ngIf="data.heliosImportStatus === 'FINISHED'">Data přenesena do Helios</span>
        </span>

        <gmt-button *ngIf="loggedUser.superuser && data.heliosImportStatus !== 'FINISHED' && data.heliosImportStatus !== 'RUNNING'" [clickAction]="runHeliosImport" tooltip="Spustit import do Helios"><i class="fa fa-upload"></i></gmt-button>

        <div *ngIf="heliosFailDetailShown">{{ data.heliosImportFailedReasonDetail }}</div>

        <span class="tag tag--not-clickable ml-5" *ngIf="!data.contractRegisterPublicationVersionId && data.contractRegisterPublication === true && data.contractRegisterPublicationException === undefined">Čeká na zveřejnění v RS</span>
        <span class="tag tag--not-clickable tag--active  ml-5" *ngIf="data.contractRegisterPublicationVersionId">Zveřejněno v RS</span>
        <span class="tag tag--not-clickable ml-5" *ngIf="!data.contractRegisterPublicationVersionId && (data.contractRegisterPublication === false || data.contractRegisterPublicationException)">Nebude zveřejněno v RS</span>
      </ng-container>
    </div>

    <div class="case-top mt-30 mb-70">
      <div class="flex-rest">
        <div class="edit" *ngIf="canEdit()">
          <a uiSref="symap.project.cases.detail.updateCase" class="button">Upravit údaje</a>
        </div>
        <div class="column-50">
          <div visibleByBrand="mpv">
            <label>Pracovní číslo případu</label>
            <div class="info">
              {{ data.internalCaseNumber || 'bez čísla' }}
            </div>
          </div>
          <label>Katastrální území</label>
          <div class="info">
            <span *ngFor="let title of data.titles; let last = last">
              {{ title.area.name }}{{ last ? '' : ', ' }}
            </span>
          </div>
          <label>List vlastnictví</label>
          <div class="info">
            <a *ngFor="let title of data.titles; let last = last" uiSref="symap.project.titles.detail" [uiParams]="{ id: title.id }" title='{{ title.area.name }}'>
              {{ title.lv }}{{ last ? '' : ', ' }}
            </a>
          </div>
          <label>{{ "CONSTRUCTION_OBJECT_PLURAL_NOMINATIV" | wordtranslate:true }}</label>
          <div class="info">
            <gmt-construction-object-list [constructionObjects]="data.constructionObjects"></gmt-construction-object-list>
          </div>
          <div *ngIf="data.tenantNames && data.tenantNames.length > 0">
            <label>Smluvní nájemce</label>
            <div class="info">
              <div>
                {{ data.tenantNames.toString() }}
              </div>
            </div>
          </div>
          <div *ngIf="showEasementDistributionSystemParts">
            <label>Umisťované součásti distribuční soustavy</label>
            <div class="info comma-list">
              <span *ngFor="let part of data.distributionSystemParts">{{ part.name }}</span>
            </div>
          </div>
        </div>
        <div class="column-50">
          <div *ngIf="data.createdDate">
            <label>Datum založení</label>
            <div class="info">{{ data.createdDate | date }}</div>
          </div>
          <div *ngIf="data.statusRespectiveDate">
            <label>Poslední změna stavu</label>
            <div class="info">
              {{ data.statusRespectiveDate | date }}
            </div>
          </div>
          <div *ngIf="data.price">
            <label>Cena za případ</label>
            <div class="info">
              {{ data.price | number | price }}
            </div>
            <div *ngIf="data.manualRentPriceSet" class="info ml-5"><i class="fa fa-exclamation-triangle color-red"></i> Upravená cena za případ (Cena vypočtená {{ data.ownershipPrice  | number | price }})</div>
          </div>
          <div *ngIf="data.rentalStartConditions">
            <label>Předpoklady pro zahájení nájmu</label>
            <div class="info">
              <div>
                {{ data.rentalStartConditions }}
              </div>
            </div>
          </div>
          <div *ngIf="data.rentalStartDate">
            <label>Datum zahájení nájmu</label>
            <div class="info">
              <div>
                {{ data.rentalStartDate | date }}
              </div>
            </div>
          </div>
          <div *ngIf="data.rentalPaymentNotificationDate">
            <label>Datum pro upozornění na novou platbu nájmu</label>
            <div class="info">
              <div>
                {{ data.rentalPaymentNotificationDate | date }}
              </div>
            </div>
          </div>
          <ng-container *ngIf="!hideColumn('mandatary')">
            <label>Řešitel případu</label>
            <div class="info">
              {{ data.mandatary | username }}
            </div>
          </ng-container>
          <ng-container *ngIf="!hideColumn('officer')">
            <label>Referent případu</label>
            <div class="info">
              {{ data.officer | username }}
            </div>
          </ng-container>
          <ng-container *ngIf="!hideColumn('titleUser')">
            <label>Přidělení LV</label>
            <div class="info">
              {{ data.titles | titleUserUniqueNames }}
            </div>
          </ng-container>
          <ng-container *ngIf="data.fromCase">
            <label>Původní případ</label>
            <div class="info">
              <a uiSref="symap.project.cases.detail" [uiParams]="{id: data.fromCase.id}">{{ data.fromCase | caseName }}</a>
            </div>
          </ng-container>
          <ng-container *ngIf="data.nextCase">
            <label>Vyvlastnění</label>
            <div class="info">
              <a uiSref="symap.project.cases.detail" [uiParams]="{id: data.nextCase.id}">{{ data.nextCase | caseName }}</a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <fieldset *ngIf="data.obligation && data.obligation.type === 'InnominateSalesContractLiability'">
      <legend>Předmět případu</legend>
      <div class="column-100">
        <label>Popis</label>
        <div class="info">{{ data.innominationSubject }}</div>
        <label>Cena</label>
        <div class="info">{{ data.innominationPrice | price }}</div>
      </div>
    </fieldset>

    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
        <div *ngIf="tab === 'owners'">
          <div *ngFor="let caseOwnership of dataCaseOwnerships" class="subject">
            <fieldset *ngIf="caseOwnership.ownership.isBsm" class="bordered no-padding">
              <div style="margin:20px">
                <legend>Osoba SJM</legend>
                <div class="edit" *ngIf="canEdit()">
                  <a uiSref="symap.project.cases.detail.updateCaseOwnership" [uiParams]="{ caseOwnershipId: caseOwnership.id }" class="button">Upravit údaje</a>
                </div>
                <div class="flexbox-container pb-20">
                  <div class="column-50">
                    <label>Podíl</label>
                    <div class="info" *ngIf="data.titles.length === 1">
                      {{ caseOwnership.podil }}
                    </div>
                    <div class="info" *ngIf="data.titles.length > 1">
                      {{ caseOwnership.podil }}

                      <ng-container *ngIf="caseOwnership.ownership.title">
                        (LV <a uiSref='symap.project.titles.detail' [uiParams]="{ id: caseOwnership.ownership.title.id, tab: null }" title='{{caseOwnership.ownership.title.area.name }}'>{{ caseOwnership.ownership.title.lv }}</a> - {{ caseOwnership.ownership.title.area.name }})
                      </ng-container>
                      <ng-container *ngIf="!caseOwnership.ownership.title">
                        (LV {{ caseOwnership.ownership.lv }})
                      </ng-container>
                    </div>
                    <div *ngIf="caseOwnership.singlePayment">
                      <div *ngIf="caseOwnership.singlePayment !== 'M' && caseOwnership.singlePayment !== 'B'">
                        <label>Způsob platby</label><div class="info">Neurčeno</div>
                      </div>
                      <div *ngIf="caseOwnership.singlePayment === 'M'">
                        <label>Způsob platby</label><div class="info">Poštovní poukázkou</div>
                      </div>
                      <div *ngIf="caseOwnership.singlePayment !== 'B'">
                        <label>Způsob platby</label><div class="info">Bankovním převodem</div>
                        <label>Číslo B.Ú.</label><div class="info">{{ caseOwnership.accountNumber && caseOwnership.bankCode ? caseOwnership.accountNumber + "/" + caseOwnership.bankCode : ''}}</div>
                        <label>Var. symbol</label><div class="info">{{ caseOwnership.variableSymbol }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="column-50">
                    <div *ngIf="data.obligation.type === 'SalesContractLiability' || data.obligation.type === 'NonGratuitousTransferContractLiability' || data.obligation.type === 'ExchangeContractLiability'">
                      <label>Kupní cena</label><div class="info">{{ caseOwnership.price | number | price }}<br/>{{ caseOwnership.priceInWords}}</div>
                    </div>
                    <div *ngIf="data.obligation.type === 'GiftContractLiability' || data.obligation.type === 'GratuitousTransferContractLiability'">
                      <label>Účetní cena</label><div class="info">{{ caseOwnership.price | number | price }}<br/>{{ caseOwnership.priceInWords}}</div>
                    </div>
                    <div *ngIf="caseOwnership.easementPrice !== undefined">
                      <label>Cena služebnosti</label><div class="info">{{caseOwnership.easementPrice|number|price}}<br/>{{caseOwnership.easementPriceInWords}}</div>
                    </div>
                    <div *ngIf="data.obligation.type === 'RentalContractShorterThan1YearLiability' || data.obligation.type === 'RentalContractLongerThan1YearLiability' || data.obligation.type === 'RentalContractCommonLiability'">
                      <label>Nájem</label><div class="info">{{ caseOwnership.landPrice | number | price }}</div>
                      <label>Náhrady</label><div class="info">{{ caseOwnership.compensationPrice | number | price }}</div>
                      <label>Celkem</label><div class="info">{{ caseOwnership.price| number | price }} <span *ngIf="data.manualRentPriceSet" class="info ml-5"><i class="fa fa-exclamation-triangle color-red"></i> Upravená cena za případ</span>
                      <br/>{{ caseOwnership.priceInWords }}</div>
                    </div>
                    <div *ngIf="caseOwnership.easementFutureContractNumber">
                      <label>Číslo SOSB</label><div class="info">{{ caseOwnership.easementFutureContractNumber }}</div>
                    </div>
                    <div *ngIf="caseOwnership.easementFutureContractStatus">
                      <label>Stav SOSB</label><div class="info">{{ caseOwnership.easementFutureContractStatus }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngFor="let caseSubject of caseOwnership.caseSubjects; let index = index;" class="bordered-top">
                <fieldset *ngIf="caseOwnership.ownership.isBsm" class="bordered-top">
                  <case-owner [manualRentPriceSet]="data.manualRentPriceSet" [editFunction]="updateCaseSubjectDialogFunction" [canEdit]="canEdit()" [caseSubject]="caseSubject" [caseOwnership]="caseOwnership" [obligationType]="data.obligation.type" [obligationBonusPeriod]="data.obligation.bonusPeriod" [titles]="data.titles" [index]="index + 1"></case-owner>
                </fieldset>
              </ng-container>
            </fieldset>
            <ng-container *ngFor="let caseSubject of caseOwnership.caseSubjects" class="bordered">
              <fieldset *ngIf="!caseOwnership.ownership.isBsm" class="bordered">
                <case-owner [manualRentPriceSet]="data.manualRentPriceSet" [editFunction]="updateCaseSubjectDialogFunction" [canEdit]="canEdit()" [caseSubject]="caseSubject" [caseOwnership]="caseOwnership" [obligationType]="data.obligation.type" [obligationBonusPeriod]="data.obligation.bonusPeriod" [titles]="data.titles"></case-owner >
              </fieldset>
            </ng-container>
          </div>
        </div>

        <div *ngIf="tab === 'detailInfo'">
          <div class="clearfix">
            <div class="column-100">
              <label>{{ 'ps.case.id' | brandTranslate }}</label>
              <div class="info">{{ data.textId }}</div>
            </div>
            <div class="column-25" *ngIf="data.sentInvestorDate">
              <label>Odeslání k odsouhlasení</label>
              <div class="info">{{data.sentInvestorDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.approvedInvestorDate">
              <label>Potvrzení odsouhlasení</label>
              <div class="info">{{data.approvedInvestorDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.signedInvestorDate">
              <label>Datum podpisu investora</label>
              <div class="info">{{data.signedInvestorDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.financialControlDate">
              <label>Datum finanční kontroly</label>
              <div class="info">{{data.financialControlDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.maxAuctionAmount">
              <label>Maximální částka pro dražbu</label>
              <div class="info">{{data.maxAuctionAmount|number}}</div>
            </div>
            <div class="column-25" *ngIf="data.auctionWinDate">
              <label>Datum příklepu</label>
              <div class="info">{{data.auctionWinDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.auctionWinLegalEffectsDate">
              <label>Datum právní moci rozhodnutí</label>
              <div class="info">{{data.auctionWinLegalEffectsDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.auctionWinAmount">
              <label>Celková cena podání</label>
              <div class="info">{{data.auctionWinAmount|number}}</div>
            </div>
            <div class="column-25" *ngIf="data.sentAdministratorDate">
              <label>Odeslání oprávněnému</label>
              <div class="info">{{data.sentAdministratorDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.signedAdministratorDate">
              <label>Podpis oprávněným</label>
              <div class="info">{{data.signedAdministratorDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.sentAllOwnersDate">
              <label>Datum odeslání vlastníkovi</label>
              <div class="info">{{data.sentAllOwnersDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationProposalDate">
              <label>Datum žádosti</label>
              <div class="info">{{data.expropriationProposalDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAssessmentInteruptionDate">
              <label>Datum přerušení posouzení</label>
              <div class="info">{{data.expropriationAssessmentInteruptionDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAssessmentInteruptionDeadlineDate">
              <label>Datum lhůty přerušení posouzení</label>
              <div class="info">{{data.expropriationAssessmentInteruptionDeadlineDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAssessmentInteruptionReason">
              <label>Důvod přerušení posouzení</label>
              <div class="info">{{data.expropriationAssessmentInteruptionReason}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationProceedingStartedDate">
              <label>Datum zahájení řízení</label>
              <div class="info">{{data.expropriationProceedingStartedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationInterimDecisionRequestDate">
              <label>Datum žádosti o mezitimní rozhodnutí</label>
              <div class="info">{{data.expropriationInterimDecisionRequestDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationInterimDecisionDate">
              <label>Datum mezitimního rozhodnutí</label>
              <div class="info">{{data.expropriationInterimDecisionDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationStartDeadlineDate">
              <label>Datum lhůty pro zahájení uskutečňování účelu</label>
              <div class="info">{{data.expropriationStartDeadlineDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAdministrativeAppealDate">
              <label>Datum podání správní žaloby</label>
              <div class="info">{{data.expropriationAdministrativeAppealDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAdministrativeAppealRejectedDate">
              <label>Datum zamítnutí správní žaloby</label>
              <div class="info">{{data.expropriationAdministrativeAppealRejectedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAdministrativeAppealAcceptedDate">
              <label>Datum potvrzení správní žaloby</label>
              <div class="info">{{data.expropriationAdministrativeAppealAcceptedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationCompensationPaymentDate">
              <label>Datum úhrady zálohy na náhradu</label>
              <div class="info">{{data.expropriationCompensationPaymentDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationProceedingInteruptionDate">
              <label>Datum přerušení řízení</label>
              <div class="info">{{data.expropriationProceedingInteruptionDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationProceedingInteruptionDeadlineDate">
              <label>Datum lhůty přerušení řízení</label>
              <div class="info">{{data.expropriationProceedingInteruptionDeadlineDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationProceedingInteruptionReason">
              <label>Důvod přerušení řízení</label>
              <div class="info">{{data.expropriationProceedingInteruptionReason}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationProceedingInteruptionExtendedDate">
              <label>Datum prodloužení přerušení řízení</label>
              <div class="info">{{data.expropriationProceedingInteruptionExtendedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationProceedingInteruptionExtendedDeadlineDate">
              <label>Datum prodloužené lhůty přerušení řízení</label>
              <div class="info">{{data.expropriationProceedingInteruptionExtendedDeadlineDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationProceedingInteruptionExtendedReason">
              <label>Důvod prodloužení přerušení řízení</label>
              <div class="info">{{data.expropriationProceedingInteruptionExtendedReason}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationStoppedBySide">
              <label>Řízení zastavil</label>
              <div class="info">{{data.expropriationStoppedBySide}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationStoppedDate">
              <label>Datum zastavení řízení</label>
              <div class="info">{{data.expropriationStoppedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationStoppedReason">
              <label>Důvod zastavení řízení</label>
              <div class="info">{{data.expropriationStoppedReason}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationCalledHearingCalledDate">
              <label>Datum doručení rozhodnutí o svolání ústního jednání</label>
              <div class="info">{{data.expropriationCalledHearingCalledDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationCalledHearingDate">
              <label>Datum ústního jednání</label>
              <div class="info">{{data.expropriationCalledHearingDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationHearingRepetitionCalledDate">
              <label>Datum doručení rozhodnutí o svolání opakovaného ústního jednání</label>
              <div class="info">{{data.expropriationHearingRepetitionCalledDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationHearingRepetitioDate">
              <label>Datum opakovaného ústního jednání</label>
              <div class="info">{{data.expropriationHearingRepetitioDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationHearingRepetitionReason">
              <label>Důvod opakování ústního jednání</label>
              <div class="info">{{data.expropriationHearingRepetitionReason}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationResultDate">
              <label>Datum rozhodnutí</label>
              <div class="info">{{data.expropriationResultDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationResultDeliveryDate">
              <label>Datum doručení rozhodnutí</label>
              <div class="info">{{data.expropriationResultDeliveryDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAppealDeadlineDate">
              <label>Datum lhůty pro odvolání</label>
              <div class="info">{{data.expropriationAppealDeadlineDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationResultRealizationDeadlineDate">
              <label>Lhůta pro zahájení uskutečňování účelu</label>
              <div class="info">{{data.expropriationResultRealizationDeadlineDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAmount">
              <label>Cena vyvlastnění</label>
              <div class="info">{{data.expropriationAmount|number}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAmountSource">
              <label>Původ ceny vyvlastnění</label>
              <div class="info">{{data.expropriationAmountSource}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAppealReason">
              <label>Důvod odvolání</label>
              <div class="info">{{data.expropriationAppealReason}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAppealDate">
              <label>Datum odvolání</label>
              <div class="info">{{data.expropriationAppealDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAppealSide">
              <label>Odvolávající se strana</label>
              <div class="info">{{data.expropriationAppealSide === 'I' ? 'investor' : 'vlastník'}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAppealResultDate">
              <label>Datum výsledku odvolání</label>
              <div class="info">{{data.expropriationAppealResultDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAppealResultReason">
              <label>Odůvodnění výsledku odvolání</label>
              <div class="info">{{data.expropriationAppealResultReason}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationAppealResultArbitrator">
              <label>O výsledku odvolání rozhodl</label>
              <div class="info">{{data.expropriationAppealResultArbitrator}}</div>
            </div>
            <div class="column-25" *ngIf="data.expropriationEnforceableDate">
              <label>Datum právní moci rozhodnutí</label>
              <div class="info">{{data.expropriationEnforceableDate|date}}</div>
            </div>

            <div class="column-25" *ngIf="data.contractRegisterPublicationDate">
              <label>Zveřejnění v registru smluv</label>
              <div class="info">
                <a *ngIf="data.contractRegisterPublicationVersionId" target="_blank" href="https://smlouvy.gov.cz/smlouva/{{ data.contractRegisterPublicationVersionId }}">zveřejněno {{ data.contractRegisterPublicationDate | date }}</a>
                <span *ngIf="data.contractRegisterPublicationException">nezveřejněno {{ data.contractRegisterPublicationDate | date }}<br>{{data.contractRegisterPublicationException}}</span>
              </div>
            </div>
          </div>

          <div class="cb" *ngIf="data.obligation.type !== 'TemporaryExpropriationLiability' && data.obligation.type !== 'FulfillmentOfDecisionExpropriationLiability' && data.obligation.type !== 'FulfillmentOfDecisionAuctionSaleExecutionLiability'">
            <ng-container *ngFor="let recipient of dataRecipients">
              <fieldset *ngIf="recipient.personalVisitDate || recipient.meetingRequestDate ||  recipient.sentDate || recipient.undeliveredDate || recipient.receivedDate || recipient.declinedDate || recipient.signedDate || recipient.responseReceivedDate || recipient.approvedOwnerDate || recipient.disapprovedOwnerDate || recipient.exchangeRequestDate || recipient.otherSolutionRequestDate || recipient.withoutResolutionDate" class="bordered">
                <legend>{{recipient.name}}</legend>
                <div class="column-25" *ngIf="recipient.personalVisitDate">
                  <label>Datum osobní návštěvy</label>
                  <div class="info">{{recipient.personalVisitDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.sentDate">
                  <label>Datum odeslání</label>
                  <div class="info">{{recipient.sentDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.undeliveredDate">
                  <label>Datum vrácení</label>
                  <div class="info">{{recipient.undeliveredDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.dunningLetterDate">
                  <label>Datum urgence</label>
                  <div class="info">{{recipient.dunningLetterDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.callBeforeExpropriationDate">
                  <label>Datum odeslání výzvy před vyvlastněním</label>
                  <div class="info">{{recipient.callBeforeExpropriationDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.callBeforeExpropriationReceivedDate">
                  <label>Datum doručení výzvy před vyvlastněním</label>
                  <div class="info">{{recipient.callBeforeExpropriationReceivedDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.expropriationCaseInProgressDate">
                  <label>Datum zahájení vyvlastňovacího řízení</label>
                  <div class="info">{{recipient.expropriationCaseInProgressDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.expropriationCaseStoppedDate">
                  <label>Datum zastavení vyvlastňovacího řízení</label>
                  <div class="info">{{recipient.expropriationCaseStoppedDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.expropriationCaseFinishedDate">
                  <label>Datum vyvlastnění</label>
                  <div class="info">{{recipient.expropriationCaseFinishedDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.receivedDate">
                  <label>Datum přijetí</label>
                  <div class="info">{{recipient.receivedDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.meetingRequestDate">
                  <label>Datum požadavku jednání</label>
                  <div class="info">{{recipient.meetingRequestDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.acceptedGeometricalPlanPossibleDate">
                  <label>Datum souhlasu</label>
                  <div class="info">{{recipient.acceptedGeometricalPlanPossibleDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.declinedDate">
                  <label>Datum odmítnutí</label>
                  <div class="info">{{recipient.declinedDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.signedDate">
                  <label>Datum podpisu</label>
                  <div class="info">{{recipient.signedDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.exchangeRequestDate">
                  <label>Datum požadavku na směnu</label>
                  <div class="info">{{recipient.exchangeRequestDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.otherSolutionRequestDate">
                  <label>Datum požadavku na jiné řešení</label>
                  <div class="info">{{recipient.otherSolutionRequestDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.disapprovedOwnerDate">
                  <label>Datum nesouhlasu vlastníka</label>
                  <div class="info">{{recipient.disapprovedOwnerDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.withoutResolutionDate">
                  <label>Datum označení případu bez výsledku</label>
                  <div class="info">{{recipient.withoutResolutionDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.approvedOwnerDate">
                  <label>Datum souhlasu vlastníka</label>
                  <div class="info">{{recipient.approvedOwnerDate|date}}</div>
                </div>
                <div class="column-25" *ngIf="recipient.responseReceivedDate">
                  <label>Datum doručení smlouvy</label>
                  <div class="info">{{recipient.responseReceivedDate|date}}</div>
                </div>
              </fieldset>
            </ng-container>
          </div>

          <div class="clearfix">
            <div class="column-100 cb" *ngIf="data.bonusDate && data.obligation.bonusPeriod">
              <label>Akcelerační příplatek</label>
              <div class="info">{{data.bonusDate|date}}{{data.bonusPossible && !data.bonusGranted ? '' : (' / ' + (data.bonusGranted ? 'ano' : 'ne'))}}</div>
            </div>
            <div class="column-25" *ngIf="data.signedSentOwnersDate">
              <label>Odeslání podepsané smlouvy vlastníkům</label>
              <div class="info">{{data.signedSentOwnersDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.buildingAuthorityApprovalRequestDate">
              <label>Datum žádosti o souhlas SÚ</label>
              <div class="info">{{data.buildingAuthorityApprovalRequestDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.buildingAuthorityApprovedDate">
              <label>Datum udělení souhlasu SÚ</label>
              <div class="info">{{data.buildingAuthorityApprovedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.buildingAuthorityRejectedDate">
              <label>Datum nesouhlasu SÚ</label>
              <div class="info">{{data.buildingAuthorityRejectedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.cadastreEntryDeclinedDate">
              <label>Zamítnutí návrhu na vklad</label>
              <div class="info">{{data.cadastreEntryDeclinedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.cadastreEntryStoppedDate">
              <label>Zastavení návrhu na vklad</label>
              <div class="info">{{data.cadastreEntryStoppedDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.entryProposalDate">
              <label>Podání návrhu na vklad</label>
              <div class="info">{{data.entryProposalDate|date}}</div>
            </div>
            <div class="column-50" *ngIf="data.entryProposalLink">
              <label>Adresa elektronického podání</label>
              <div class="info"><a href="{{data.entryProposalLink}}">{{data.entryProposalLink}}</a></div>
            </div>
            <div class="column-25" *ngIf="data.entryProposalLink">
              <label>Uživatel elektronického podání</label>
              <div class="info">{{data.entryProposalUser}}</div>
            </div>
            <div class="cb column-25" *ngIf="data.cadastreEntryDeliveryDate">
              <label>Datum doručení rozhodnutí</label>
              <div class="info">{{data.cadastreEntryDeliveryDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.legalEffectsDate">
              <label>Datum právních účinků</label>
              <div class="info">{{data.legalEffectsDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.cadastreEntryDate">
              <label>Datum rozhodnutí KN</label>
              <div class="info">{{data.cadastreEntryDate|date}}</div>
            </div>
            <div class="column-25" *ngIf="data.cadastreEntryNumber">
              <label>Č.j. rozhodnutí KN</label>
              <div class="info">{{data.cadastreEntryNumber}}</div>
            </div>

            <div class="column-100" *ngIf="data.cancelledDate">
              <label>Datum zrušení případu</label>
              <div class="info">{{data.cancelledDate|date}}</div>
            </div>

            <div class="column-25" *ngIf="data.dueDate">
              <label>Datum splatnosti</label>
              <div class="info">{{data.dueDate|date}}</div>
            </div>

            <div class="column-25" *ngIf="data.handedOverInvestorDate">
              <label>Závěrečný stav případu</label>
              <div class="info">{{data.handedOverInvestorDate|date}}</div>
            </div>

            <div class="column-25" *ngIf="data.paymentInstructionDate">
              <label>Datum žádosti o úhradu</label>
              <div class="info">{{data.paymentInstructionDate|date}}</div>
            </div>

            <div class="column-25" *ngIf="data.paymentOrderDate">
              <label>Datum platebního příkazu</label>
              <div class="info">{{data.paymentOrderDate|date}}</div>
            </div>
          </div>

          <fieldset *ngIf="caseTransferErrorsData.length" class="bordered">
            <legend>Chyby posledního přenosu do MPP</legend>
            <div>
              <ul>
                <li *ngFor="let error of caseTransferErrorsData">
                  <div><b>{{ error.message }}</b></div>
                  <div>{{ error.exception }}</div>
                </li>
              </ul>
            </div>
          </fieldset>
        </div>

        <div *ngIf="tab === 'parcels'">
          <div [loading]="easementsLoading || occupationLoading">
            <div *ngIf="dataOccupationGroups.permanent && !occupationLoading">
              <gmt-labeled-block label="{{ 'PERMANENT_OCCUPATION' | wordtranslate }}">
                <table-occupations-permanent
                  updateOccupationParcelPath="symap.project.cases.detail.updateOccupationParcel"
                  [priceEditFunction]="updateParcelPriceDialogFunction"
                  [data]="dataOccupationGroups.permanent"
                  [columns]="occupationPermanentColumns"
                ></table-occupations-permanent>
              </gmt-labeled-block>
            </div>

            <div *ngIf="dataOccupationGroups.permanentWithSolutionType && !occupationLoading">
              <gmt-labeled-block label="Trvalý zábor bez výkupu (nájem)">
                <table-occupations-temporary
                  updateOccupationParcelPath="symap.project.cases.detail.updateOccupationParcel"
                  [priceEditFunction]="updateParcelPriceDialogFunction"
                  [data]="dataOccupationGroups.permanentWithSolutionType"
                  [columns]="permanentWithSolutionTypeColumns"
                ></table-occupations-temporary>
              </gmt-labeled-block>
            </div>

            <div *ngIf="dataOccupationGroups.underOneYear && !occupationLoading">
              <gmt-labeled-block [label]="occupationUnderYearTableName">
                <table-occupations-temporary
                  updateOccupationParcelPath="symap.project.cases.detail.updateOccupationParcel"
                  [priceEditFunction]="updateParcelPriceDialogFunction"
                  [data]="dataOccupationGroups.underOneYear"
                  [columns]="occupationUnderOneYearColumns"
                ></table-occupations-temporary>
              </gmt-labeled-block>
            </div>

            <div *ngIf="dataOccupationGroups.overOneYear && !occupationLoading">
              <gmt-labeled-block [label]="occupationOverYearTableName">
                <table-occupations-temporary
                  updateOccupationParcelPath="symap.project.cases.detail.updateOccupationParcel"
                  [priceEditFunction]="updateParcelPriceDialogFunction"
                  [data]="dataOccupationGroups.overOneYear"
                  [columns]="occupationOverOneYearColumns"
                ></table-occupations-temporary>
              </gmt-labeled-block>
            </div>

            <div *ngIf="dataEasements && !easementsLoading && dataEasements.length > 0">
              <gmt-labeled-block label="{{ 'EASEMENT' | wordtranslate }}">
                <table-easements
                  [priceEditFunction]="updateParcelPriceDialogFunction"
                  [data]="dataEasements"
                  [columns]="easementColumns"
                  [showUpdate]="true"></table-easements>
              </gmt-labeled-block>
            </div>
          </div>

          <gmt-labeled-block label="Budovy" *ngIf="data.caseBuildings.length" [ngClass]="{'mt-20': dataOccupationGroups.permanent || dataEasements.length}">
            <div class="bordered">
              <table>
                <thead>
                <tr>
                  <th>Parcela</th>
                  <th>LV parcely</th>
                  <th>LV budovy</th>
                  <th>Část obce</th>
                  <th>Číslo budovy</th>
                  <th>Typ a Využití</th>
                  <th>Ochrana</th>
                  <th *ngIf="data.obligation.type != 'FulfillmentOfDecisionExpropriationLiability'">Cena [<span class="unit">Kč</span>]</th>
                </tr>
                </thead>
                <tbody *ngFor="let itemBuilding of data.caseBuildings" class="hover">
                <tr *ngFor="let itemParcel of itemBuilding.building.parcels.length ? itemBuilding.building.parcels : [null]; let index = index">
                  <td class="no-padding">
                    <div class="fill-content" *ngIf="itemParcel">
                      <div class="tooltip-hover-area fill-content" [tooltip]="tooltipParcelComponent" [data]="{ parcel: itemParcel }" [activeContent]="true">
                        <div class="fill-content flexbox-container" style="flex-direction: column">
                          <div class="pt-10 pl-10" *ngIf="itemParcel.soucasti">
                            součástí
                          </div>
                          <cell-parcel [parcel]="itemParcel" [showTitle]="true"></cell-parcel>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="number">
                    <span *ngIf="itemParcel && !itemParcel.title">{{ itemParcel.lv }}</span>
                    <a *ngIf="itemParcel && itemParcel.title" uiSref="symap.project.titles.detail" [uiParams]="{id: itemParcel.title.id, tab: null}">{{ itemParcel.title.lv }}</a>
                  </td>
                  <td *ngIf="index === 0" [attr.rowSpan]="itemBuilding.building.parcels.length">
                    <span *ngIf="!itemBuilding.building.title">{{ itemBuilding.building.cisloTel }}</span>
                    <a *ngIf="itemBuilding.building.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: itemBuilding.building.title.id, tab: null }">{{ itemBuilding.building.title.lv }}</a>
                  </td>
                  <td *ngIf="index === 0" [attr.rowSpan]="itemBuilding.building.parcels.length">{{ itemBuilding.building.castobce }}</td>
                  <td *ngIf="index === 0" [attr.rowSpan]="itemBuilding.building.parcels.length">{{ itemBuilding.building | buildingNumber:true }}</td>
                  <td *ngIf="index === 0" [attr.rowSpan]="itemBuilding.building.parcels.length">{{ itemBuilding.building.typ }}<br>{{ itemBuilding.building.vyuziti }}</td>
                  <td *ngIf="index === 0" [attr.rowSpan]="itemBuilding.building.parcels.length">
                    <span class="comma-list">
                      <span *ngFor="let itemProtection of itemBuilding.buildingProtections">{{ itemProtection.nazevOchrany }}</span>
                    </span>
                  </td>
                  <td *ngIf="data.obligation.type != 'FulfillmentOfDecisionExpropriationLiability' && index === 0" class="number" [attr.rowSpan]="itemBuilding.building.parcels.length">
                    {{ itemBuilding.buildingsPrice | number }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </gmt-labeled-block>
        </div>

        <div *ngIf="tab === 'parcelsExternal'">
          <div *ngIf="data.parcelsExternal.length">
            <gmt-labeled-block label="Parcely investora">
              <table-parcels-small
                [data]="data.parcelsExternal"
                [columns]="EXT_PARCELS_COLUMNS"
              ></table-parcels-small>
            </gmt-labeled-block>
          </div>
        </div>

        <div *ngIf="tab === 'notifications'">
          <gmt-notifications
            *ngIf="!loading"
            [readonly]="notificationsReadonly"
            [notificationList]="notificationList"
            (updated)="onUpdateNotificationList()"
          ></gmt-notifications>
        </div>

        <div *ngIf="tab === 'notes'">
          <gmt-notes
            [noteList]="noteList"
            [editable]="true"
            [allowType]="true"
            [defaultData]="{ businessCase: { id: caseId }, systemType: false }"
            (updated)="onUpdateNoteList();loadHistory();"
          ></gmt-notes>
        </div>

        <div *ngIf="tab === 'history'">
          <gmt-notes
            [noteList]="historyList"
            [editable]="false"
            type="history"
          ></gmt-notes>
        </div>

        <div *ngIf="tab === 'documents'">
          <gmt-documents
            [documentList]="attachmentList"
            [defaultData]="{ caseId: data.id }"
            (updated)="onUpdateAttachmentsList();loadHistory();"
            [authorizedUser]="canEdit()"
            [allowType]="true"
            [disabledDocumentTypes]="disabledDocumentTypes"
          ></gmt-documents>
        </div>
      </gmt-navbar>
    </div>
  </div>

  <help *ngIf="!loading" [helpId]="helpIds.CASES_DETAIL" class="cm-help-page"></help>
</div>
