import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { EnumUtils } from '@app/common/utils/enum.utils';
import { PaymentOptionsEnum } from '@app/ps/enums/payment-options.enum';
import { PaymentTypeModel } from '@app/ps/models/payment-type.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';

@Component({
  selector: 'payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentFormComponent implements OnInit {
  @Input() paymentOptions: PaymentTypeModel;
  @Input() withPaymentDetails = true;
  @Input() nestedForm = false;
  @Input() required = false;

  options: SwitchOption[];

  ngOnInit() {
    this.options = <SwitchOption[]>EnumUtils.toObject(PaymentOptionsEnum);
    if (!this.paymentOptions.paymentType) {
      this.paymentOptions.paymentType = 'X';
    }
  }

  isBankTransferPayment(): boolean {
    const bankTransferOption = this.options.find((o) => o.value === PaymentOptionsEnum.B);
    return this.paymentOptions.paymentType === bankTransferOption.id;
  }
}
