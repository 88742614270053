import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { PricingExpertOpinionStepComponent } from '@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component';
import { AuthService } from '@app/common/services/auth.service';
import {
  ExpertOpinionCoefficient,
  ExpertOpinionCoefficientsFlatten, ParcelPriceCoefficient,
  ParcelPriceModel
} from '@app/ps/models/parcel-price.model';
import { DecimalPipe } from '@app/common/pipes/decimal.pipe';
import { ParcelUtils } from '@app/common/utils/parcel.utils';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';
import {ParcelPriceService} from "@app/ps/services/parcel-price.service";
import { RoundingService } from '@app/ps/services/rounding.service';
import { Decimal } from 'decimal.js';

@Component({
  selector: 'pricing-expert-opinion-parcels',
  templateUrl: './pricing-expert-opinion-parcels.component.html',
  styleUrls: ['./pricing-expert-opinion-parcels.component.scss']
})
export class PricingExpertOpinionParcelsComponent extends PricingExpertOpinionStepComponent implements OnInit {

  private static readonly DEFAULT_PRICE = {
    expertOpinionLandSquarePrice: '0',
    expertOpinionLandAreaPrice: '0',

    expertOpinionLandPrice: '0',
    expertOpinionLandPriceDefault: '0',

    expertOpinionVegetationPrice: '0',
    expertOpinionVegetationPriceDefault: '0',

    expertOpinionCompensationPrice: '0',
    expertOpinionCompensationPriceDefault: '0',

    expertOpinionBuildingsPrice: '0',
    expertOpinionBuildingsPriceDefault: '0',

    expertOpinionParcelPriceDefault: '0',
  };

  checkedAll = false;
  sortOrder: any = {};

  pricingMethodOptions: SwitchOption[] = [
    { id: 'M', value: 'Cena za metr čtvereční pozemku' },
    { id: 'A', value: 'Cena za pozemek' },
  ];

  private parcelsFilter: any;
  private buildingsFilter: any;
  private parcelPricesFilter: any;

  constructor(
    private restangular: Restangular,
    private decimalPipe: DecimalPipe,
    protected authService: AuthService,
    private roundingService: RoundingService,
    private parcelPriceService: ParcelPriceService,
  ) {
    super(authService);
    this.onSubmit = this.onSubmit.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  async ngOnInit() {
    this.data.opinion.expertOpinionPricingMethod = this.data.opinion.expertOpinionPricingMethod || 'M';

    if (!this.data.parcelPrices) {
      this.setupFilters();
      this.data.opinion.id ? await this.loadOpinionPricing() : await this.loadParcelPrices();
    }

    if (!this.data.opinion.id && !this.data.updated) {
      this.data.parcelPrices.forEach(parcelPrice => {
        this.populateBaseLandPrice(parcelPrice);
        // this.computeParcelPrice(parcelPrice);
      });
    }

    this.loading = false;
  }

  isFormValid() {
    return this.data.readonly || (this.data.opinion.parcelPrices.length && this.data.opinion.parcelPrices.every(this.isPriceRowValid, this));
  }

  onBack() {
    this.backCallback.emit();
  }

  onSubmit() {
    this.submitCallback.emit();
  }

  checkAll() {
    this.checkedAll = !this.checkedAll;
    this.data.opinion.parcelPrices.splice(0);

    if (!this.checkedAll) {
      return;
    }

    this.data.parcelPrices
      .filter(this.isParcelPriceEnabled, this)
      .forEach((item) => {
        this.data.opinion.parcelPrices.push(item);
      });
  }

  toggleParcelPrice(parcelPrice: ParcelPriceModel) {
    if (this.isParcelPriceEnabled(parcelPrice)) {
      this.data.checklistParcelPrices.toggleSelection(parcelPrice);
    }

    this.checkedAll = (this.data.checklistParcelPrices.checkedItems.length === this.data.parcelPrices.filter(this.isParcelPriceEnabled, this).length);
  }

  toggleMultiply(parcelPrice: ParcelPriceModel, type: 'compensation' | 'vegetation' | 'buildings') {
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    // turn off coefficient
    if (coefficients[type + 'Multiply']) {
      coefficients[type + 'Coefficient'] = this.COEFFICIENT_1;

    // turn on coefficient - try to set automatically same as on land
    } else {
      coefficients[type + 'Coefficient'] = coefficients.land15Multiply ? this.COEFFICIENT_15 : this.COEFFICIENT_8;
    }

    coefficients[type + 'Multiply'] = !coefficients[type + 'Multiply'];
  }

  toggleCoefficient(parcelPrice: ParcelPriceModel, type: 'compensation' | 'vegetation' | 'buildings', coefficient: number) {
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;
    if (coefficients[type + 'Coefficient'] === coefficient) {
      coefficients[type + 'Coefficient'] = this.COEFFICIENT_1;
    } else {
      coefficients[type + 'Coefficient'] = coefficient;
    }

    coefficients[type + 'Multiply'] = coefficients[type + 'Coefficient'] > this.COEFFICIENT_1;
  }

  isParcelPriceEnabled(parcelPrice: ParcelPriceModel): boolean {
    const hasLand = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionLand) || !this.data.opinion.expertOpinionLand;
    const hasVegetation = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionVegetation) || !this.data.opinion.expertOpinionVegetation;
    const hasBuilding = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionBuildings) || !this.data.opinion.expertOpinionBuildings;
    const isBuilding = parcelPrice.building && (!parcelPrice.building.validBuyoutOpinionBuildings || !this.data.opinion.expertOpinionBuildings);
    return (hasLand && hasVegetation && hasBuilding) || isBuilding;
  }

  isPriceRowValid(p: ParcelPriceModel): boolean {
    const coefficients = p.expertOpinionCoefficientsFlatten;

    const isLandPriceValid = (
        this.decimalPipe.transform(p.expertOpinionLandSquarePrice) &&
        !ParcelPriceService.coefficientsKeys.some(k => coefficients[`land${k}Multiply`] && !this.decimalPipe.transform(coefficients[`land${k}SquarePrice`]))
      ) || !this.data.opinion.expertOpinionLand || !p.parcel;
    const isVegetationPriceValid = this.decimalPipe.transform(p.expertOpinionVegetationPriceDefault) || !this.data.opinion.expertOpinionVegetation || !p.parcel;
    const isBuildingPriceValid = this.decimalPipe.transform(p.expertOpinionBuildingsPriceDefault) || !this.data.opinion.expertOpinionBuildings;
    const isCompensationPriceValid = this.decimalPipe.transform(p.expertOpinionCompensationPriceDefault) || !this.data.opinion.expertOpinionCompensation;

    let landCoefficientValid = ParcelPriceService.coefficientsKeys.some(k => coefficients[`land${k}Multiply`]);
    if (this.parcelPriceService.multipleLandCoefficient(p)) {
      landCoefficientValid = this.multipleLandCoefficientAreaMatches(p);
    }

    const isCoefficientValid = (!this.hasCoefficient || landCoefficientValid || p.parcel === undefined || !this.data.opinion.expertOpinionLand);

    if (this.data.occupationType === 'P') {
      return isLandPriceValid && isVegetationPriceValid && isBuildingPriceValid && isCoefficientValid;
    }

    return isCompensationPriceValid && isCoefficientValid;
  }

  populateBaseLandPrice(parcelPrice: ParcelPriceModel) {
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    if (this.data.opinion.expertOpinionPricingMethod === 'A') {
       const price = this.decimalPipe.transform(parcelPrice.expertOpinionLandAreaPrice);
       parcelPrice.expertOpinionLandSquarePrice = parcelPrice.parcel && parcelPrice.parcel.vymera && price ? price.div(parcelPrice.parcel.vymera).toDP(5).toString() : null;

       ParcelPriceService.coefficientsKeys.forEach(k => {
         const price = this.decimalPipe.transform(coefficients[`land${k}Price`]);
         const area = this.decimalPipe.transform(coefficients[`land${k}Area`]);
         coefficients[`land${k}SquarePrice`] = parcelPrice.parcel && price && area && area.cmp(0) > 0 ?
           price.div(area).toDP(5).toString() : (price && area && area.cmp(0) == 0 ? '0' : null)
       });
    } else {
      const price = this.decimalPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
      parcelPrice.expertOpinionLandAreaPrice = parcelPrice.parcel && parcelPrice.parcel.vymera && price ?
        this.roundingService.roundProject(
          price.mul(parcelPrice.parcel.vymera)
        ).toString() : null;

      ParcelPriceService.coefficientsKeys.forEach(k => {
        const price = this.decimalPipe.transform(coefficients[`land${k}SquarePrice`]);
        const area = this.decimalPipe.transform(coefficients[`land${k}Area`]);
        coefficients[`land${k}Price`] = parcelPrice.parcel && price && area ?
          this.roundingService.roundProject(price.mul(area)).toString() : null
      });
    }
  }

  computeParcelPrice(parcelPrice: ParcelPriceModel) {
    this.data.updated = true;
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    parcelPrice.expertOpinionParcelPriceDefault = '0';

    // Land price
    if (this.data.opinion.expertOpinionLand && parcelPrice.parcel) {
      // If multiple coefficients, then compute land price
      if (this.parcelPriceService.multipleLandCoefficient(parcelPrice)) {
        const expertOpinionLandAreaPrice = this.parcelPriceService.computeLandPriceFromMultiple(parcelPrice.expertOpinionCoefficientsFlatten, this.data.opinion.expertOpinionPricingMethod);
        parcelPrice.expertOpinionLandAreaPrice = expertOpinionLandAreaPrice ? expertOpinionLandAreaPrice.toString() : null;
        parcelPrice.expertOpinionLandSquarePrice = parcelPrice.parcel && parcelPrice.parcel.vymera && expertOpinionLandAreaPrice ? expertOpinionLandAreaPrice.div(parcelPrice.parcel.vymera).toDP(5).toString() : null;
      }

      const squarePrice = this.decimalPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
      const landPriceCoefficient = this.parcelPriceService.computeLandPriceCoefficient(parcelPrice, this.data.opinion.expertOpinionPricingMethod);
      parcelPrice.expertOpinionLandPriceDefault = this.data.opinion.expertOpinionPricingMethod === 'A' ?
        (this.decimalPipe.transform(parcelPrice.expertOpinionLandAreaPrice) ? parcelPrice.expertOpinionLandAreaPrice : null)
        : (squarePrice ? this.roundingService.roundProject(squarePrice.mul(parcelPrice.parcel.vymera)).toString() : null);
      parcelPrice.expertOpinionParcelPriceDefault = this.decimalPipe.transform(parcelPrice.expertOpinionLandPriceDefault) ? parcelPrice.expertOpinionLandPriceDefault : null;
      parcelPrice.expertOpinionLandPriceCoefficient = landPriceCoefficient ? landPriceCoefficient.toString() : null;
      parcelPrice.expertOpinionLandPrice = parcelPrice.expertOpinionLandPriceCoefficient;
    } else {
      parcelPrice.expertOpinionLandSquarePrice = null;
      parcelPrice.expertOpinionLandAreaPrice = null;
    }

    ['compensation', 'vegetation', 'buildings'].forEach(k => {
      const upperK = _.upperFirst(k)
      if (this.data.opinion[`expertOpinion${upperK}`]) {
        const price = this.decimalPipe.transform(parcelPrice[`expertOpinion${upperK}PriceDefault`]);
        const priceDefault = this.decimalPipe.transform(parcelPrice.expertOpinionParcelPriceDefault);
        parcelPrice.expertOpinionParcelPriceDefault = price && priceDefault ? priceDefault.add(price).toString() : null;
        parcelPrice[`expertOpinion${upperK}Price`] = price ? this.roundingService.roundProject(price.mul(coefficients[`${k}Coefficient`])).toString() : null;
      } else {
        parcelPrice[`expertOpinion${upperK}Price`] = null;
        parcelPrice[`expertOpinion${upperK}PriceDefault`] = null;
      }
    });

    // Store configuration
    parcelPrice.expertOpinionCoefficients = ExpertOpinionCoefficientsFlatten.toCoefficients(coefficients);
  }

  setSortData(sortBy) {
    this.sortOrder.direction = this.sortOrder.direction === 'asc' ? 'desc' : 'asc';
    // every first sort will be sorted 'asc'
    if (this.sortOrder.sortBy !== sortBy) {
      this.sortOrder.direction = 'asc';
    }
    this.sortOrder.sortBy = sortBy;
  }

  onSortParcel() {
    const sortFn = (item) => {
      let parcel = item.parcel;
      if (item.building) {
        parcel = item.building.parcels[0];
      }
      return ParcelUtils.getParcelSortNumber(parcel.parcisKmen, parcel.parcisPod, parcel.parcisDil);
    };

    this.setSortData('parcel');
    this.data.parcelPrices = _.orderBy(
      this.data.parcelPrices,
      sortFn,
      this.sortOrder.direction
    );
  }

  multipleLandCoefficient(parcelPrice: ParcelPriceModel) {
    return this.parcelPriceService.multipleLandCoefficient(parcelPrice);
  }

  multipleLandCoefficientAreaMatches(parcelPrice: ParcelPriceModel) {
    return this.parcelPriceService.multipleLandCoefficientAreaMatches(parcelPrice);
  }

  isUsedCoefficient(coefficients: ExpertOpinionCoefficient[]) {
    return coefficients.some(coefficient => coefficient.multiply !== false);
  }

  getCoefficientName(type: 'land' | 'compensation' | 'vegetation' | 'buildings') {
    switch (type) {
      case 'land':
        return 'Pozemek';
      case 'buildings':
        return 'Budova';
      case 'compensation':
        return 'Náhrada';
      case 'vegetation':
        return 'Porost';
      default:
        return '';
    }
  }

  private loadParcelPrices(): Promise<any> {
    this.data.checklistParcelPrices = new ChecklistModel(this.data.opinion.parcelPrices);

    return Promise.all(this.prepareApiCalls())
      .then(res => {
        this.data.parcelPrices = [];

        res[0].forEach((parcel: any) => {
          const item = {
            parcel: parcel,
            expertOpinionCoefficientsFlatten: new ExpertOpinionCoefficientsFlatten(),
          };

          this.data.parcelPrices.push(<ParcelPriceModel>Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
        });

        res[1].forEach((building) => {
          const doesBuildingMatch = this.data.parcelPrices.find(parcelPrice => this.parcelPriceService.buildingsMatch(building, parcelPrice));

          const item = {
            building: building,
            expertOpinionCoefficientsFlatten: new ExpertOpinionCoefficientsFlatten(),
          };

          if (!doesBuildingMatch && this.data.occupationType === 'P') {
            this.data.parcelPrices.push(<ParcelPriceModel>Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
          }
        });

        // filter parcelPrices by parcels and buildings from opinion request
        if (this.data.opinionRequest) {
          this.data.parcelPrices = this.data.parcelPrices.filter((item) => {
            return this.data.opinionRequest.parcels.find(e => item.parcel.id === e.id) || this.data.opinionRequest.buildings.find(e => item.building.id === e.id);
          });
        }

        this.checkAll();
      });
  }

  private loadOpinionPricing(): Promise<any> {
    return this.restangular
      .all('parcel-prices')
      .customPOST({ filter: this.parcelPricesFilter })
      .toPromise()
      .then(data => {
        this.data.parcelPrices = [];
        data = data.plain();
        this.data.parcelPrices = this.data.parcelPrices.concat(data);
        data.forEach(this.loadParcelPrice, this);
        this.data.checklistParcelPrices = new ChecklistModel(this.data.opinion.parcelPrices);
      })
      .then(() => {
        if (this.data.readonly) {
          return;
        }

        Promise.all(this.prepareApiCalls()).then((res) => {
          res[0].forEach((parcel) => {
            const doesParcelMatch = this.data.parcelPrices.find(parcelPrice => this.parcelPriceService.parcelsMatch(parcel, parcelPrice));
            const item = {
              parcel: parcel,
              expertOpinionCoefficientsFlatten: new ExpertOpinionCoefficientsFlatten(),
            };

            if (!doesParcelMatch) {
              this.data.parcelPrices.push(<ParcelPriceModel>Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
            }
          });

          res[1].forEach((building) => {
            const doesBuildingMatch = this.data.parcelPrices.find(parcelPrice => this.parcelPriceService.buildingsMatch(building, parcelPrice));
            const item = {
              building: building,
              expertOpinionCoefficientsFlatten: new ExpertOpinionCoefficientsFlatten(),
            };

            if (!doesBuildingMatch) {
              this.data.parcelPrices.push(<ParcelPriceModel>Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
            }
          });
        });
      });
  }

  private loadParcelPrice(parcelPrice: ParcelPriceModel) {
    const expertOpinionVegetationPriceDefault = (this.data.opinion.expertOpinionVegetation ? this.decimalPipe.transform(parcelPrice.expertOpinionVegetationPriceDefault) : null) || new Decimal('0');
    const expertOpinionBuildingsPriceDefault = (this.data.opinion.expertOpinionBuildings ? this.decimalPipe.transform(parcelPrice.expertOpinionBuildingsPriceDefault) : null ) || new Decimal('0');
    const expertOpinionCompensationPriceDefault = (this.data.opinion.expertOpinionCompensation ? this.decimalPipe.transform(parcelPrice.expertOpinionCompensationPriceDefault) : null ) || new Decimal('0');
    const expertOpinionLandPriceDefault = (this.data.opinion.expertOpinionLand ? this.decimalPipe.transform(parcelPrice.expertOpinionLandPriceDefault) : null) || new Decimal('0');

    parcelPrice.expertOpinionParcelPriceDefault = expertOpinionLandPriceDefault.add(expertOpinionVegetationPriceDefault).add(expertOpinionBuildingsPriceDefault).add(expertOpinionCompensationPriceDefault).toString();
    parcelPrice.expertOpinionCoefficientsFlatten = ExpertOpinionCoefficientsFlatten.fromCoefficients(parcelPrice.expertOpinionCoefficients);
    const sqPrice = this.decimalPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
    parcelPrice.expertOpinionLandAreaPrice = parcelPrice.parcel && parcelPrice.parcel.vymera && sqPrice ?
      this.roundingService.roundProject(
        sqPrice.mul(parcelPrice.parcel.vymera)
      ).toString() : '0';

    if (parcelPrice.parcel) {
      parcelPrice.parcel.validBuyoutOpinionLand = !this.data.opinion.expertOpinionLand && parcelPrice.parcel.validBuyoutOpinionLand;
      parcelPrice.parcel.validBuyoutOpinionVegetation = !this.data.opinion.expertOpinionVegetation && parcelPrice.parcel.validBuyoutOpinionVegetation;
      parcelPrice.parcel.validBuyoutOpinionBuildings = !this.data.opinion.expertOpinionBuildings && parcelPrice.parcel.validBuyoutOpinionBuildings;
    } else {
      parcelPrice.building.validBuyoutOpinionBuildings = !this.data.opinion.expertOpinionBuildings && parcelPrice.building.validBuyoutOpinionBuildings;
    }

    this.data.opinion.parcelPrices.push(parcelPrice);
  }

  private prepareApiCalls(): any[] {
    const loadParcels = this.restangular.all('parcels').customPOST({ filter: this.parcelsFilter }).toPromise();
    const loadBuildings = this.restangular.all('buildings').customPOST({ filter: this.buildingsFilter }).toPromise();
    return [loadParcels, loadBuildings];
  }

  private setupFilters() {
    this.parcelsFilter = {
      filters: {
        titleId: [ this.data.title.id ],
        validBuyoutOpinion: [ false ],
        validity: [ 'valid' ],
      },
    };
    this.parcelsFilter.filters[ (this.data.occupationType === 'P' ? 'permanent' : 'temporary') + 'OccupationExists' ] = [ true ];

    this.buildingsFilter = {
      filters: {
        occupationOrEasementExists: [ true ],
        titleId: [ this.data.title.id ],
        validBuyoutOpinion: [ false ],
        validity: [ 'valid' ],
        differentTitle: [ true ],
        loadCollections: [
          'parcelsWithEasementOrOccupation',
          'buildingProtections',
          'parcels.parcelProtections',
        ],
      },
    };

    this.parcelPricesFilter = {
      filters: {
        opinionId: [ this.data.opinion.id ],
        validity: ['valid'],
        loadCollections: [
          'building.parcelsWithEasementOrOccupation',
          'building.buildingProtections',
          'building.parcels.parcelProtections',
        ],
      },
    };
  }

  onMethodChange() {
    this.data.parcelPrices.forEach(parcelPrice => {
      this.computeParcelPrice(parcelPrice);
      this.populateBaseLandPrice(parcelPrice);
    });
  }
}
