import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Decimal } from 'decimal.js';

@Pipe({
  name: 'decimalPipe'
})
@Injectable({
  providedIn: 'root'
})
export class DecimalPipe implements PipeTransform {

  transform(text: string): Decimal {
    if (_.isNumber(text)) {
      return new Decimal(text);
    }
    if (!text) {
      return null;
    }

    const res = text.replace(',', '.').replace(/[^\d\.]*/g, '');

    try {
      return new Decimal(res);
    } catch (e) {
      return null;
    }
  }
}
