import {Ng2StateDeclaration, StateDeclaration, StateParams, StateService, Transition} from '@uirouter/angular';

import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { CaseModel } from '@app/ps/models/case.model';
import { DialogService } from '@app/common/services/dialog.service';
import { FormCaseUpdateComponent } from '@app/ps/cases/components/form-case-update/form-case-update.component';
import { FormContractsRegisterDialogComponent } from '@app/ps/cases/components/form-contracts-register-dialog/form-contracts-register-dialog.component';
import { UpdateOccupationComponent } from './components/update-occupation/update-occupation.component';
import { UpdateEasementComponent } from './components/update-easement/update-easement.component';
import { CadastreEntryProposalComponent } from './components/cadastre-entry-proposal/cadastre-entry-proposal.component';
import { CaseListComponent } from './components/case-list/case-list.component';
import { UpdateOccupationParcelComponent } from '@app/ps/occupations/components/update-occupation-parcel/update-occupation-parcel.component';
import { resolveCallbacks, resolveId, resolveTrue } from '@app/common/utils/state.utils';
import { AcceptedGeometricalPlanPossibleComponent } from './components/accepted-geometrical-plan-possible/accepted-geometrical-plan-possible.component';
import { ExpropriationInterimDecisionRequestComponent } from './components/expropriation-interim-decision-request/expropriation-interim-decision-request.component';
import { ExpropriationInterimDecisionComponent } from './components/expropriation-interim-decision/expropriation-interim-decision.component';
import { ExpropriationAdministrativeAppealComponent } from './components/expropriation-administrative-appeal/expropriation-administrative-appeal.component';
import { ExpropriationAdministrativeAppealRejectedComponent } from './components/expropriation-administrative-appeal-rejected/expropriation-administrative-appeal-rejected.component';
import { ExpropriationAdministrativeAppealAcceptedComponent } from './components/expropriation-administrative-appeal-accepted/expropriation-administrative-appeal-accepted.component';
import { ExpropriationCompensationPaymentComponent } from './components/expropriation-compensation-payment/expropriation-compensation-payment.component';
import { SimpleCaseStatusComponent } from '@app/ps/cases/components/simple-case-status/simple-case-status.component';
import { CaseService } from '@app/ps/services/case.service';
import { SignInvestorComponent } from '@app/ps/cases/components/sign-investor/sign-investor.component';
import { SendOwnerComponent } from '@app/ps/cases/components/send-owner/send-owner.component';
import { SignOwnerComponent } from '@app/ps/cases/components/sign-owner/sign-owner.component';
import { ReceiveOwnerComponent } from '@app/ps/cases/components/receive-owner/receive-owner.component';
import { ConfirmComponent } from '@app/ps/cases/components/confirm/confirm.component';
import { CadastreEntryComponent } from '@app/ps/cases/components/cadastre-entry/cadastre-entry.component';
import { ExpropriationResultComponent } from '@app/ps/cases/components/expropriation-result/expropriation-result.component';
import { ExpropriationAppealResultComponent } from '@app/ps/cases/components/expropriation-appeal-result/expropriation-appeal-result.component';
import { AuctionResultComponent } from '@app/ps/cases/components/auction-result/auction-result.component';
import { EntryProposalComponent } from '@app/ps/cases/components/entry-proposal/entry-proposal.component';
import { PaymentCheckComponent } from '@app/ps/cases/components/payment-check/payment-check.component';
import { GenerateDocumentsComponent } from '@app/ps/cases/components/generate-documents/generate-documents.component';
import { DeclinedOwnerComponent } from '@app/ps/cases/components/declined-owner/declined-owner.component';
import { PersonalVisitComponent } from '@app/ps/cases/components/personal-visit/personal-visit.component';
import { UpdateCaseOwnershipComponent } from '@app/ps/cases/components/update-case-ownership/update-case-ownership.component';
import { CaseDetailComponent } from '@app/ps/cases/components/case-detail/case-detail.component';
import { CreateCaseComponent } from '../titles/components/create-case/create-case.component';
import { Restangular } from 'ngx-restangular';
import { UIRouter } from '@uirouter/core';
import {
  ExpropriationEnforceableComponent
} from "@app/ps/cases/components/expropriation-enforceable/expropriation-enforceable.component";

export const casesState: Ng2StateDeclaration = {
  name: 'symap.project.cases',
  data: {
    title: 'Případy',
  },
  url: '/cases?page&sef&obligationId&cancelStatus&sefReset',
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
    sef: {
      type: 'string',
      inherit: false,
      array: true
    },
    sefReset: {
      type: 'string',
      inherit: false,
    },
    obligationId: {
      type: 'int',
      array: true,
      inherit: false,
    },
    cancelStatus: {
      type: 'string',
      inherit: false,
    },
  },
  views: {
    'content@symap.project': {
      component: CaseListComponent,
    }
  }
};

export async function updateCaseDialogFunction(transition: Transition) {
  const dialogService = transition.injector().get(DialogService);
  const stateService = transition.injector().get(UIRouter).stateService;
  const callbacks = transition.injector().get('callbacks');
  const caseObject = transition.injector().get('caseObject');
  const previousTab = transition.injector().get('previousTab');

  const dialog = dialogService.open(FormCaseUpdateComponent, {
    data: {
      caseObject,
    }
  });

  const sub = dialog.afterClosed.subscribe((res: CaseModel) => {
    if (res) {
      callbacks.get(Callbacks.CaseUpdated)(res);
    }
    stateService.go('symap.project.cases.detail', { tab: previousTab });
    sub.unsubscribe();
  });
}

export const updateCaseState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.updateCase',
  url: '/update-case',
  data: {
    title: 'Editace obecných údajů případu',
  },
  resolve: {
    caseObject: ['$stateParams', Restangular, resolveCaseObjectFunction],
    previousTab: ['$stateParams', resolvePreviousTabFunction],
  },
  onEnter: updateCaseDialogFunction
};

export const updateCaseOccupation: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.updateOccupation',
  url: '/update-occupation/{occupationId}',
  data: {
    title: 'Editace záboru případu',
  },
  params: {
    occupationId: {
      type: 'int',
    },
  },
  resolve: {
    caseId: ['$stateParams', resolveId],
    occupationId: ['$stateParams', resolveOccupationIdFunction],
  },
  views: {
    'content@symap.project': {
      component: UpdateOccupationComponent,
    }
  },
};

export const updateCaseEasement: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.updateEasement',
  url: '/update-easement/{easementId}',
  data: {
    title: 'Editace věcného břemene případu',
  },
  params: {
    easementId: {
      type: 'int',
    },
  },
  resolve: {
    caseId: ['$stateParams', resolveId],
    easementId: ['$stateParams', resolveEasementIdFunction],
  },
  views: {
    'content@symap.project': {
      component: UpdateEasementComponent,
    }
  },
};

export const contractsRegisterState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.contractsRegister',
  url: '/contracts-register',
  data: {
    title: 'Zveřejnění smlouvy v registru smluv',
  },
  resolve: {
    caseObject: ['$stateParams', Restangular, resolveCaseObjectFunction],
    previousTab: ['$stateParams', resolvePreviousTabFunction],
  },
  onEnter: contractsRegisterDialogFunction
};

export const cadastreEntryProposalState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.cadastreEntryProposal',
  data: {
    title: 'Webová služba pro návrh na vklad',
  },
  url: '/cadastre-entry-proposal',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: CadastreEntryProposalComponent,
    },
  },
};

export function contractsRegisterDialogFunction(transition: Transition, state: StateDeclaration) {
  const dialogService = transition.injector().get(DialogService);
  const stateService = transition.injector().get(UIRouter).stateService;
  const callbacks = transition.injector().get('callbacks');
  const caseObject = transition.injector().get('caseObject');
  const previousTab = transition.injector().get('previousTab');

  const dialog = dialogService.open(FormContractsRegisterDialogComponent, {
    data: {
      caseObject,
    },
  });

  const sub = dialog.afterClosed.subscribe((res: CaseModel) => {
    if (res) {
      callbacks.get(Callbacks.CaseUpdated)(res);
    }

    stateService.go('symap.project.cases.detail', { tab: previousTab });
    sub.unsubscribe();
  });
}

export function resolveSimpleStatusConfig(transition: Transition, caseService: CaseService) {
  const stateName = transition.$to().name;
  const chunks = stateName.split('.');
  return caseService.getSimpleCaseStatusConfig(chunks[chunks.length - 1]);
}

export function resolveObligationType() { return 'FulfillmentOfDecisionExpropriationLiability'; }

export function resolveOccupationIdFunction(stateParams: StateParams) {
  return stateParams.occupationId;
}

export function resolveEasementIdFunction(stateParams: StateParams) {
  return stateParams.easementId;
}

export function resolveCaseObjectFunction(stateParams: StateParams, restangular: Restangular) {
  return restangular.one('cases', stateParams.id).get().toPromise();
}

export function resolvePreviousTabFunction(stateParams: StateParams) {
  return stateParams.tab;
}

export const updateOccupationParcelState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.updateOccupationParcel',
  url: '/occupation/{occupationId}/update-parcel',
  data: {
    title: 'Aktualizace parcely záboru',
  },
  params: {
    occupationId: {
      type: 'int',
    },
  },
  resolve: {
    occupationId: ['$stateParams', resolveOccupationIdFunction],
  },
  views: {
    'content@symap.project': {
      component: UpdateOccupationParcelComponent,
    },
  },
};

export const meetingRequestState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.meetingRequest',
  data: {
    title: 'Požadavek na jednání',
  },
  url: '/meeting-request',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ],
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const acceptedGeometricalPlanPossibleState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.acceptedGeometricalPlanPossible',
  data: {
    title: 'Vlastník souhlasí',
  },
  url: '/accepted-geometrical-plan-possible',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: AcceptedGeometricalPlanPossibleComponent,
    },
  },
};

export const expropriationInterimDecisionRequestState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationInterimDecisionRequest',
  data: {
    title: 'Žádost o mezitimní rozhodnutí',
  },
  url: '/expropriation-interim-decision-request',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: ExpropriationInterimDecisionRequestComponent,
    },
  },
};

export const expropriationInterimDecisionState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationInterimDecision',
  data: {
    title: 'Vydání mezitimního rozhodnutí',
  },
  url: '/expropriation-interim-decision',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: ExpropriationInterimDecisionComponent,
    },
  },
};

export const expropriationAdministrativeAppealState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationAdministrativeAppeal',
  data: {
    title: 'Podána správní žaloba',
  },
  url: '/expropriation-administrative-appeal',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: ExpropriationAdministrativeAppealComponent,
    },
  },
};

export const expropriationAdministrativeAppealRejectedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationAdministrativeAppealRejected',
  data: {
    title: 'Žaloba zamítnuta',
  },
  url: '/expropriation-administrative-appeal-rejected',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: ExpropriationAdministrativeAppealRejectedComponent,
    },
  },
};

export const expropriationAdministrativeAppealAcceptedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationAdministrativeAppealAccepted',
  data: {
    title: 'Žaloba potvrzena',
  },
  url: '/expropriation-administrative-appeal-accepted',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: ExpropriationAdministrativeAppealAcceptedComponent,
    },
  },
};

export const expropriationCompensationPaymentState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationCompensationPayment',
  data: {
    title: 'Úhrada zálohy na náhradu',
  },
  url: '/expropriation-compensation-payment',
  params: {},
  resolve: {
    caseId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: ExpropriationCompensationPaymentComponent,
    },
  },
};

export const expropriationEnforceableState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationEnforceable',
  data: {
    title: 'Nabytí právní moci rozhodnutí',
  },
  url: '/expropriation-enforceable',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: ExpropriationEnforceableComponent,
    },
  },
};

export const expropriationAppealState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationAppeal',
  data: {
    title: 'Odvolání účastníka řízení',
  },
  url: '/expropriation-appeal',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationHearingRepetitionState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationHearingRepetition',
  data: {
    title: 'Opakování ústního jednání',
  },
  url: '/expropriation-hearing-repetition',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationProceedingInteruptionExtendedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationProceedingInteruptionExtended',
  data: {
    title: 'Prodloužení lhůty přerušení',
  },
  url: '/expropriation-proceeding-interuption-extended',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationProceedingInteruptedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationProceedingInterupted',
  data: {
    title: 'Přerušení vyvlastňovacího řízení',
  },
  url: '/expropriation-proceeding-interupted',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationAssessmentInteruptedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationAssessmentInterupted',
  data: {
    title: 'Přerušení posouzení žádosti',
  },
  url: '/expropriation-assessment-interupted',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationStopState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationStop',
  data: {
    title: 'Zastavení vyvlastňovacího řízení',
  },
  url: '/expropriation-stop',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationCalledHearingState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationCalledHearing',
  data: {
    title: 'Svolání ústního jednání',
  },
  url: '/expropriation-called-hearing',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationProceedingStartedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationProceedingStarted',
  data: {
    title: 'Zahájení vyvlastňovacího řízení',
  },
  url: '/expropriation-proceeding-started',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const handOverInvestorState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.handOverInvestor',
  data: {
    title: 'Předání investorovi',
  },
  url: '/hand-over-investor',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const paymentInstructionState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.paymentInstruction',
  data: {
    title: 'Žádost o úhradu',
  },
  url: '/case-payment-instruction',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const paymentOrderState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.paymentOrder',
  data: {
    title: 'Platební příkaz',
  },
  url: '/case-payment-order',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const sendAdministratorState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.sendAdministrator',
  data: {
    title: 'Odeslání oprávněnému',
  },
  url: '/case-send-administrator',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const sendInvestorState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.sendInvestor',
  url: '/case-send-investor',
  data: {
    title: 'Odeslání investorovi',
  },
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationProposalState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationProposal',
  data: {
    title: 'Podání žádosti na vyvlastňovací úřad',
  },
  url: '/expropriation-proposal',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const signAdministratorState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.signAdministrator',
  data: {
    title: 'Podpis oprávněným',
  },
  url: '/case-sign-administrator',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const signInvestorState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.signInvestor',
  data: {
    title: 'Podpis smlouvy investorem',
  },
  url: '/case-sign-investor',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: SignInvestorComponent,
    },
  },
};

export const sendOwnerState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.sendOwner',
  data: {
    title: 'Odeslání vlastníkovi',
  },
  url: '/case-send-owner',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: SendOwnerComponent,
    },
  },
};

export const signOwnerState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.signOwner',
  data: {
    title: 'Podpis vlastníkem',
  },
  url: '/case-sign-owner',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: SignOwnerComponent,
    },
  },
};

export const receiveOwnerState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.receiveOwner',
  data: {
    title: 'Přijetí vlastníkem',
  },
  url: '/case-receive-owner',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: ReceiveOwnerComponent,
    },
  },
};

export const confirmState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.confirm',
  data: {
    title: 'Schválení případu',
  },
  url: '/case-confirm',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: ConfirmComponent,
    },
  },
};

export const cadastreEntryState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.cadastreEntry',
  data: {
    title: 'Vklad KN',
  },
  url: '/case-cadastre-entry',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: CadastreEntryComponent,
    },
  },
};

export const expropriationResultState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationResult',
  data: {
    title: 'Rozhodnutí vyvlastňovacího úřadu',
  },
  url: '/expropriation-result',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: ExpropriationResultComponent,
    },
  },
};

export const expropriationAppealResultState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationAppealResult',
  data: {
    title: 'Výsledek odvolání',
  },
  url: '/expropriation-appeal-result',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: ExpropriationAppealResultComponent,
    },
  },
};


export const auctionResultState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.auctionResult',
  data: {
    title: 'Výsledek dražby',
  },
  url: '/auction-result',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: AuctionResultComponent,
    },
  },
};

export const entryProposalState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.entryProposal',
  data: {
    title: 'Návrh na vklad KN',
  },
  url: '/case-entry-proposal',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: EntryProposalComponent,
    },
  },
};

export const paymentCheckPreliminaryState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.paymentCheckPreliminary',
  data: {
    title: 'Předběžná kontrola způsobu platby',
  },
  url: '/case-payment-check-preliminary',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    preliminary: [ resolveTrue ],
  },
  views: {
    'content@symap.project': {
      component: PaymentCheckComponent,
    },
  },
};

export const paymentCheckState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.paymentCheck',
  data: {
    title: 'Závěrečná kontrola způsobu platby',
  },
  url: '/case-payment-check',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: PaymentCheckComponent,
    },
  },
};

export const undeliveredOwnerState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.undeliveredOwner',
  data: {
    title: 'Vrácení smlouvy nedoručené vlastníkovi',
  },
  url: '/case-undelivered-owner',
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ],
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const declinedOwnerState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.declinedOwner',
  data: {
    title: 'Odmítnuto vlastníkem',
  },
  url: '/case-declined-owner',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: DeclinedOwnerComponent,
    },
  },
};

export const personalVisitState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.personalVisit',
  data: {
    title: 'Osobní návštěva',
  },
  url: '/personal-visit',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: PersonalVisitComponent,
    },
  },
};

export const signedSentOwnersState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.signedSentOwners',
  data: {
    title: 'Podepsané smlouvy odeslány vlastníkům',
  },
  url: '/signed-sent-owners',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};


export const generateState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.generate',
  data: {
    title: 'Generování dokumentů',
  },
  url: '/generate',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
  },
  views: {
    'content@symap.project': {
      component: GenerateDocumentsComponent,
    },
  },
};

export function resolveFinancialType() {
  return 'financial';
}

export const generateFinancialControlState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.generateFinancialControl',
  data: {
    title: 'Generování dokumentu finanční kontroly',
  },
  url: '/generateFinancialControl',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    type: [ resolveFinancialType ]
  },
  views: {
    'content@symap.project': {
      component: GenerateDocumentsComponent,
    },
  },
};

export function reolveCaseOwnershipId(stateParams: StateParams): number {
  return stateParams.caseOwnershipId;
}

export const updateCaseOwnershipState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.updateCaseOwnership',
  data: {
    title: 'Editace vlastníka',
  },
  url: '/update-case-ownership/:caseOwnershipId',
  params: {
    caseOwnershipId: {
      type: 'int',
    },
  },
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    caseOwnershipId: [ '$stateParams', reolveCaseOwnershipId ],
  },
  views: {
    'content@symap.project': {
      component: UpdateCaseOwnershipComponent,
    },
  },
};

export const caseDetailState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail',
  url: '/:id/:tab',
  data: {
    title: 'Složka případu',
  },
  views: {
    'content@symap.project': {
      component: CaseDetailComponent,
    }
  },
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    callbacks: [resolveCallbacks],
  },
  params: {
    id: {
      type: 'int',
      value: undefined,
    },
    tab: {
      value: 'owners',
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
};

export const callBeforeExpropriationState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.callBeforeExpropriation',
  data: {
    title: 'Výzva před vyvlastněním',
  },
    url: '/call-before-expropriation',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const callBeforeExpropriationReceivedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.callBeforeExpropriationReceived',
  data: {
    title: 'Doručena výzva před vyvlastněním',
  },
    url: '/call-before-expropriation-received',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const expropriationCaseInProgressState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.expropriationCaseInProgress',
  data: {
    title: 'Zahájení vyvlastnění',
  },
  url: '/expropriation-case-in-progress',
  params: {
  },
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    obligationType: resolveObligationType,
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: CreateCaseComponent,
    },
  },
};

export const dunningLetterState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.dunningLetter',
  data: {
    title: 'Odeslána urgence',
  },
    url: '/dunning-letter',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const buildingAuthorityApprovalRequestState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.buildingAuthorityApprovalRequest',
  data: {
    title: 'Odeslána žádost o souhlas SÚ s dělením pozemků',
  },
    url: '/building-authority-approval-request',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const buildingAuthorityApprovedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.buildingAuthorityApproved',
  data: {
    title: 'Udělen souhlas SÚ s dělením pozemků',
  },
    url: '/building-authority-approved',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const buildingAuthorityRejectedState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.buildingAuthorityRejected',
  data: {
    title: 'Nesouhlas SÚ s dělením pozemků',
  },
    url: '/building-authority-rejected',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const disapprovedOwnerState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.disapprovedOwner',
  data: {
    title: 'Nesouhlas vlastníka',
  },
    url: '/disapproved-owner',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const sentAllOwnersState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.sentAllOwners',
  data: {
    title: 'Odesláno vlastníkovi',
  },
    url: '/sent-all-owners',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const approvedOwnerState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.approvedOwner',
  data: {
    title: 'Vlastník souhlasí',
  },
    url: '/approved-owner',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const otherSolutionRequestState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.otherSolutionRequest',
  data: {
    title: 'Požadavek na jiné řešení',
  },
    url: '/other-solution-request',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const withoutResolutionState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.withoutResolution',
  data: {
    title: 'Nerozhodnuto / bez výsledku jednání',
  },
    url: '/without-resolution',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};

export const exchangeRequestState: Ng2StateDeclaration = {
  name: 'symap.project.cases.detail.exchangeRequest',
  data: {
    title: 'Požadavek na směnu',
  },
    url: '/exchange-request',
  params: {},
  resolve: {
    caseId: [ '$stateParams', resolveId ],
    config: [ '$transition$', CaseService, resolveSimpleStatusConfig ]
  },
  views: {
    'content@symap.project': {
      component: SimpleCaseStatusComponent,
    },
  },
};
