import { OwnershipModel } from '@app/common/models/ownership.model';
import { CaseSubjectModel } from '@app/ps/models/case-subject.model';
import { CaseOwnershipValidation } from '@app/ps/cases/components/field-validation-msg/field-validation-msg.component';

export class CaseOwnershipModel {
  ownership: OwnershipModel;
  parentCaseOwnership: CaseOwnershipModel;
  isParent: boolean;
  singlePayment?: boolean;
  price?: number;
  easementPrice?: number;
  podil?: string;
  paymentType?: string;
  accountNumber?: string;
  bankCode?: string;

  // helpers
  caseSubjects?: CaseSubjectModel[];
  requirements?: CaseOwnershipValidation[];
  errors?: CaseOwnershipValidation[];
  opened?: boolean;

  // helpers for grouped caseOwnership
  sumPrice?: number;
  ownershipIds: number[];
}
